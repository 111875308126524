import type {PerkIntegrationSpec, PerkIntegrationStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const InstagramCloseFriendsListConfigSchema = z.object({
  username: z.string().regex(/^[a-zA-Z0-9._]+$/u, 'errorFormat'),
});
export type InstagramCloseFriendsListConfig = z.infer<typeof InstagramCloseFriendsListConfigSchema>;

const InstagramCloseFriendsListStateSchema = z.object({});
export type InstagramCloseFriendsListState = z.infer<typeof InstagramCloseFriendsListStateSchema>;

const InstagramCloseFriendsListAccessDataSchema = z.object({});
export type InstagramCloseFriendsListAccessData = z.infer<
  typeof InstagramCloseFriendsListAccessDataSchema
>;

const InstagramCloseFriendsListUsageInputSchema = z.object({});
export type InstagramCloseFriendsListUsageInput = z.infer<
  typeof InstagramCloseFriendsListUsageInputSchema
>;

const InstagramCloseFriendsListUsageDataSchema = z.object({
  instagramUserId: z.string(),
  instagramUsername: z.string().nullable(),
});
export type InstagramCloseFriendsListUsageData = z.infer<
  typeof InstagramCloseFriendsListUsageDataSchema
>;

const InstagramCloseFriendsListUsageDataForExportSchema = InstagramCloseFriendsListUsageDataSchema;
export type InstagramCloseFriendsListUsageDataForExport = z.infer<
  typeof InstagramCloseFriendsListUsageDataForExportSchema
>;

export type InstagramCloseFriendsListPerkIntegrationStruct = PerkIntegrationStruct<
  'instagram.close-friends-list',
  InstagramCloseFriendsListConfig,
  InstagramCloseFriendsListState,
  InstagramCloseFriendsListAccessData,
  InstagramCloseFriendsListUsageInput,
  InstagramCloseFriendsListUsageData,
  InstagramCloseFriendsListUsageData,
  InstagramCloseFriendsListUsageDataForExport
>;

export const InstagramCloseFriendsListPerkIntegrationSpec: PerkIntegrationSpec<InstagramCloseFriendsListPerkIntegrationStruct> =
  {
    id: 'instagram.close-friends-list' as const,
    configSchema: InstagramCloseFriendsListConfigSchema,
    stateSchema: InstagramCloseFriendsListStateSchema,
    accessDataSchema: InstagramCloseFriendsListAccessDataSchema,
    usageInputDataSchema: InstagramCloseFriendsListUsageInputSchema,
    internalUsageDataSchema: InstagramCloseFriendsListUsageDataSchema,
    exposedUsageDataSchema: InstagramCloseFriendsListUsageDataSchema,
    exposedUsageDataForExportSchema: InstagramCloseFriendsListUsageDataForExportSchema,
    defaultMaxUsagesPerToken: 1,
    requiresMerchantConnection: true,
  };
