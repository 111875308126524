import {TriggerReferenceIdSchema} from '@cohort/shared/apps/cohort-form/triggers/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {LocalizedStringSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

const VerificationAttemptInputSchema = z.object({
  answer: z.union([z.string(), z.array(z.string())]),
});
type VerificationAttemptInput = z.infer<typeof VerificationAttemptInputSchema>;

const VerificationAttemptDataSchema = z.object({
  answer: z.union([z.string(), z.array(z.string())]),
});
type VerificationAttemptData = z.infer<typeof VerificationAttemptDataSchema>;

const OptionSchema = z.object({
  imageFileKey: z.string(),
  label: LocalizedStringSchema.optional(),
});

const ConfigSchema = z
  .object({
    referenceId: TriggerReferenceIdSchema,
    multipleChoice: z.boolean().default(false).optional(),
    maxMultipleChoices: z.number().min(1).nullable().optional(),
    showLabels: z.boolean().default(false).optional(),
    pictureChoiceOptions: z.array(OptionSchema).min(1),
    userPropertyId: z.string().uuid().nullable(),
  })
  .transform(data => {
    if (!data.showLabels) {
      data.pictureChoiceOptions.forEach(option => {
        option.label = undefined;
      });
    }
    return data;
  });
type Config = z.infer<typeof ConfigSchema>;

const SocialLayerDataSchema = z.object({
  pictures: z.record(z.string().uuid(), z.number()),
});
type SocialLayerData = z.infer<typeof SocialLayerDataSchema>;

export type CohortFormPictureChoiceTriggerStruct = TriggerIntegrationStruct<
  'cohort-form.picture-choice',
  Config,
  VerificationAttemptData,
  VerificationAttemptInput,
  SocialLayerData
>;

export const CohortFormPictureChoiceTriggerIntegrationSpec: TriggerIntegrationSpec<CohortFormPictureChoiceTriggerStruct> =
  {
    id: 'cohort-form.picture-choice' as const,
    configurableMaxAttempts: false,
    configSchema: ConfigSchema,
    verificationAttemptDataSchema: VerificationAttemptDataSchema,
    verificationAttemptInputSchema: VerificationAttemptInputSchema,
    socialLayerDataSchema: SocialLayerDataSchema,
    requiresMerchantConnection: false,
    useCases: ['customer-knowledge'],
  };
