import FacebookLikePageTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/facebook/triggers/likePage/InstructionsComponent';
import type {FacebookLikePageUnverifiedTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePageUnverified';
import {FacebookLikePageUnverifiedTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePageUnverified';
import FacebookLikePageTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/facebook/triggers/likePage/DetailsComponent';
import FacebookLikePageUnverifiedTriggerActionCtaComponent from '@cohort/wallet/apps/facebook/triggers/likePageUnverified/ActionCtaComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const FacebookLikePageUnverifiedTriggerIntegration: TriggerIntegration<FacebookLikePageUnverifiedTriggerStruct> =
  {
    type: 'action',
    spec: FacebookLikePageUnverifiedTriggerSpec,
    actionCtaComponent: FacebookLikePageUnverifiedTriggerActionCtaComponent,
    detailsComponent: FacebookLikePageTriggerIntegrationDetailsComponent,
    instructionsComponent: FacebookLikePageTriggerIntegrationInstructionsComponent,
  };
