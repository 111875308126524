import type {CohortFormMediaTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/media';
import {CohortFormMediaTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/media';
import CohortFormMediaCommunitySubmissions from '@cohort/wallet/apps/cohort-form/triggerIntegrations/media/CommunitySubmissions';
import CohortFormMediaUsageComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/media/UsageComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortFormMediaTriggerIntegration: TriggerIntegration<CohortFormMediaTriggerStruct> = {
  type: 'embedded',
  spec: CohortFormMediaTriggerIntegrationSpec,
  usageComponent: CohortFormMediaUsageComponent,
  communitySubmissionsComponent: CohortFormMediaCommunitySubmissions,
  disableExponentialBackoff: true,
};
