import {StravaActivityGroupsSchema} from '@cohort/shared/apps/strava/activity';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {startDateBeforeEndDateRefinement} from '@cohort/shared/schema/common/dates';
import {z} from 'zod';

export const StravaBeActiveTriggerConfigMetricSchema = z.enum([
  'distance',
  'duration',
  'elevation',
]);

export const StravaBeActiveTriggerConfigSchema = z
  .object({
    activityGroups: z
      .array(StravaActivityGroupsSchema)
      .nonempty({message: 'errorNoOptionsSelected'})
      .nullable(),
    endDateTimestamp: z.number(),
    metric: StravaBeActiveTriggerConfigMetricSchema,
    startDateTimestamp: z.number(),
    value: z.number().min(0),
  })
  .superRefine(startDateBeforeEndDateRefinement({}));

export type StravaBeActiveTriggerConfig = z.infer<typeof StravaBeActiveTriggerConfigSchema>;

export const StravaBeActiveVerificationAttemptDataSchema = z.object({
  activityIds: z.array(z.number()).nullable(),
  cumulativeValue: z.number().min(0).optional(),
  metric: StravaBeActiveTriggerConfigMetricSchema,
  thresholdValue: z.number().min(0),
  stravaUserId: z.string().nullable(),
  stravaUsername: z.string().nullable(),
});

export type StravaBeActiveVerificationAttemptData = z.infer<
  typeof StravaBeActiveVerificationAttemptDataSchema
>;

export type StravaBeActiveTriggerStruct = TriggerIntegrationStruct<
  'strava.be-active',
  StravaBeActiveTriggerConfig,
  StravaBeActiveVerificationAttemptData
>;

export const StravaBeActiveTriggerIntegrationSpec: TriggerIntegrationSpec<StravaBeActiveTriggerStruct> =
  {
    id: 'strava.be-active' as const,
    configurableMaxAttempts: false,
    configSchema: StravaBeActiveTriggerConfigSchema,
    verificationAttemptDataSchema: StravaBeActiveVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
    deprecated: true,
  };
