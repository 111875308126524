import NotFoundSection from '@cohort/wallet/components/error-pages/NotFoundSection';

const ChallengeController = {
  protectedRoutes: [
    {
      path: 'rewards',
      children: [
        {
          path: 'perks/:perkId',
          children: [
            {
              index: true,
              lazy: () => import('@cohort/wallet/pages/challenges/rewards/perk/page'),
            },
          ],
        },
        {
          path: 'ownerships',
          children: [
            {
              path: ':ownershipId',
              children: [
                {
                  index: true,
                  lazy: () => import('@cohort/wallet/pages/challenges/ownerships/ownership/page'),
                },
                {
                  path: 'transfer/new',
                  lazy: () =>
                    import('@cohort/wallet/pages/challenges/ownerships/ownership/transfer/page'),
                },
              ],
            },
          ],
        },
        {
          path: 'reward-boxes/:rewardBoxId',
          children: [
            {
              index: true,
              lazy: () => import('@cohort/wallet/pages/challenges/rewards/rewardBox/page'),
            },
          ],
        },
        {
          path: '*',
          element: <NotFoundSection />,
        },
      ],
    },
    {
      path: 'referral',
      lazy: () => import('@cohort/wallet/pages/challenges/referral/page'),
    },
    {
      path: 'play',
      lazy: () => import('@cohort/wallet/pages/challenges/play/page'),
    },
  ],
  bothPublicAndProtectedRoutes: [
    {
      path: 'steps/:stepId/verify',
      lazy: () => import('@cohort/wallet/pages/challenges/steps/verify/page'),
    },
  ],
};

export default ChallengeController;
