import {
  StravaActivityGroupsSchema,
  StravaActivityTypesSchema,
} from '@cohort/shared/apps/strava/activity';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {startDateBeforeEndDateRefinement} from '@cohort/shared/schema/common/dates';
import {z} from 'zod';

export const StravaCompleteActivityTriggerConfigSchema = z
  .object({
    activityGroups: z
      .array(StravaActivityGroupsSchema)
      .nonempty({message: 'errorNoOptionsSelected'})
      .optional(),
    minAverageElevationGainMeters: z.number().min(0).nullable(),
    minAverageSpeedMetersPerSecond: z.number().min(0).nullable(),
    minDistanceMeters: z.number().min(0).nullable(),
    minDurationSeconds: z.number().min(0).nullable(),
    activityEndDateTimestamp: z.number(),
    activityStartDateTimestamp: z.number(),
  })
  .superRefine(
    startDateBeforeEndDateRefinement({
      startDatePropertyName: 'activityStartDateTimestamp',
      endDatePropertyName: 'activityEndDateTimestamp',
    })
  );

export type StravaCompleteActivityTriggerConfig = z.infer<
  typeof StravaCompleteActivityTriggerConfigSchema
>;

export const StravaCompleteActivityVerificationAttemptDataSchema = z
  .object({
    activityType: StravaActivityTypesSchema,
    activityId: z.number(),
    activityStartDate: z.date(),
    averageSpeedMetersPerSecond: z.number().min(0).nullable(),
    distanceMeters: z.number().min(0).nullable(),
    durationSeconds: z.number().min(0).nullable(),
    totalElevationGainMeters: z.number().min(0).nullable(),
    stravaUserId: z.string(),
    stravaUsername: z.string().nullable(),
  })
  .nullable();

export type StravaCompleteActivityVerificationAttemptData = z.infer<
  typeof StravaCompleteActivityVerificationAttemptDataSchema
>;

export type StravaCompleteActivityTriggerStruct = TriggerIntegrationStruct<
  'strava.complete-activity',
  StravaCompleteActivityTriggerConfig,
  StravaCompleteActivityVerificationAttemptData
>;

export const StravaCompleteActivityTriggerIntegrationSpec: TriggerIntegrationSpec<StravaCompleteActivityTriggerStruct> =
  {
    id: 'strava.complete-activity' as const,
    configurableMaxAttempts: false,
    configSchema: StravaCompleteActivityTriggerConfigSchema,
    verificationAttemptDataSchema: StravaCompleteActivityVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
    deprecated: true,
  };
