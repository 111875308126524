import type {CohortFormSelectTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/select';
import {CohortFormSelectTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/select';
import TriggerFormWrapper from '@cohort/wallet/apps/cohort-form/triggerIntegrations/TriggerFormWrapper';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {SelectPicker} from '@cohort/wallet/components/forms/SelectPicker';
import useGetLocalizedString from '@cohort/wallet/hooks/getLocalizedString';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import type {MultiValue, SingleValue} from 'react-select';
import type {z} from 'zod';

type CohortFormSelect = z.infer<
  (typeof CohortFormSelectTriggerIntegrationSpec)['verificationAttemptInputSchema']
>;

const CohortFormSelectUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFormSelectTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config, stepId}) => {
  const {isMobile} = useScreenSize();
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
  } = useForm<CohortFormSelect>({
    defaultValues: {
      answer: config.multipleChoice ? [] : undefined,
    },
    resolver: zodResolver(CohortFormSelectTriggerIntegrationSpec.verificationAttemptInputSchema),
  });
  const getLocalizedString = useGetLocalizedString();
  const error = errors.answer?.message;
  const answer = watch('answer');
  const isMulti = config.multipleChoice;

  const localizedOptions = config.options.map(option => {
    return {
      value: option.value,
      label: getLocalizedString(option.label),
    };
  });

  return (
    <TriggerFormWrapper
      stepId={stepId}
      formId="score-form"
      error={error}
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      isValid={answer !== undefined}
      onSubmit={handleSubmit(data => onTriggerIntegrationUsageSuccess(data))}
    >
      <SelectPicker
        // @Devs - Fixes a bug with react-select on mobile: https://stackoverflow.com/a/75614415 - menuPlacement="top" goes in the fix too.
        menuPortalTarget={isMobile ? null : document.body}
        value={localizedOptions.find(option => option.value === answer)}
        options={localizedOptions}
        onChange={newValue =>
          setValue(
            'answer',
            // fix issue with type inference
            (newValue as unknown as SingleValue<(typeof localizedOptions)[number]>)?.value ?? ''
          )
        }
        isSearchable={false}
        {...(isMulti && {
          isMulti: true,
          value: localizedOptions.filter(option => answer.includes(option.value)),
          onChange: (newValue: MultiValue<(typeof localizedOptions)[number]>) =>
            setValue(
              'answer',
              newValue.map(option => option.value)
            ),
        })}
      />
    </TriggerFormWrapper>
  );
};

export default CohortFormSelectUsageComponent;
