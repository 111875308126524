import InstagramLikeMediaTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/instagram/triggerIntegrations/likeMedia/InstructionsComponent';
import type {InstagramLikeMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/likeMedia';
import {InstagramLikeMediaTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/likeMedia';
import InstagramLikeMediaTriggerActionCtaComponent from '@cohort/wallet/apps/instagram/triggerIntegrations/likeMedia/ActionCtaComponent';
import InstagramLikeMediaTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/instagram/triggerIntegrations/likeMedia/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const InstagramLikeMediaTriggerIntegration: TriggerIntegration<InstagramLikeMediaTriggerStruct> =
  {
    type: 'action',
    spec: InstagramLikeMediaTriggerIntegrationSpec,
    detailsComponent: InstagramLikeMediaTriggerIntegrationDetailsComponent,
    actionCtaComponent: InstagramLikeMediaTriggerActionCtaComponent,
    instructionsComponent: InstagramLikeMediaTriggerIntegrationInstructionsComponent,
  };
