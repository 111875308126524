import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const CohortDigitalAssetOwnedTriggerConfigSchema = z.object({
  digitalAssetCollectionId: z.string().uuid(),
});
export type CohortDigitalAssetOwnedTriggerConfig = z.infer<
  typeof CohortDigitalAssetOwnedTriggerConfigSchema
>;

export const CohortDigitalAssetOwnedVerificationAttemptDataSchema = z.object({
  ownerships: z.array(
    z.object({
      ownershipId: z.string().uuid(),
      digitalAssetId: z.string().uuid(),
    })
  ),
});
export type CohortDigitalAssetOwnedVerificationAttemptData = z.infer<
  typeof CohortDigitalAssetOwnedVerificationAttemptDataSchema
>;

export type CohortDigitalAssetOwnedTriggerStruct = TriggerIntegrationStruct<
  'cohort.digital-asset-owned',
  CohortDigitalAssetOwnedTriggerConfig,
  CohortDigitalAssetOwnedVerificationAttemptData
>;

export const CohortDigitalAssetOwnedTriggerIntegrationSpec: TriggerIntegrationSpec<CohortDigitalAssetOwnedTriggerStruct> =
  {
    id: 'cohort.digital-asset-owned' as const,
    configurableMaxAttempts: false,
    configSchema: CohortDigitalAssetOwnedTriggerConfigSchema,
    verificationAttemptDataSchema: CohortDigitalAssetOwnedVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
    deprecated: true,
  };
