import type {AppSpec, AppStruct, PerkIntegrationSpec} from '@cohort/shared/apps/app';
import {CohortFormPerkIntegrationSpec} from '@cohort/shared/apps/cohort-form/perks/fillForm';
import {CohortFormCheckboxTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/checkbox';
import {CohortFormDateTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/date';
import {CohortFormEmailTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/email';
import {CohortFillFormTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import {CohortFormLongTextTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/longText';
import {CohortFormMediaTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/media';
import {CohortFormNumberTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/number';
import {CohortFormPictureChoiceTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/pictureChoice';
import {CohortFormScoreTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/score';
import {CohortFormSelectTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/select';
import {CohortSwipeCardsTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {CohortFormTextTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/text';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';

export const CohortPerkIntegrationSpecs = [
  CohortFormPerkIntegrationSpec,
] as const satisfies ReadonlyArray<PerkIntegrationSpec>;
type CohortPerkIntegrationIds = (typeof CohortPerkIntegrationSpecs)[number]['id'];

export const CohortFormTriggerIntegrationSpecs = [
  CohortFormCheckboxTriggerIntegrationSpec,
  CohortFormDateTriggerIntegrationSpec,
  CohortFormEmailTriggerIntegrationSpec,
  CohortFormLongTextTriggerIntegrationSpec,
  CohortFormMediaTriggerIntegrationSpec,
  CohortFormNumberTriggerIntegrationSpec,
  CohortFormPictureChoiceTriggerIntegrationSpec,
  CohortFormScoreTriggerIntegrationSpec,
  CohortFormSelectTriggerIntegrationSpec,
  CohortFormTextTriggerIntegrationSpec,
  CohortSwipeCardsTriggerIntegrationSpec,
  CohortFillFormTriggerIntegrationSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;
type CohortTriggerIntegrationIds = (typeof CohortFormTriggerIntegrationSpecs)[number]['id'];

export type CohortFormAppStruct = AppStruct<
  'cohort-form',
  null,
  null,
  null,
  never,
  never,
  CohortPerkIntegrationIds,
  CohortTriggerIntegrationIds,
  never,
  never,
  never,
  never
>;

export const CohortFormAppSpec: AppSpec<CohortFormAppStruct> = {
  id: 'cohort-form',
  name: 'Cohort Form',
  merchantConnector: null,
  userConnector: null,
  syncSpec: null,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: CohortPerkIntegrationSpecs,
  triggerIntegrationSpecs: CohortFormTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: [],
  resourceSpecs: [],
  mediaSpecs: [],
};
