import {TriggerReferenceIdSchema} from '@cohort/shared/apps/cohort-form/triggers/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {LocalizedStringSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

const VerificationAttemptInputSchema = z.object({
  answer: z.boolean(),
});
type VerificationAttemptInput = z.infer<typeof VerificationAttemptInputSchema>;

const VerificationAttemptDataSchema = z.object({
  answer: z.boolean(),
});
type VerificationAttemptData = z.infer<typeof VerificationAttemptDataSchema>;

const ConfigSchema = z.object({
  referenceId: TriggerReferenceIdSchema,
  userPropertyId: z.string().uuid().nullable(),
  checkboxText: LocalizedStringSchema,
});
type Config = z.infer<typeof ConfigSchema>;

export type CohortFormCheckboxTriggerStruct = TriggerIntegrationStruct<
  'cohort-form.checkbox',
  Config,
  VerificationAttemptData,
  VerificationAttemptInput
>;

export const CohortFormCheckboxTriggerIntegrationSpec: TriggerIntegrationSpec<CohortFormCheckboxTriggerStruct> =
  {
    id: 'cohort-form.checkbox' as const,
    configurableMaxAttempts: false,
    configSchema: ConfigSchema,
    verificationAttemptDataSchema: VerificationAttemptDataSchema,
    verificationAttemptInputSchema: VerificationAttemptInputSchema,
    requiresMerchantConnection: false,
    useCases: ['customer-knowledge'],
  };
