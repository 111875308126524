import {z} from 'zod';

export const ChallengeStepAllowedMediaKinds = ['cohort.image', 'cohort.link', 'cohort.video'];

export const ChallengeStepImageMediaSchema = z.object({
  kind: z.literal('cohort.image'),
  config: z.object({
    imageFileKey: z.string(),
  }),
});

export const ChallengeStepVideoMediaSchema = z.object({
  kind: z.literal('cohort.video'),
  config: z.object({
    videoFileKey: z.string(),
    thumbnailFileKey: z.string().nullable(),
  }),
});

export const ChallengeStepLinkMediaSchema = z.object({
  kind: z.literal('cohort.link'),
  config: z.object({
    url: z.string(),
    imageSrc: z.string().nullable(),
    title: z.string().nullable(),
  }),
});

export const ChallengeStepMediaSchema = z
  .discriminatedUnion('kind', [
    ChallengeStepImageMediaSchema,
    ChallengeStepVideoMediaSchema,
    ChallengeStepLinkMediaSchema,
  ])
  .superRefine((data, ctx) => {
    if (!ChallengeStepAllowedMediaKinds.includes(data.kind)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorInvalid',
        path: ['kind'],
      });
    }
  });
export type ChallengeStepMedia = z.infer<typeof ChallengeStepMediaSchema>;
