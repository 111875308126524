import EmbedSkeleton from '@cohort/components-xps/components/apps/EmbedSkeleton';
import type {CohortFormNumberTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/number';
import {CohortFormNumberTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/number';
import TriggerFormWrapper, {
  useSyncUserAttributesToForm,
} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/TriggerFormWrapper';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {Input} from '@cohort/wallet/components/forms/Input';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import type {z} from 'zod';

type CohortFormNumber = z.infer<
  (typeof CohortFormNumberTriggerIntegrationSpec)['verificationAttemptInputSchema']
>;

const CohortFormNumberUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFormNumberTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, stepId, config}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.number',
  });
  const {
    register,
    handleSubmit,
    resetField,
    formState: {errors, isValid},
  } = useForm<CohortFormNumber>({
    resolver: zodResolver(CohortFormNumberTriggerIntegrationSpec.verificationAttemptInputSchema),
  });
  const shouldDisplayLoader = useSyncUserAttributesToForm(config.userPropertyId, resetField);
  const error = errors.answer?.message;

  if (shouldDisplayLoader) {
    return <EmbedSkeleton width="100%" height={200} />;
  }

  return (
    <TriggerFormWrapper
      stepId={stepId}
      formId="number-form"
      error={error}
      isValid={isValid}
      onSubmit={handleSubmit(data => onTriggerIntegrationUsageSuccess(data))}
    >
      <Input
        type="number"
        placeholder={t('placeholderNumberInput')}
        register={register('answer', {
          setValueAs: (value: string) => {
            const number = parseFloat(value);

            return isNaN(number) ? null : number;
          },
        })}
      />
    </TriggerFormWrapper>
  );
};

export default CohortFormNumberUsageComponent;
