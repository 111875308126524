import EmbedSkeleton from '@cohort/components-xps/components/apps/EmbedSkeleton';
import Title from '@cohort/components-xps/components/Title';
import type {
  CohortScanQrCodeTriggerConfig,
  CohortScanQrCodeTriggerStruct,
} from '@cohort/shared/apps/cohort/triggers/scanQrCode';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import Button from '@cohort/wallet/components/button/Button';
import type {TriggerCtaTrackingMetadata} from '@cohort/wallet/components/challenges/NextStepCta';
import {BaseModal, DrawerModal} from '@cohort/wallet/components/modals/Modal';
import Subtitle from '@cohort/wallet/components/Subtitle';
import useCameraPermission from '@cohort/wallet/hooks/cameraPermission';
import useGetLocalizedString from '@cohort/wallet/hooks/getLocalizedString';
import useChallengeContext from '@cohort/wallet/hooks/useChallengeContext';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import {useVerifyStep} from '@cohort/wallet/hooks/useStepVerification';
import type {ChallengeStepWDto} from '@cohort/wallet-schemas/challengeSteps';
import {SealCheck} from '@phosphor-icons/react';
import {useIsMutating} from '@tanstack/react-query';
import {Html5Qrcode} from 'html5-qrcode';
import {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {match} from 'ts-pattern';

type QrCodeScannerModalProps = {
  trackingMetadata: TriggerCtaTrackingMetadata;
  onClose: () => void;
};

type QrCodeScannerDesktopWarningModalProps = {
  trackingMetadata: TriggerCtaTrackingMetadata;
  onClose: () => void;
};

const scannerConfig = {
  fps: 10,
  qrbox: {width: 250, height: 250},
  aspectRatio: 1,
  rememberLastUsedCamera: true,
};

const QrCodeScannerModal: React.FC<QrCodeScannerModalProps> = ({trackingMetadata, onClose}) => {
  const {campaign} = useChallengeContext();
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.scan-qr-code',
  });
  const [scannerLoading, setScannerLoading] = useState(true);
  const cameraPermission = useCameraPermission();
  const {processStepVerificationAsync} = useVerifyStep();
  const observer = useRef<MutationObserver | null>(null);
  const qrCodeScanner = useRef<Html5Qrcode | null>(null);
  const [invalidQrCode, setInvalidQrCode] = useState(false);
  const [scannedStep, setScannedStep] = useState<ChallengeStepWDto | null>(null);
  const {challengeSlug: challengeSlugParam} = useParams();
  const getLocalizedString = useGetLocalizedString();
  const ongoingVerifications = useIsMutating({
    mutationKey: ['verifyStep', scannedStep?.id],
  });
  const isVerifying = ongoingVerifications > 0;

  useEffect(() => {
    return () => {
      try {
        qrCodeScanner.current?.stop();
        observer.current?.disconnect();
      } catch (err) {
        void err;
      }
    };
  }, []);

  const qrCodeScannerHandler = useCallback(
    async (node: HTMLElement | null) => {
      if (node && qrCodeScanner.current === null) {
        qrCodeScanner.current = new Html5Qrcode('reader');
        observer.current = new MutationObserver(() => {
          setScannerLoading(!(qrCodeScanner.current?.getState() === 2));
        });
        observer.current.observe(node, {childList: true, subtree: true});

        await qrCodeScanner.current.start(
          {facingMode: 'environment'},
          scannerConfig,
          async qrCodeMessage => {
            await qrCodeScanner.current?.stop();
            qrCodeScanner.current = null;
            try {
              // Ensure the format of the url and navigate to it
              const url = new URL(qrCodeMessage);
              const regex = /\/challenges\/([\w-]+)\/steps\/([\w-]+)\/verify/;
              const match = url.pathname.match(regex);

              if (match) {
                const challengeSlug = match[1];
                const stepId = match[2];
                const step = campaign.challenge.steps.find(step => step.id === stepId);
                const trigger = step?.triggers[0];

                if (
                  challengeSlug &&
                  challengeSlug === challengeSlugParam &&
                  trigger &&
                  trigger.triggerIntegrationId === 'cohort.scan-qr-code'
                ) {
                  return setScannedStep(step);
                }
              }
              setInvalidQrCode(true);
            } catch {
              setInvalidQrCode(true);
            }
          },
          () => {}
        );
      }
    },
    [challengeSlugParam, campaign.challenge.steps]
  );

  const modalContent = match(cameraPermission)
    .with('unknown', () => <EmbedSkeleton width="100%" height={350} />)
    .with('denied', () => (
      <Fragment>
        <Title>{t('cameraPermissionDeniedTitle')}</Title>
        <Subtitle className="text-red-500">{t('cameraPermissionDeniedDescription')}</Subtitle>
        <p>{t('cameraPermissionDeniedHint')}</p>
      </Fragment>
    ))
    .otherwise(() => {
      // If the step is scanned, we display the success message and the verify button
      if (scannedStep) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const trigger = scannedStep.triggers[0]!;
        const triggerConfig = trigger.triggerIntegrationConfig as CohortScanQrCodeTriggerConfig;
        const successImageUrl = triggerConfig.successImageFileKey
          ? getImageUrl(import.meta.env.COHORT_ENV, triggerConfig.successImageFileKey, {
              h: Sizes.M,
              w: Sizes.M,
            })
          : null;
        const successMessage = getLocalizedString(triggerConfig.successMessage);

        return (
          <div className="flex justify-center">
            <div className="flex max-w-xl flex-col items-center justify-center gap-4">
              <SealCheck size={40} className="text-[--xps-accent-color]" />
              {successImageUrl && (
                <img src={successImageUrl} width={200} alt="success" className="aspect-square" />
              )}
              <Title className="text-center">{successMessage}</Title>
            </div>
          </div>
        );
      }

      // If the step is not scanned, we display the QR code scanner
      return (
        <div className="grid [&>*]:[grid-column:1] [&>*]:[grid-row:1]">
          <div ref={qrCodeScannerHandler} id="reader"></div>
          {scannerLoading && <EmbedSkeleton width="100%" height={350} />}
        </div>
      );
    });

  return (
    <DrawerModal
      onClose={onClose}
      tracking={{namespace: 'modals.challenges.qrCodeScanner', metadata: trackingMetadata}}
    >
      <div className="flex flex-col gap-4">
        {invalidQrCode ? (
          <div className="flex flex-col gap-4">
            <Title>{t('invalidQrCodeTitle')}</Title>
            <Subtitle className="text-red-500">{t('invalidQrCodeDescription')}</Subtitle>
          </div>
        ) : (
          modalContent
        )}
        {scannedStep ? (
          <Button
            variant="primary"
            onClick={async () => {
              await processStepVerificationAsync(
                {
                  challengeStepId: scannedStep.id,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  challengeStepTriggerId: scannedStep.triggers[0]!.id,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  participationId: campaign.challenge.participation!.id,
                  userConnectionId: null,
                },
                true
              );
              onClose();
            }}
            loading={isVerifying}
            className="w-full"
            tracking={{
              namespace: 'modals.challenges.qrCodeScanner.verify',
              metadata: trackingMetadata,
            }}
          >
            {t('continueBtn')}
          </Button>
        ) : (
          <Button
            variant="secondary"
            tracking={{
              namespace: 'modals.challenges.qrCodeScanner.cancel',
              metadata: trackingMetadata,
            }}
            onClick={onClose}
            className="w-full"
          >
            {t('cancelBtn')}
          </Button>
        )}
      </div>
    </DrawerModal>
  );
};

const QrCodeScannerDesktopWarningModal: React.FC<QrCodeScannerDesktopWarningModalProps> = ({
  trackingMetadata,
  onClose,
}) => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.scan-qr-code',
  });

  return (
    <BaseModal
      onClose={onClose}
      tracking={{
        namespace: 'modals.challenges.qrCodeScannerDesktopWarning',
        metadata: trackingMetadata,
      }}
    >
      <div className="flex flex-col gap-4">
        <Title>{t('desktopWarningTitle')}</Title>
        <p>{t('desktopWarningContent')}</p>
        <Button
          variant="primary"
          type="button"
          className="w-full"
          tracking={{
            namespace: 'modals.challenges.qrCodeScannerDesktopWarning.close',
            metadata: trackingMetadata,
          }}
          onClick={onClose}
        >
          {t('closeBtn')}
        </Button>
      </div>
    </BaseModal>
  );
};

const CohortScanQrCodeTriggerIntegrationActionCtaComponent: React.FC<
  ActionCtaComponentProps<CohortScanQrCodeTriggerStruct>
> = ({trackingMetadata}) => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.scan-qr-code',
  });
  const [qrCodeScannerOpen, setQrCodeScannerOpen] = useState(false);
  const {isMobile} = useScreenSize();

  return (
    <Fragment>
      <Button
        variant="primary"
        className="w-full"
        tracking={{namespace: 'challenges.detail.actionCta', metadata: trackingMetadata}}
        onClick={() => setQrCodeScannerOpen(true)}
      >
        {t('ctaTitle')}
      </Button>
      {isMobile && qrCodeScannerOpen && (
        <QrCodeScannerModal
          onClose={() => setQrCodeScannerOpen(false)}
          trackingMetadata={trackingMetadata}
        />
      )}
      {!isMobile && qrCodeScannerOpen && (
        <QrCodeScannerDesktopWarningModal
          onClose={() => setQrCodeScannerOpen(false)}
          trackingMetadata={trackingMetadata}
        />
      )}
    </Fragment>
  );
};

export default CohortScanQrCodeTriggerIntegrationActionCtaComponent;
