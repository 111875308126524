import Tooltip from '@cohort/shared-frontend/components/Tooltip';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {RadioCardOption} from '@cohort/shared-frontend/utils/form';
import type {FormField} from '@cohort/wallet/components/forms/fileInput/utils';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import {useState} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {isDefined} from 'remeda';

type RadioCardsProps<T extends FieldValues> = Omit<JSX.IntrinsicElements['input'], 'name'> &
  FormField<T> & {
    options: RadioCardOption[];
    hideLabels?: boolean;
    trackingNamespace: TrackingConfig['namespace'];
    config?: {
      multipleChoice: boolean;
      maxMultipleChoices?: number | null; // null for unlimited choices.
    };
  };

export default function RadioCardsInput<T extends FieldValues>({
  register,
  control,
  rules,
  options,
  disabled,
  className,
  trackingNamespace,
  name,
  config = {
    multipleChoice: false,
    maxMultipleChoices: null,
  },
  hideLabels = false,
  ...props
}: RadioCardsProps<T>): JSX.Element {
  const [hasReachedMaxMultipleChoices, setHasReachedMaxMultipleChoices] = useState(false);
  const {field} = useController({
    control,
    name,
  });

  const {multipleChoice, maxMultipleChoices} = config;

  const handleOptionClick = (optionValue: string): void => {
    if (multipleChoice) {
      const currentValues: Array<string> = Array.isArray(field.value) ? field.value : [];
      const isSelected = currentValues.includes(optionValue);

      if (
        !isSelected &&
        isDefined(maxMultipleChoices) &&
        currentValues.length >= maxMultipleChoices
      ) {
        setHasReachedMaxMultipleChoices(true);
        // Prevent adding more options if the max number of choices is reached
        return;
      }

      const newValues = isSelected
        ? currentValues.filter(value => value !== optionValue)
        : [...currentValues, optionValue];
      field.onChange(newValues);

      setHasReachedMaxMultipleChoices(
        isDefined(maxMultipleChoices) && newValues.length >= maxMultipleChoices
      );
    } else {
      field.onChange(optionValue);
    }
  };

  return (
    <div
      className={cn(
        options.length === 1 ? 'grid grid-cols-1 justify-items-center' : 'grid grid-cols-2 gap-4',
        className
      )}
    >
      {options.map((option: RadioCardOption) => {
        const isSelected =
          (multipleChoice && Array.isArray(field.value) && field.value.includes(option.value)) ||
          field.value === option.value;

        return (
          <div
            key={option.value}
            className={cn(
              options.length === 1 ? 'w-1/2' : 'grow basis-0',
              !isSelected && hasReachedMaxMultipleChoices && 'opacity-50 hover:cursor-not-allowed'
            )}
            onClick={e => {
              e.preventDefault();
              if (!option.disabled && !disabled) {
                handleOptionClick(option.value);
              }
            }}
          >
            <Tooltip
              contentAsChild
              content={option.tooltipContent}
              disabled={option.tooltipContent === undefined}
            >
              <div
                className={cn(
                  'relative h-full flex-1 cursor-pointer rounded-[--xps-card-border-radius] border bg-[--xps-card-background-color]',
                  isSelected
                    ? 'border-2 border-[--xps-input-active-border-color]'
                    : 'border-[--xps-border-color]',
                  (option.disabled || disabled) && '!cursor-not-allowed bg-slate-50 opacity-50'
                )}
              >
                <label
                  className={cn(
                    'flex h-full cursor-pointer flex-col justify-between gap-4 p-4',
                    (option.disabled || disabled) && 'cursor-not-allowed'
                  )}
                  htmlFor={`${name}.${option.value}`}
                >
                  {option.prefix && option.prefix}
                  {option.label ||
                    (option.description && (
                      <div className="flex-1 flex-col gap-2 text-sm">
                        {!hideLabels && <span className="font-medium">{option.label}</span>}
                        {option.description && (
                          <span className="block text-slate-600">{option.description}</span>
                        )}
                      </div>
                    ))}
                  {option.suffix && option.suffix}
                </label>
                <input
                  className="pointer-events-none absolute opacity-0"
                  data-testid={name}
                  {...register(name, rules)}
                  ref={field.ref}
                  id={`${name}.${option.value}`}
                  type={multipleChoice ? 'checkbox' : 'radio'}
                  value={option.value}
                  onBlur={undefined}
                  onChange={e => {
                    props.onChange?.(e);
                  }}
                  checked={
                    Array.isArray(field.value)
                      ? field.value.includes(option.value)
                      : field.value === option.value
                  }
                  disabled={option.disabled || disabled}
                />
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
}
