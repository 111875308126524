import TiktokKeywordInBioTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/tiktok/triggers/keywordInBio/InstructionsComponent';
import type {TikTokKeywordInBioTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import {TikTokKeywordInBioTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import TiktokKeywordInBioTriggerActionCtaComponent from '@cohort/wallet/apps/tiktok/triggers/keywordInBio/ActionCtaComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const TikTokKeywordInBioTriggerIntegration: TriggerIntegration<TikTokKeywordInBioTriggerStruct> =
  {
    type: 'action',
    spec: TikTokKeywordInBioTriggerSpec,
    instructionsComponent: TiktokKeywordInBioTriggerIntegrationInstructionsComponent,
    actionCtaComponent: TiktokKeywordInBioTriggerActionCtaComponent,
  };
