import InstructionsWrapper from '@cohort/components-xps/components/apps/InstructionsWrapper';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {CohortScanQrCodeTriggerStruct} from '@cohort/shared/apps/cohort/triggers/scanQrCode';
import type {InstructionsComponentProps} from '@cohort/shared/apps/trigger';
import {getI18n, useTranslation} from 'react-i18next';

const CohortScanQrCodeTriggerIntegrationInstructionsComponent: React.FC<
  InstructionsComponentProps<CohortScanQrCodeTriggerStruct>
> = ({selectedLanguage}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'apps.cohort.triggers.scan-qr-code',
    lng: selectedLanguage ?? getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });

  return (
    <InstructionsWrapper>
      <ul className="mt-2 list-disc pl-6">
        <li>
          <p>{t('instructionsStep1')}</p>
        </li>
        <li>
          <p>{t('instructionsStep2')}</p>
        </li>
        <li>
          <p>{t('instructionsStep3')}</p>
        </li>
      </ul>
    </InstructionsWrapper>
  );
};

export default CohortScanQrCodeTriggerIntegrationInstructionsComponent;
