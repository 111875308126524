import DefaultInstructions from '@cohort/components-xps/components/apps/facebook/triggers/DefaultInstructions';
import InstructionsWrapper from '@cohort/components-xps/components/apps/InstructionsWrapper';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {FacebookTagInPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/tagInPost';
import type {FacebookTagInPostUnverifiedTriggerStruct} from '@cohort/shared/apps/facebook/triggers/tagInPostUnverified';
import type {InstructionsComponentProps} from '@cohort/shared/apps/trigger';
import {Fragment} from 'react';
import {getI18n, useTranslation} from 'react-i18next';

const FacebookTagInPostTriggerIntegrationInstructionsComponent: React.FC<
  InstructionsComponentProps<
    FacebookTagInPostTriggerStruct | FacebookTagInPostUnverifiedTriggerStruct
  >
> = ({connected, selectedLanguage}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'apps.facebook.triggers.tag-in-post',
    lng: selectedLanguage ?? getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });

  return (
    <InstructionsWrapper>
      <ul className="mt-2 list-disc pl-6">
        {connected ? (
          <Fragment>
            <li>
              <p>{t('instructionsStep1')}</p>
            </li>
            <li>
              <p>{t('instructionsStep2')}</p>
            </li>
            <li>
              <p>{t('instructionsStep3')}</p>
            </li>
            <li>
              <p>{t('instructionsStep4')}</p>
            </li>
          </Fragment>
        ) : (
          <DefaultInstructions />
        )}
      </ul>
    </InstructionsWrapper>
  );
};

export default FacebookTagInPostTriggerIntegrationInstructionsComponent;
