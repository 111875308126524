import {TriggerReferenceIdSchema} from '@cohort/shared/apps/cohort-form/triggers/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {EmailSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

const VerificationAttemptInputSchema = z.object({
  answer: EmailSchema,
});
type VerificationAttemptInput = z.infer<typeof VerificationAttemptInputSchema>;

const VerificationAttemptDataSchema = z.object({
  answer: EmailSchema,
});
type VerificationAttemptData = z.infer<typeof VerificationAttemptDataSchema>;

const ConfigSchema = z.object({
  referenceId: TriggerReferenceIdSchema,
  userPropertyId: z.string().uuid().nullable(),
});
type Config = z.infer<typeof ConfigSchema>;

export type CohortFormEmailTriggerStruct = TriggerIntegrationStruct<
  'cohort-form.email',
  Config,
  VerificationAttemptData,
  VerificationAttemptInput
>;

export const CohortFormEmailTriggerIntegrationSpec: TriggerIntegrationSpec<CohortFormEmailTriggerStruct> =
  {
    id: 'cohort-form.email' as const,
    configurableMaxAttempts: false,
    configSchema: ConfigSchema,
    verificationAttemptDataSchema: VerificationAttemptDataSchema,
    verificationAttemptInputSchema: VerificationAttemptInputSchema,
    requiresMerchantConnection: false,
  };
