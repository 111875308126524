import {
  ImageMimeTypeSchema,
  PdfMimeTypeSchema,
  VideoMimeTypeSchema,
  WalletImageMimeTypeSchema,
} from '@cohort/shared/schema/common/assets';
import type {PrivateContentPerkContentType} from '@cohort/shared/schema/common/perks';
import mime from 'mime';
export function isVideoFile(mimeType: string): boolean {
  return VideoMimeTypeSchema.safeParse(mimeType).success;
}

export function isImageFile(mimeType: string): boolean {
  return ImageMimeTypeSchema.safeParse(mimeType).success;
}

export function isWalletImageFile(mimeType: string): boolean {
  return WalletImageMimeTypeSchema.safeParse(mimeType).success;
}

export function isDocumentFile(mimeType: string): boolean {
  return PdfMimeTypeSchema.safeParse(mimeType).success;
}

export function contentTypeFromMimeType(mimeType: string): PrivateContentPerkContentType {
  if (isVideoFile(mimeType)) {
    return 'video';
  }
  if (mimeType === 'application/pdf') {
    return 'pdf';
  }
  throw new Error(`Perk private content cannot be of mimeType: ${mimeType}`);
}

export function isImageFileUrl(fileUrl: string): boolean {
  const mimeType = mime.getType(fileUrl);
  return ImageMimeTypeSchema.safeParse(mimeType).success;
}

export function isVideoFileUrl(fileUrl: string): boolean {
  const mimeType = mime.getType(fileUrl);
  return VideoMimeTypeSchema.safeParse(mimeType).success;
}
