import type {CohortFormAppStruct} from '@cohort/shared/apps/cohort-form';
import {CohortFormAppSpec} from '@cohort/shared/apps/cohort-form';
import type {App} from '@cohort/wallet/apps';
import {CohortFormPerkIntegration} from '@cohort/wallet/apps/cohort-form/perkIntegrations/fillForm/PerkIntegration';
import {CohortFormCheckboxTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/checkbox/TriggerIntegration';
import {CohortFormDateTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/date/TriggerIntegration';
import {CohortFormEmailTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/email/TriggerIntegration';
import {CohortFormLongTextTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/longText/TriggerIntegration';
import {CohortFormMediaTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/media/TriggerIntegration';
import {CohortFormNumberTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/number/TriggerIntegration';
import {CohortFormPictureChoiceTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/pictureChoice/TriggerIntegration';
import {CohortFormScoreTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/score/TriggerIntegration';
import {CohortFormSelectTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/select/TriggerIntegration';
import {CohortSwipeCardsTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/swipeCards/TriggerIntegration';
import {CohortFormTextTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/text/TriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const CohortFormApp: App<CohortFormAppStruct> = {
  medias: [],
  spec: CohortFormAppSpec,
  perkIntegrations: [CohortFormPerkIntegration],
  triggerIntegrations: [
    CohortSwipeCardsTriggerIntegration,
    CohortFormDateTriggerIntegration,
    CohortFormTextTriggerIntegration,
    CohortFormLongTextTriggerIntegration,
    CohortFormNumberTriggerIntegration,
    CohortFormEmailTriggerIntegration,
    CohortFormCheckboxTriggerIntegration,
    CohortFormScoreTriggerIntegration,
    CohortFormMediaTriggerIntegration,
    CohortFormSelectTriggerIntegration,
    CohortFormPictureChoiceTriggerIntegration,
  ] as TriggerIntegration[],
  logo: null,
};

export default CohortFormApp;
