import type {CohortFormPictureChoiceTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/pictureChoice';
import {CohortFormPictureChoiceTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/pictureChoice';
import TriggerFormWrapper from '@cohort/wallet/apps/cohort-form/triggerIntegrations/TriggerFormWrapper';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import PictureChoiceInput from '@cohort/wallet/components/forms/PictureChoiceInput';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import type {z} from 'zod';

type CohortFormPictureChoice = z.infer<
  (typeof CohortFormPictureChoiceTriggerIntegrationSpec)['verificationAttemptInputSchema']
>;

const CohortFormPictureChoiceUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFormPictureChoiceTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config, stepId}) => {
  const {
    control,
    handleSubmit,
    register,
    formState: {errors, isValid},
  } = useForm<CohortFormPictureChoice>({
    resolver: zodResolver(
      CohortFormPictureChoiceTriggerIntegrationSpec.verificationAttemptInputSchema
    ),
  });
  const error = errors.answer?.message;

  return (
    <TriggerFormWrapper
      stepId={stepId}
      formId="picture-choice-form"
      error={error}
      isValid={isValid}
      onSubmit={handleSubmit(data => onTriggerIntegrationUsageSuccess(data))}
    >
      <PictureChoiceInput
        name="answer"
        config={{
          pictureChoiceOptions: config.pictureChoiceOptions,
          showLabels: config.showLabels,
          multipleChoice: config.multipleChoice,
          maxMultipleChoices: config.maxMultipleChoices,
        }}
        control={control}
        register={register}
      />
    </TriggerFormWrapper>
  );
};

export default CohortFormPictureChoiceUsageComponent;
