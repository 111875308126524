import type {CohortFormScoreTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/score';
import {CohortFormScoreTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/score';
import CohortFormScoreSocialLayerComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/score/SocialLayerComponent';
import CohortFormScoreUsageComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/score/UsageComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortFormScoreTriggerIntegration: TriggerIntegration<CohortFormScoreTriggerStruct> = {
  type: 'embedded',
  spec: CohortFormScoreTriggerIntegrationSpec,
  usageComponent: CohortFormScoreUsageComponent,
  socialLayerComponent: CohortFormScoreSocialLayerComponent,
  disableExponentialBackoff: true,
};
