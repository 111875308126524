import LinkPreviewCard from '@cohort/components-xps/components/apps/LinkPreviewCard';
import type {CohortVisitLinkTriggerStruct} from '@cohort/shared/apps/cohort/triggers/visitLink';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';

const CohortVisitLinkTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<CohortVisitLinkTriggerStruct>
> = ({config}) => {
  if (!config.title && !config.imageUrl) {
    return null;
  }

  return <LinkPreviewCard title={config.title} description={config.url} image={config.imageUrl} />;
};

export default CohortVisitLinkTriggerIntegrationDetailsComponent;
