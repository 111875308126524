import {
  GoogleFitActivityGroupsSchema,
  GoogleFitActivityTypeSchema,
} from '@cohort/shared/apps/google-fit/activity';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {startDateBeforeEndDateRefinement} from '@cohort/shared/schema/common/dates';
import {z} from 'zod';

export const GoogleFitCompleteActivityTriggerConfigSchema = z
  .object({
    activityGroups: z
      .array(GoogleFitActivityGroupsSchema)
      .nonempty({message: 'errorNoOptionsSelected'})
      .optional(),
    minNumberOfSteps: z.number().min(0).nullable(),
    minAverageElevationGainMeters: z.number().min(0).nullable(),
    minAverageSpeedMetersPerSecond: z.number().min(0).nullable(),
    minDistanceMeters: z.number().min(0).nullable(),
    minDurationSeconds: z.number().min(0).nullable(),
    activityEndDateTimestamp: z.number(),
    activityStartDateTimestamp: z.number(),
  })
  .superRefine(
    startDateBeforeEndDateRefinement({
      startDatePropertyName: 'activityStartDateTimestamp',
      endDatePropertyName: 'activityEndDateTimestamp',
    })
  );

export type GoogleFitCompleteActivityTriggerConfig = z.infer<
  typeof GoogleFitCompleteActivityTriggerConfigSchema
>;

export const GoogleFitCompleteActivityVerificationAttemptDataSchema = z
  .object({
    activityId: z.string(),
    activityType: GoogleFitActivityTypeSchema,
    activityStartDate: z.date().nullable(),
    averageSpeedMetersPerSecond: z.number().min(0).nullable(),
    distanceMeters: z.number().min(0).nullable(),
    durationSeconds: z.number().min(0).nullable(),
    numberOfSteps: z.number().min(0).nullable(),
    totalElevationGainMeters: z.number().min(0).nullable(),
    googleUserId: z.string(),
    googleUsername: z.string().nullable(),
  })
  .nullable();

export type GoogleFitCompleteActivityVerificationAttemptData = z.infer<
  typeof GoogleFitCompleteActivityVerificationAttemptDataSchema
>;

export type GoogleFitCompleteActivityTriggerStruct = TriggerIntegrationStruct<
  'google-fit.complete-activity',
  GoogleFitCompleteActivityTriggerConfig,
  GoogleFitCompleteActivityVerificationAttemptData
>;

export const GoogleFitCompleteActivityTriggerIntegrationSpec: TriggerIntegrationSpec<GoogleFitCompleteActivityTriggerStruct> =
  {
    id: 'google-fit.complete-activity' as const,
    configurableMaxAttempts: false,
    configSchema: GoogleFitCompleteActivityTriggerConfigSchema,
    verificationAttemptDataSchema: GoogleFitCompleteActivityVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
    deprecated: true,
  };
