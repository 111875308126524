import {z} from 'zod';

export const AssetWSchema = z.object({
  fileKey: z.string(),
  mimeType: z.string(),
});
export type AssetWDto = z.infer<typeof AssetWSchema>;

export const BaseSignedUrlWSchema = z.object({
  signedUrl: z.string(),
  fileKey: z.string(),
  maxFileSize: z.number(),
});
export type BaseSignedUrlWDto = z.infer<typeof BaseSignedUrlWSchema>;

export const SignedUrlWSchema = BaseSignedUrlWSchema.extend({
  thumbnails: z.array(BaseSignedUrlWSchema),
});
export type SignedUrlWDto = z.infer<typeof SignedUrlWSchema>;

export const ProcessUploadCompleteWDtoSchema = z.object({
  fileKey: z.string(),
});
export type ProcessUploadCompleteWDto = z.infer<typeof ProcessUploadCompleteWDtoSchema>;
