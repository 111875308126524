import type {CohortLinkConfig, CohortLinkUsageData} from '@cohort/shared/apps/cohort/perks/link';
import {defaultErrorMessage} from '@cohort/shared/models';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import type {PerkUsageComponentProps} from '@cohort/wallet/apps/PerkIntegration';
import {usePerkAccess} from '@cohort/wallet/hooks/api/PerkAccesses';
import useNotify from '@cohort/wallet/hooks/notify';
import {doUsePerkAccess} from '@cohort/wallet/lib/Endpoints';
import React, {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

export const CohortLinkPerkUsageComponent: React.FC<PerkUsageComponentProps> = props => {
  const {
    perk,
    onClose,
    onPerkUsageSuccess,
    perkAccess: {id: perkAccessId},
  } = props;

  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'perkIntegrations.link.perkUsageComponent',
  });
  const notify = useNotify();
  const isMounted = useRef(false);

  const {data: perkAccess} = usePerkAccess(perkAccessId);

  useEffect(() => {
    if (isMounted.current || perkAccess === undefined) {
      return;
    }
    isMounted.current = true;
    // Open the URL in a new tab
    window.open((perk.integration?.config as CohortLinkConfig).url, '_blank');
    doUsePerkAccess(perkAccess.id, null, {} as CohortLinkUsageData)
      .then(() => {
        onPerkUsageSuccess();
      })
      .catch(err => {
        if (isCohortError(err, 'perk.usages-limit-reached')) {
          notify('error', t('maxUsagesReached'), {autoClose: false});
          return;
        }
        notify('error', defaultErrorMessage);
      });
    onClose();
  }, [onPerkUsageSuccess, perk, perkAccess, notify, t, onClose]);

  return <Fragment />;
};
