import type {CohortFormMediaTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/media';
import type {WalletAssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isEmptyFileList, isFile, isFileList} from '@cohort/shared-frontend/utils/isFile';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import Button from '@cohort/wallet/components/button/Button';
import ProgressButton from '@cohort/wallet/components/button/ProgressButton';
import {EmbeddedTriggerCta} from '@cohort/wallet/components/challenges/NextStepCta';
import {CheckboxInput} from '@cohort/wallet/components/forms/CheckboxInput';
import {
  FileInput,
  FileInputUpload,
  FileInputUploader,
} from '@cohort/wallet/components/forms/fileInput/FileInput';
import {getAcceptedAssetKindsForMediaInput} from '@cohort/wallet/components/forms/fileInput/utils';
import type {FeatureFlags} from '@cohort/wallet/FeatureFlags';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import useNotify from '@cohort/wallet/hooks/notify';
import useChallengeContext from '@cohort/wallet/hooks/useChallengeContext';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {uploadAsset} from '@cohort/wallet/lib/Utils';
import {zodResolver} from '@hookform/resolvers/zod';
import {useIsMutating} from '@tanstack/react-query';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {motion} from 'motion/react';
import {useEffect, useMemo, useState} from 'react';
import type {
  Control,
  FieldError,
  FieldErrors,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  UseFormRegister,
} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isDefined} from 'remeda';
import {match, P} from 'ts-pattern';
import {z} from 'zod';

const ErrorMessage: React.FC<{
  error: string | FieldError | Merge<FieldError, FieldErrorsImpl<FieldValues>> | undefined;
}> = ({error}) => {
  if (!error) return null;

  return (
    <motion.p className="mt-2 text-red-500" initial={{opacity: 0}} animate={{opacity: 1}}>
      {error.toString()}
    </motion.p>
  );
};

type MediaUploadButtonProps = {
  control: Control<CohortFormMedia>;
  register: UseFormRegister<CohortFormMedia>;
  assetKind: WalletAssetKind;
  errors: FieldErrors<CohortFormMedia>;
  configBtnText: () => string;
};

const MediaUploadButton: React.FC<MediaUploadButtonProps> = ({
  control,
  register,
  assetKind,
  errors,
  configBtnText,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const error = errors.answer?.message;

  return (
    <FileInput name="answer" control={control} register={register} assetKind={assetKind}>
      <div className="relative">
        <Button
          variant="primary"
          className="w-full"
          tracking={{
            namespace: `triggerIntegrations.media-form.submit`,
          }}
          loading={isProcessing}
        >
          <FileInputUpload
            name="answer"
            assetKind={assetKind}
            accept={AllowedAssetMimeTypes[assetKind].options.join(',')}
            setIsProcessing={setIsProcessing}
          />
          {configBtnText()}
        </Button>
        <ErrorMessage error={error} />
      </div>
    </FileInput>
  );
};

type FileInputWithPreviewProps = {
  control: Control<CohortFormMedia>;
  register: UseFormRegister<CohortFormMedia>;
  assetKind: WalletAssetKind;
  hasMedia: boolean;
  isAnswerDefined: boolean;
  error: string | FieldError | Merge<FieldError, FieldErrorsImpl<FieldValues>> | undefined;
};

const FileInputWithPreview: React.FC<FileInputWithPreviewProps> = ({
  control,
  register,
  assetKind,
  hasMedia,
  isAnswerDefined,
  error,
}) => {
  const {campaign} = useChallengeContext();
  const {isMobile} = useScreenSize();

  if (hasMedia && !isAnswerDefined) {
    return null;
  }

  let fileInputHeight = 460;
  if (isMobile) {
    fileInputHeight = campaign.socialLayerEnabled ? 250 : 500;
  }

  return (
    <>
      <FileInput name="answer" control={control} register={register} assetKind={assetKind}>
        <FileInputUploader {...(hasMedia ? {} : {height: fileInputHeight})} />
      </FileInput>
      <ErrorMessage error={error} />
    </>
  );
};

type SocialLayerConsentProps = {
  register: UseFormRegister<CohortFormMedia>;
  hasDarkBg: boolean;
  selectedFile: File | null;
};

const SocialLayerConsent: React.FC<SocialLayerConsentProps> = ({
  register,
  hasDarkBg,
  selectedFile,
}) => {
  const {campaign} = useChallengeContext();
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.media',
  });

  if (!campaign.socialLayerEnabled || !selectedFile || !isFile(selectedFile)) {
    return null;
  }

  return (
    <div className="my-6">
      <label className="flex cursor-pointer items-center gap-2">
        <CheckboxInput register={register('socialLayerConsent')} />
        <span className={cn('text-sm font-normal', hasDarkBg ? 'text-white' : 'text-black/90')}>
          {t('socialLayerConsent')}
        </span>
      </label>
    </div>
  );
};
const fileValidatorRequired = (file: string | File | FileList): boolean => {
  if (isFileList(file) && file.length === 0) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return file !== null && file !== undefined;
};

const CohortFormMediaSchema = z.object({
  answer: z.any().refine(fileValidatorRequired),
  socialLayerConsent: z.boolean(),
});
type CohortFormMedia = z.infer<typeof CohortFormMediaSchema>;

const CohortFormMediaUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFormMediaTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config, stepId, hasMedia, handleAnswerChange}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.media',
  });

  const {featureVideoModeration} = useFlags<FeatureFlags>();
  const {hasDarkBg} = useThemeContext();
  const notify = useNotify();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: {errors, isValid},
  } = useForm<CohortFormMedia>({
    resolver: zodResolver(CohortFormMediaSchema),
    defaultValues: {
      socialLayerConsent: false,
      answer: null,
    },
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const ongoingVerifications = useIsMutating({
    mutationKey: ['verifyStep', stepId],
  });
  const isVerifying = ongoingVerifications > 0;
  const selectedFile = watch('answer');

  const onUploadError = (error: Error | unknown): void => {
    if (isCohortError(error, 'media.forbidden-content')) {
      setValue('answer', null);
      notify('error', t('errorFileUploadForbiddenContent'));
      return;
    }
    notify('error', t('errorFileUploadFailed'));
  };

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: WalletAssetKind}) =>
      uploadAsset(file, assetKind, featureVideoModeration, progress => setUploadProgress(progress)),
    onError: (error: Error) => onUploadError(error),
  });

  const error = errors.answer?.message;
  const answer = watch('answer');
  const assetKind = getAcceptedAssetKindsForMediaInput(config.mediaType);

  const configBtnText = useMemo(
    () => (): string => {
      return match({mediaType: config.mediaType, answer})
        .with(
          {
            mediaType: 'image',
            answer: P.when(answer => isEmptyFileList(answer) || !isDefined(answer)),
          },
          () => t('submitBtnImage')
        )
        .with(
          {
            mediaType: 'video',
            answer: P.when(answer => isEmptyFileList(answer) || !isDefined(answer)),
          },
          () => t('submitBtnVideo')
        )
        .with(
          {
            mediaType: 'imageOrVideo',
            answer: P.when(answer => isEmptyFileList(answer) || !isDefined(answer)),
          },
          () => t('submitBtnImageOrVideo')
        )
        .with(
          {
            answer: P.when(answer => isDefined(answer)),
          },
          () => t('submit')
        )
        .exhaustive();
    },
    [config.mediaType, answer, t]
  );

  useEffect(() => {
    handleAnswerChange(answer);
  }, [answer, handleAnswerChange]);

  const isAnswerDefined = isDefined(answer) && !isEmptyFileList(answer);

  const FileInputWithPreviewMemo = useMemo(
    () => (
      <FileInputWithPreview
        control={control}
        register={register}
        assetKind={assetKind}
        hasMedia={hasMedia}
        isAnswerDefined={isAnswerDefined}
        error={error}
      />
    ),
    [control, register, assetKind, hasMedia, isAnswerDefined, error]
  );

  const SocialLayerSectionMemo = useMemo(
    () => (
      <SocialLayerConsent register={register} hasDarkBg={hasDarkBg} selectedFile={selectedFile} />
    ),
    [selectedFile, register, hasDarkBg]
  );

  return (
    <form
      id="media-form"
      className="md:min-w-[350px]"
      onSubmit={handleSubmit(async data => {
        if (data.answer && isFile(data.answer)) {
          // if the file gets moderated, the upload will fail
          const asset = await uploadFile({
            file: data.answer,
            assetKind: assetKind,
          }).catch(() => null);

          if (asset) {
            onTriggerIntegrationUsageSuccess({
              answer: asset.fileKey,
              socialLayerConsent: data.socialLayerConsent,
            });
          }
        }
      })}
    >
      {FileInputWithPreviewMemo}
      {SocialLayerSectionMemo}

      <EmbeddedTriggerCta>
        {/* @Devs - When the step has a custom media, we hide the fileInput above and the CTA becomes the FileInput. */}
        {hasMedia && (!isDefined(answer) || isEmptyFileList(answer)) ? (
          <MediaUploadButton
            control={control}
            register={register}
            assetKind={assetKind}
            errors={errors}
            configBtnText={configBtnText}
          />
        ) : (
          <ProgressButton
            form="media-form"
            tracking={{
              namespace: `triggerIntegrations.media-form.submit`,
            }}
            variant="primary"
            className="w-full"
            type="submit"
            progress={uploadProgress}
            loading={isUploading || isVerifying}
            disabled={!isValid}
          >
            {configBtnText()}
          </ProgressButton>
        )}
      </EmbeddedTriggerCta>
    </form>
  );
};

export default CohortFormMediaUsageComponent;
