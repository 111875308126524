import type {PerkIntegrationSpec, PerkIntegrationStruct} from '@cohort/shared/apps/app';
import type {CohortFormConfig} from '@cohort/shared/schema/common/cohortForm';
import {
  CohortFormAnswerSchema,
  CohortFormConfigSchema,
  CohortFormPromptTypeSchema,
} from '@cohort/shared/schema/common/cohortForm';
import {z} from 'zod';

export const CohortFormStateSchema = z.object({});
export type CohortFormState = z.infer<typeof CohortFormStateSchema>;

export const CohortFormAccessDataSchema = z.object({});
export type CohortFormAccessData = z.infer<typeof CohortFormAccessDataSchema>;

export const CohortFormUsageDataSchema = z.object({
  values: z.record(z.string(), CohortFormAnswerSchema),
});
export type CohortFormUsageData = z.infer<typeof CohortFormUsageDataSchema>;

export const ExposedCohortFormUsageAnswerDataSchema = z.object({
  promptId: z.string().describe('The ID of the prompt'),
  promptType: CohortFormPromptTypeSchema.describe('The type of the prompt'),
  referenceId: z.string().nullable().describe('The reference ID of the prompt, if any'),
  answer: CohortFormAnswerSchema.describe(
    'The answer to the prompt Checkboxes will be represented as booleans, and other fields as strings. Optional fields that were not filled by the user will be represented as null.'
  ),
});
export type ExposedCohortFormUsageAnswerData = z.infer<
  typeof ExposedCohortFormUsageAnswerDataSchema
>;

export const ExposedCohortFormUsageDataSchema = z
  .object({
    answers: z
      .record(ExposedCohortFormUsageAnswerDataSchema)
      .describe('A dictionnary containing all the answers filled by the user.'),
  })
  .describe('The data for the `form` perk App');
export type ExposedCohortFormUsageData = z.infer<typeof ExposedCohortFormUsageDataSchema>;

const ExposedCohortFormUsageDataForExportSchema = z.array(CohortFormAnswerSchema);
export type ExposedCohortFormUsageDataForExport = z.infer<
  typeof ExposedCohortFormUsageDataForExportSchema
>;

export type CohortFormPerkIntegrationStruct = PerkIntegrationStruct<
  'cohort-form.fill-form',
  CohortFormConfig,
  CohortFormState,
  CohortFormAccessData,
  CohortFormUsageData,
  CohortFormUsageData,
  ExposedCohortFormUsageData,
  ExposedCohortFormUsageDataForExport
>;

export const CohortFormPerkIntegrationSpec: PerkIntegrationSpec<CohortFormPerkIntegrationStruct> = {
  id: 'cohort-form.fill-form' as const,
  configSchema: CohortFormConfigSchema,
  stateSchema: CohortFormStateSchema,
  accessDataSchema: CohortFormAccessDataSchema,
  usageInputDataSchema: CohortFormUsageDataSchema,
  internalUsageDataSchema: CohortFormUsageDataSchema,
  exposedUsageDataSchema: ExposedCohortFormUsageDataSchema,
  exposedUsageDataForExportSchema: ExposedCohortFormUsageDataForExportSchema,
  defaultMaxUsagesPerToken: 1,
  requiresMerchantConnection: false,
};
