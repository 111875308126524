import FacebookTagInPostTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/facebook/triggers/tagInPost/InstructionsComponent';
import type {FacebookTagInPostUnverifiedTriggerStruct} from '@cohort/shared/apps/facebook/triggers/tagInPostUnverified';
import {FacebookTagInPostUnverifiedTriggerSpec} from '@cohort/shared/apps/facebook/triggers/tagInPostUnverified';
import FacebookTagInPostUnverifiedTriggerActionCtaComponent from '@cohort/wallet/apps/facebook/triggers/tagInPostUnverified/ActionCtaComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const FacebookTagInPostUnverifiedTriggerIntegration: TriggerIntegration<FacebookTagInPostUnverifiedTriggerStruct> =
  {
    type: 'action',
    spec: FacebookTagInPostUnverifiedTriggerSpec,
    actionCtaComponent: FacebookTagInPostUnverifiedTriggerActionCtaComponent,
    instructionsComponent: FacebookTagInPostTriggerIntegrationInstructionsComponent,
  };
