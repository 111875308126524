import type {MediaSpec, MediaStruct} from '@cohort/shared/apps/media';
import {z} from 'zod';

const TikTokPostMediaConfigSchema = z.object({
  videoId: z.string(),
  imageFileKey: z.string().nullable(),
});
export type TikTokPostMediaConfig = z.infer<typeof TikTokPostMediaConfigSchema>;

export type TikTokPostMediaStruct = MediaStruct<'tiktok.post', TikTokPostMediaConfig>;
export const TikTokPostMediaSpec: MediaSpec<TikTokPostMediaStruct> = {
  kind: 'tiktok.post',
  configSchema: TikTokPostMediaConfigSchema,
};
