import type {WebappLanguage} from '@cohort/shared/schema/common';
import type {WalletAssetKind} from '@cohort/shared/schema/common/assets';
import {
  WALLET_ASSET_VIDEO_THUMBNAIL_MEDIA_KIND,
  WALLET_ASSET_VIDEO_THUMBNAIL_MIME_TYPE,
} from '@cohort/shared/schema/common/assets';
import {formatI18nLanguage} from '@cohort/shared/utils/localization';
import {getSignedUrl, processUploadedFile} from '@cohort/wallet/lib/Endpoints';
import type {AssetWDto, BaseSignedUrlWDto} from '@cohort/wallet-schemas/asset';
import type {OwnershipWDto} from '@cohort/wallet-schemas/ownership';
import type {PerkAccessWDto} from '@cohort/wallet-schemas/perkAccess';
import axios from 'axios';
import dayjs from 'dayjs';
import i18n from 'i18next';

export type SectionName = 'contents' | 'description' | 'rewards' | 'faq';
export type SigninMethod = 'google' | 'email' | 'auth-token';
export interface AccessesAndOwnership {
  access: PerkAccessWDto;
  ownership: OwnershipWDto;
}

export const GRAY_500 = '#6b7280';
export const STYLE_TITLE_CARD = 'text-base font-medium';
export const STYLE_SUBTITLE_CARD = 'text-sm font-normal';

export const SCROLL_SPY_CONTAINER_ID = 'scroll-container';

export async function poll<T extends unknown[], R>(
  fn: (...args: T) => Promise<R>,
  interval: number,
  maxTries: number,
  ...args: T
): Promise<R> {
  return new Promise((resolve, reject) => {
    let tries = 0;
    const intervalId = setInterval(async () => {
      try {
        const result = await fn(...args);
        clearInterval(intervalId);
        resolve(result);
      } catch (err: unknown) {
        tries++;
        if (tries >= maxTries) {
          clearInterval(intervalId);
          reject(err);
        }
      }
    }, interval);
  });
}

export function languageToLocale(language: string): WebappLanguage {
  switch (language) {
    case 'fr-FR':
    case 'fr':
      return 'fr';
    case 'en-EN':
    case 'en-US':
    case 'en-GB':
    case 'en':
      return 'en';
    default:
      return 'en';
  }
}

export const perkObtentionDate = (ownership: OwnershipWDto, canBeUsed: boolean): string => {
  const {t} = i18n;
  const perkStatusText = canBeUsed ? t('ordered', {ns: 'utils'}) : t('used', {ns: 'utils'});

  return `${perkStatusText} ${dayjs(ownership.createdAt).fromNow()}`;
};

const pad = (num: number): string => num.toString().padStart(2, '0');

/**
 * Formats a number of seconds into a human readable time.
 * formatDuration(5000) => 01h 23m 20s
 */
export const formatDuration = (seconds: number): string => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  let time = `${pad(s)}s`;
  if (m > 0 || h > 0) {
    time = `${pad(m)}m ${time}`;
  }
  if (h > 0) {
    time = `${pad(h)}h ${time}`;
  }
  return time;
};

export const toUserDate = (date: Date): string => {
  return [
    date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    }),
  ].join(', ');
};

export function assert<T>(value: unknown): value is T {
  if (value === undefined) {
    throw new Error('Assertion failed');
  }
  return true;
}

export function getCohortTosUrl(): string {
  const currentLanguage = formatI18nLanguage(i18n.language);

  if (currentLanguage === 'fr') {
    return 'https://storage.googleapis.com/cohort-prod-347206.appspot.com/terms-fr.pdf';
  }
  return 'https://getcohort.com/terms-of-service';
}

export function getCohortPrivacyUrl(): string {
  return 'https://getcohort.com/privacy-policy';
}

export const isOnSpace = (): boolean => {
  const rootPath = window.location.pathname.split('/')[1];
  const isSpaceRoute = rootPath === 'space';
  return isSpaceRoute;
};

async function prepareVideoUpload(
  file: File,
  thumbnails: BaseSignedUrlWDto[],
  onProgress?: (progress: number) => void
): Promise<void> {
  const THUMBNAIL_COUNT = thumbnails.length;
  const PROGRESS_PER_THUMBNAIL = 20 / THUMBNAIL_COUNT;

  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  video.src = URL.createObjectURL(file);

  await new Promise((resolve, reject) => {
    video.onloadedmetadata = resolve;
    video.onerror = reject;
  });

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  // Generate evenly spaced thumbnails across video duration
  for (const [i, thumbnail] of thumbnails.entries()) {
    const currentTime = (video.duration * i) / (THUMBNAIL_COUNT - 1);
    video.currentTime = currentTime;

    await new Promise(resolve => {
      video.onseeked = resolve;
    });

    ctx?.drawImage(video, 0, 0);

    const blob = await new Promise<Blob>(resolve => {
      canvas.toBlob(blob => resolve(blob as Blob), WALLET_ASSET_VIDEO_THUMBNAIL_MIME_TYPE);
    });

    const thumbnailFile = new File([blob], '', {type: WALLET_ASSET_VIDEO_THUMBNAIL_MIME_TYPE});

    await axios.put(thumbnail.signedUrl, thumbnailFile, {
      headers: {
        'content-type': 'application/octet-stream',
        'x-goog-content-length-range': `0,${thumbnail.maxFileSize}`,
      },
    });

    await processUploadedFile(thumbnail.fileKey, WALLET_ASSET_VIDEO_THUMBNAIL_MEDIA_KIND);

    if (onProgress) {
      onProgress(PROGRESS_PER_THUMBNAIL * (i + 1));
    }
  }

  URL.revokeObjectURL(video.src);
}

export async function uploadAsset(
  file: File,
  assetKind: WalletAssetKind,
  featureVideoModerationEnabled: boolean,
  onProgress?: (progress: number) => void
): Promise<AssetWDto> {
  const {
    signedUrl,
    fileKey: baseFileKey,
    maxFileSize,
    thumbnails,
  } = await getSignedUrl(assetKind, file.type);

  const isVideo = file.type.startsWith('video/');
  if (isVideo && featureVideoModerationEnabled) {
    await prepareVideoUpload(file, thumbnails, onProgress);
  }

  await axios.put(signedUrl, file, {
    headers: {
      'content-type': 'application/octet-stream',
      'x-goog-content-length-range': `0,${maxFileSize}`,
    },
    onUploadProgress: (progressEvent: ProgressEvent) => {
      // For videos, scale from 20-90%
      // For images, scale from 0-90%
      const progressBase = isVideo && featureVideoModerationEnabled ? 20 : 0;
      const progressScale = isVideo ? 70 : 90;

      const percentCompleted =
        progressBase + Math.round((progressEvent.loaded * progressScale) / progressEvent.total);

      if (onProgress !== undefined) {
        onProgress(percentCompleted);
      }
    },
  });

  const {fileKey} = await processUploadedFile(baseFileKey, assetKind);

  // Set progress to 100% after processing complete
  if (onProgress !== undefined) {
    onProgress(100);
  }

  return {fileKey, mimeType: file.type};
}
