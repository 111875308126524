import {GoogleFitActivityGroupsSchema} from '@cohort/shared/apps/google-fit/activity';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {startDateBeforeEndDateRefinement} from '@cohort/shared/schema/common/dates';
import {z} from 'zod';

export const GoogleFitBeActiveTriggerConfigMetricSchema = z.enum([
  'active minutes',
  'distance',
  'heart points',
  'steps',
]);

export const GoogleFitBeActiveTriggerConfigSchema = z
  .object({
    activityGroups: z.array(GoogleFitActivityGroupsSchema).nonempty().nullable(),
    metric: GoogleFitBeActiveTriggerConfigMetricSchema,
    endDateTimestamp: z.number(),
    startDateTimestamp: z.number(),
    value: z.number().min(0),
  })
  .superRefine(
    startDateBeforeEndDateRefinement({
      startDatePropertyName: 'startDateTimestamp',
      endDatePropertyName: 'endDateTimestamp',
    })
  );

export type GoogleFitBeActiveTriggerConfig = z.infer<typeof GoogleFitBeActiveTriggerConfigSchema>;

export const GoogleFitBeActiveVerificationAttemptDataSchema = z
  .object({
    metric: GoogleFitBeActiveTriggerConfigMetricSchema,
    cumulativeValue: z.number().min(0).optional(),
    thresholdValue: z.number().min(0),
    googleUserId: z.string().nullable(),
    googleUsername: z.string().nullable(),
  })
  .nullable();

export type GoogleFitBeActiveVerificationAttemptData = z.infer<
  typeof GoogleFitBeActiveVerificationAttemptDataSchema
>;

export type GoogleFitBeActiveTriggerStruct = TriggerIntegrationStruct<
  'google-fit.be-active',
  GoogleFitBeActiveTriggerConfig,
  GoogleFitBeActiveVerificationAttemptData
>;

export const GoogleFitBeActiveTriggerIntegrationSpec: TriggerIntegrationSpec<GoogleFitBeActiveTriggerStruct> =
  {
    id: 'google-fit.be-active' as const,
    configurableMaxAttempts: false,
    configSchema: GoogleFitBeActiveTriggerConfigSchema,
    verificationAttemptDataSchema: GoogleFitBeActiveVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
    deprecated: true,
  };
