import InstagramFollowTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/instagram/triggerIntegrations/follow/InstructionsComponent';
import type {InstagramFollowTriggerStruct} from '@cohort/shared/apps/instagram/triggers/follow';
import {InstagramFollowTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/follow';
import InstagramFollowTriggerActionCtaComponent from '@cohort/wallet/apps/instagram/triggerIntegrations/follow/ActionCtaComponent';
import InstagramFollowTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/instagram/triggerIntegrations/follow/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const InstagramFollowTriggerIntegration: TriggerIntegration<InstagramFollowTriggerStruct> = {
  type: 'action',
  spec: InstagramFollowTriggerIntegrationSpec,
  actionCtaComponent: InstagramFollowTriggerActionCtaComponent,
  detailsComponent: InstagramFollowTriggerIntegrationDetailsComponent,
  instructionsComponent: InstagramFollowTriggerIntegrationInstructionsComponent,
};
