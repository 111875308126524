import {Card} from '@cohort/components-xps/components/cards/Card';
import Title from '@cohort/components-xps/components/Title';
import type {
  CohortSwipeCardsTriggerConfig,
  CohortSwipeCardsVerificationAttemptData,
} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import Button from '@cohort/wallet/components/button/Button';
import {EmbeddedTriggerCta} from '@cohort/wallet/components/challenges/NextStepCta';
import useGetLocalizedString from '@cohort/wallet/hooks/getLocalizedString';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {CheckCircle} from '@phosphor-icons/react';
import type {PanInfo} from 'motion/react';
import {
  animate,
  AnimatePresence,
  motion,
  useMotionValue,
  useMotionValueEvent,
  useTransform,
} from 'motion/react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const SWIPE_THRESHOLD = 100;
const MAX_PREVIEW_NUMBER = 3;
const AUTO_SUBMIT_DELAY = 1500;

type TinderSwipeProps = {
  leftBtnLabel: string;
  rightBtnLabel: string;
  cards: CohortSwipeCardsTriggerConfig['cards'];
  onSuccess?: (answers: CohortSwipeCardsVerificationAttemptData['answers']) => void;
  isVerifying: boolean;
};

const TinderSwipe: React.FC<TinderSwipeProps> = ({
  leftBtnLabel,
  rightBtnLabel,
  cards,
  onSuccess,
  isVerifying,
}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.swipeCards.tinderSwipe',
  });
  const [currentCardIdx, setCurrentCardIdx] = useState(0);
  const [activeButton, setActiveButton] = useState<'left' | 'right' | null>(null);
  const {hasDarkBg} = useThemeContext();
  const getLocalizedString = useGetLocalizedString();
  const [answers, setAnswers] = useState<CohortSwipeCardsVerificationAttemptData['answers']>({});

  const x = useMotionValue(0);
  const rotate = useTransform(x, [-SWIPE_THRESHOLD, SWIPE_THRESHOLD], [-25, 25]);
  const isFinished = currentCardIdx >= cards.length;

  useEffect(() => {
    if (isFinished && onSuccess) {
      const timer = setTimeout(() => {
        onSuccess(answers);
      }, AUTO_SUBMIT_DELAY);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [isFinished, answers, onSuccess]);

  useMotionValueEvent(x, 'change', latest => {
    if (Math.abs(latest) >= SWIPE_THRESHOLD) {
      setActiveButton(latest > 0 ? 'right' : 'left');
    } else {
      setActiveButton(null);
    }
  });

  const visibleCount = Math.min(MAX_PREVIEW_NUMBER, cards.length - currentCardIdx);

  async function handleSwipe(direction: 'left' | 'right'): Promise<void> {
    const directionValue = direction === 'left' ? -1 : 1;
    const card = cards[currentCardIdx];

    if (!card) {
      return;
    }
    const currentAnswers = {
      ...answers,
      [card.id]: {
        cardId: card.id,
        cardTitle: card.title ? getLocalizedString(card.title) : undefined,
        cardImageFileKey: card.imageFileKey,
        answer: direction === 'left' ? leftBtnLabel : rightBtnLabel,
      },
    };

    // Animate the card off screen first
    await animate(x, directionValue * 300, {
      duration: 0.2,
    });

    // Reset position and rotation immediately (no animation)
    x.set(0, false);
    rotate.set(0, false);

    setAnswers(currentAnswers);
    setCurrentCardIdx(currentCardIdx + 1);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (navigator.vibrate) {
      navigator.vibrate(300);
    }
  }

  return (
    <div className="flex flex-col items-center justify-between">
      <AnimatePresence mode="wait">
        {!isFinished ? (
          <ul className="mt-16 grid list-none pb-8 [&>*]:[grid-column:1] [&>*]:[grid-row:1]">
            {cards
              .slice(currentCardIdx, currentCardIdx + MAX_PREVIEW_NUMBER)
              .reverse()
              .map((card, idx) => (
                <motion.li
                  key={card.id}
                  className={cn('size-[300px] rounded-3xl')}
                  style={{
                    boxShadow: 'var(--xps-card-shadow)',
                    ...(idx === visibleCount - 1 && {
                      x,
                      rotate,
                    }),
                  }}
                  animate={{
                    y: (visibleCount - 1 - idx) * -30,
                    scale: 1 - (visibleCount - 1 - idx) * 0.1,
                  }}
                  transition={{
                    duration: 0.3,
                    ease: 'easeOut',
                    type: 'tween',
                  }}
                  dragMomentum={false}
                  drag="x"
                  dragConstraints={{left: -100, right: 100}}
                  dragElastic={0.7}
                  whileDrag={{cursor: 'grabbing'}}
                  dragDirectionLock
                  dragSnapToOrigin
                  onDragEnd={async (_, info: PanInfo) => {
                    if (Math.abs(info.offset.x) >= SWIPE_THRESHOLD) {
                      await handleSwipe(info.offset.x > 0 ? 'right' : 'left');
                    }
                    setActiveButton(null);
                  }}
                >
                  <Card
                    className={cn(
                      'flex h-full flex-col items-center justify-center gap-2',
                      hasDarkBg ? 'bg-[#424242]' : 'bg-[#F5F5F3]'
                    )}
                  >
                    <div className="aspect-square w-full overflow-hidden">
                      <img
                        src={getImageUrl(import.meta.env.COHORT_ENV, card.imageFileKey, {
                          h: Sizes.S,
                          w: Sizes.S,
                        })}
                        className="h-full w-full object-cover"
                        alt="Tinder swipe"
                      />
                    </div>
                    {card.title && (
                      <p className="font-semibold">{getLocalizedString(card.title)}</p>
                    )}
                  </Card>
                </motion.li>
              ))}
          </ul>
        ) : (
          <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5, ease: 'easeOut'}}
            className="flex h-full min-h-[450px] flex-grow flex-col items-center justify-center gap-4"
          >
            <CheckCircle size={32} className="text-[--xps-accent-color]" />
            <Title className="italic opacity-80">{t('allGood')}</Title>
          </motion.div>
        )}
      </AnimatePresence>
      {!isFinished ? (
        <EmbeddedTriggerCta>
          <div className="flex w-full justify-center gap-2">
            <Button
              variant="secondary"
              className={cn(
                'w-full transition-colors active:bg-[--xps-primary-btn-background-color]',
                activeButton === 'left' && 'bg-[--xps-primary-btn-background-color]'
              )}
              tracking={{namespace: 'modals.apps.cohort-form.tinder.ko'}}
              onClick={() => handleSwipe('left')}
            >
              {leftBtnLabel}
            </Button>
            <Button
              variant="secondary"
              className={cn(
                'w-full transition-colors active:bg-[--xps-primary-btn-background-color]',
                activeButton === 'right' && 'bg-[--xps-primary-btn-background-color]'
              )}
              tracking={{namespace: 'modals.apps.cohort-form.tinder.ok'}}
              onClick={() => handleSwipe('right')}
            >
              {rightBtnLabel}
            </Button>
          </div>
        </EmbeddedTriggerCta>
      ) : (
        <EmbeddedTriggerCta>
          <Button
            tracking={{namespace: 'modals.apps.cohort-form.tinder.close'}}
            className="w-full"
            onClick={() => onSuccess?.(answers)}
            loading={true}
          />
        </EmbeddedTriggerCta>
      )}
    </div>
  );
};

export default TinderSwipe;
