import FacebookEmbed from '@cohort/components-xps/components/apps/facebook/FacebookEmbedPost';
import type {FacebookLikePageTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePage';
import type {FacebookLikePageUnverifiedTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePageUnverified';
import serializeDataAttributes from '@cohort/shared-frontend/utils/serializeDataAtttributes';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';

const FacebookLikePageTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<FacebookLikePageTriggerStruct | FacebookLikePageUnverifiedTriggerStruct>
> = ({config, stepId, triggerId}) => {
  const {isMobile} = useScreenSize();

  return (
    <div
      className="flex w-full items-center justify-center"
      {...serializeDataAttributes({
        'step-id': stepId,
        'trigger-id': triggerId,
      })}
    >
      <FacebookEmbed
        type="page"
        url={`https://www.facebook.com/${config.pageId}`}
        isMobile={isMobile}
      />
    </div>
  );
};

export default FacebookLikePageTriggerIntegrationDetailsComponent;
