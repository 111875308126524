import {TriggerReferenceIdSchema} from '@cohort/shared/apps/cohort-form/triggers/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const VerificationAttemptInputSchema = z.object({
  answer: z.number(),
});
type VerificationAttemptInput = z.infer<typeof VerificationAttemptInputSchema>;

const VerificationAttemptDataSchema = z.object({
  answer: z.number(),
});
type VerificationAttemptData = z.infer<typeof VerificationAttemptDataSchema>;

const ConfigSchema = z.object({
  referenceId: TriggerReferenceIdSchema,
  userPropertyId: z.string().uuid().nullable(),
});
type Config = z.infer<typeof ConfigSchema>;

const SocialLayerDataSchema = z.object({
  1: z.number(),
  2: z.number(),
  3: z.number(),
  4: z.number(),
  5: z.number(),
});
type SocialLayerData = z.infer<typeof SocialLayerDataSchema>;

export type CohortFormScoreTriggerStruct = TriggerIntegrationStruct<
  'cohort-form.score',
  Config,
  VerificationAttemptData,
  VerificationAttemptInput,
  SocialLayerData
>;

export const CohortFormScoreTriggerIntegrationSpec: TriggerIntegrationSpec<CohortFormScoreTriggerStruct> =
  {
    id: 'cohort-form.score' as const,
    configurableMaxAttempts: false,
    configSchema: ConfigSchema,
    verificationAttemptDataSchema: VerificationAttemptDataSchema,
    verificationAttemptInputSchema: VerificationAttemptInputSchema,
    socialLayerDataSchema: SocialLayerDataSchema,
    requiresMerchantConnection: false,
    useCases: ['customer-knowledge'],
  };
