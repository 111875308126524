import TwitterEmbedTimeline from '@cohort/components-xps/components/apps/twitter/TwitterEmbedTimeline';
import type {TwitterFollowTriggerStruct} from '@cohort/shared/apps/twitter/triggers/follow';
import serializeDataAttributes from '@cohort/shared-frontend/utils/serializeDataAtttributes';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';

const TwitterFollowTriggerDetailsComponent: React.FC<
  DetailsComponentProps<TwitterFollowTriggerStruct>
> = ({config, stepId, triggerId}) => (
  <div
    className="w-full"
    {...serializeDataAttributes({
      'step-id': stepId,
      'trigger-id': triggerId,
    })}
  >
    <TwitterEmbedTimeline profileUrl={config.profileUrl} darkMode />
  </div>
);

export default TwitterFollowTriggerDetailsComponent;
