import {TriggerReferenceIdSchema} from '@cohort/shared/apps/cohort-form/triggers/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const VerificationAttemptInputSchema = z.object({
  answer: z.number(),
});
type VerificationAttemptInput = z.infer<typeof VerificationAttemptInputSchema>;

const VerificationAttemptDataSchema = z.object({
  answer: z.number(),
});
type VerificationAttemptData = z.infer<typeof VerificationAttemptDataSchema>;

const ConfigSchema = z.object({
  referenceId: TriggerReferenceIdSchema,
  userPropertyId: z.string().uuid().nullable(),
});
type Config = z.infer<typeof ConfigSchema>;

export type CohortFormNumberTriggerStruct = TriggerIntegrationStruct<
  'cohort-form.number',
  Config,
  VerificationAttemptData,
  VerificationAttemptInput
>;

export const CohortFormNumberTriggerIntegrationSpec: TriggerIntegrationSpec<CohortFormNumberTriggerStruct> =
  {
    id: 'cohort-form.number' as const,
    configurableMaxAttempts: false,
    configSchema: ConfigSchema,
    verificationAttemptDataSchema: VerificationAttemptDataSchema,
    verificationAttemptInputSchema: VerificationAttemptInputSchema,
    requiresMerchantConnection: false,
  };
