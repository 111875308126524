import TiktokRepostTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/tiktok/triggers/repostTiktok/InstructionsComponent';
import type {TikTokRepostTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/repostTiktok';
import {TikTokRepostTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/repostTiktok';
import TikTokRepostTiktokTriggerActionCtaComponent from '@cohort/wallet/apps/tiktok/triggers/repostTiktok/ActionCtaComponent';
import TiktokRepostTiktokTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/tiktok/triggers/repostTiktok/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const TikTokRepostTiktokTriggerIntegration: TriggerIntegration<TikTokRepostTiktokTriggerStruct> =
  {
    type: 'action',
    spec: TikTokRepostTiktokTriggerSpec,
    detailsComponent: TiktokRepostTiktokTriggerIntegrationDetailsComponent,
    actionCtaComponent: TikTokRepostTiktokTriggerActionCtaComponent,
    instructionsComponent: TiktokRepostTriggerIntegrationInstructionsComponent,
  };
