import InstagramEmbedPost from '@cohort/components-xps/components/apps/instagram/InstagramEmbedPost';
import type {InstagramFollowTriggerStruct} from '@cohort/shared/apps/instagram/triggers/follow';
import serializeDataAttributes from '@cohort/shared-frontend/utils/serializeDataAtttributes';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';

const InstagramFollowTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<InstagramFollowTriggerStruct>
> = ({config, stepId, triggerId}) => (
  <div
    className="flex w-full items-center justify-center"
    {...serializeDataAttributes({
      'step-id': stepId,
      'trigger-id': triggerId,
    })}
  >
    <InstagramEmbedPost postUrl={config.url} />
  </div>
);

export default InstagramFollowTriggerIntegrationDetailsComponent;
