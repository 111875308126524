import {
  contentVisualPreviewEmptyImageElement,
  contentVisualPreviewImageElement,
} from '@cohort/components-xps/components/contents/apps/utils';
import {Link} from '@phosphor-icons/react';

type CohortImageVisualPreviewContentProps = {
  imageSrc: string | null;
  title: string;
};

const CohortLinkVisualPreviewContent: React.FC<CohortImageVisualPreviewContentProps> = ({
  imageSrc,
  title,
}) => {
  return imageSrc
    ? contentVisualPreviewImageElement(title, imageSrc)
    : contentVisualPreviewEmptyImageElement(<Link />);
};

export default CohortLinkVisualPreviewContent;
