import {EmailSchema} from '@cohort/shared/schema/common';
import type {WalletAssetKind} from '@cohort/shared/schema/common/assets';
import type {
  CohortFormAnswer,
  CohortFormPrompt,
  MediaCohortFormPrompt,
} from '@cohort/shared/schema/common/cohortForm';
import {MediaCohortFormPromptSchema} from '@cohort/shared/schema/common/cohortForm';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {getAcceptedAssetKindsForMediaInput} from '@cohort/wallet/components/forms/fileInput/utils';

type SupportedErrors = 'required' | 'invalidEmail' | 'checkboxRequired' | 'uploadFailed';

export const validateCohortFormPrompt = async (
  prompt: CohortFormPrompt,
  data: Record<string, CohortFormAnswer>,
  uploadFile?: (args: {file: File; assetKind: WalletAssetKind}) => Promise<{fileKey: string} | null>
): Promise<{value: CohortFormAnswer; error?: SupportedErrors}> => {
  const answer = data[prompt.id];
  const emptyAnswer =
    answer === '' ||
    answer === undefined ||
    answer === null ||
    (Array.isArray(answer) && answer.length === 0);

  if (prompt.mandatory && emptyAnswer) {
    return {value: '', error: 'required'};
  }

  if (MediaCohortFormPromptSchema.safeParse(prompt).success) {
    const file = isFile(answer) ? answer : null;
    if (file && uploadFile) {
      const asset = await uploadFile({
        file: answer as unknown as File,
        assetKind: getAcceptedAssetKindsForMediaInput((prompt as MediaCohortFormPrompt).mediaType),
      });
      if (asset === null) {
        return {value: null, error: 'uploadFailed'};
      }
      return {value: asset.fileKey};
    }
  }

  if (prompt.type === 'email' && !emptyAnswer) {
    try {
      EmailSchema.parse(answer);
    } catch {
      return {value: answer, error: 'invalidEmail'};
    }
  }

  if (prompt.type === 'checkbox' && prompt.mandatory && emptyAnswer) {
    return {value: false, error: 'checkboxRequired'};
  }

  return {value: answer ?? null};
};
