import {TriggerIntegrationIdSchema} from '@cohort/shared/apps';
import {ChallengeStepMediaSchema} from '@cohort/shared/schema/common/challengeStep';
import {ConnectionWSchema} from '@cohort/wallet-schemas/connections';
import {z} from 'zod';

export const ChallengeStepTriggerWSchema = z.object({
  id: z.string().uuid(),
  triggerIntegrationId: TriggerIntegrationIdSchema,
  triggerIntegrationConfig: z.unknown(),
  connectionId: z.string().uuid().nullable(),
  connection: ConnectionWSchema.nullable(),
  socialLayerData: z.unknown().nullable(),
});
export type ChallengeStepTriggerWDto = z.infer<typeof ChallengeStepTriggerWSchema>;

export const ChallengeStepWSchema = z.object({
  id: z.string().uuid(),
  triggers: ChallengeStepTriggerWSchema.array(),
  title: z.string(),
  description: z.string().nullable(),
  icon: z.string().emoji().nullable(),
  isCompleted: z.boolean(),
  media: ChallengeStepMediaSchema.nullable(),
});
export type ChallengeStepWDto = z.infer<typeof ChallengeStepWSchema>;
