import type {CohortFormScoreTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/score';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import ProgressCard from '@cohort/wallet/apps/ProgressCard';
import type {TriggerIntegrationSocialLayerComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {Star} from '@phosphor-icons/react';
import React from 'react';

const SCORE_VALUES = [5, 4, 3, 2, 1];

const CohortFormScoreSocialLayerComponent: React.FC<
  TriggerIntegrationSocialLayerComponentProps<CohortFormScoreTriggerStruct>
> = ({socialLayerData, verificationAttemptInput}) => {
  return (
    <div className="w-full space-y-2">
      {SCORE_VALUES.map(score => {
        const percentage = socialLayerData[score as 1 | 2 | 3 | 4 | 5];
        const selected = score === verificationAttemptInput?.answer;

        return (
          <ProgressCard key={score} percentage={percentage} selected={selected}>
            <div className="absolute inset-0 flex items-center justify-end px-2">
              <h3>{Math.round(percentage)}%</h3>
            </div>
            <div className="flex">
              {Array.from({length: 5}).map((_, index) => (
                <Star
                  key={index}
                  className={cn(
                    'h-8 w-8',
                    index < score ? 'text-yellow-400' : 'text-[var(--xps-p-color)] opacity-30'
                  )}
                  weight={index < score ? 'fill' : 'duotone'}
                />
              ))}
            </div>
          </ProgressCard>
        );
      })}
    </div>
  );
};

export default CohortFormScoreSocialLayerComponent;
