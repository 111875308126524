import routes from '@cohort/wallet/router/Routes';
import {NuqsAdapter} from 'nuqs/adapters/react-router/v6';
import React from 'react';
import {RouterProvider} from 'react-router-dom';

const Router: React.FC = () => (
  <NuqsAdapter>
    <RouterProvider router={routes} />
  </NuqsAdapter>
);

export default Router;
