import InstagramTagInPostTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/instagram/triggerIntegrations/tagInPost/InstructionsComponent';
import type {InstagramTagInPostTriggerStruct} from '@cohort/shared/apps/instagram/triggers/tagInPost';
import {InstagramTagInPostTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/tagInPost';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const InstagramTagInPostTriggerIntegration: TriggerIntegration<InstagramTagInPostTriggerStruct> =
  {
    type: 'verification',
    spec: InstagramTagInPostTriggerIntegrationSpec,
    instructionsComponent: InstagramTagInPostTriggerIntegrationInstructionsComponent,
  };
