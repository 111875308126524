import CohortScanQrCodeTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/cohort/triggerIntegrations/scanQrCode/InstructionsComponent';
import type {CohortScanQrCodeTriggerStruct} from '@cohort/shared/apps/cohort/triggers/scanQrCode';
import {CohortScanQrCodeTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/scanQrCode';
import CohortScanQrCodeTriggerIntegrationActionCtaComponent from '@cohort/wallet/apps/cohort/triggerIntegrations/scanQrCode/ActionCtaComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortScanQrCodeTriggerIntegration: TriggerIntegration<CohortScanQrCodeTriggerStruct> =
  {
    type: 'action',
    spec: CohortScanQrCodeTriggerIntegrationSpec,
    disableExponentialBackoff: true,
    actionCtaComponent: CohortScanQrCodeTriggerIntegrationActionCtaComponent,
    instructionsComponent: CohortScanQrCodeTriggerIntegrationInstructionsComponent,
  };
