import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const DiscordRoleTriggerConfigSchema = z.object({
  roleId: z.string(),
  roleName: z.string(),
});
export const DiscordHasRoleTriggerConfigSchema = z.object({
  roles: z.array(DiscordRoleTriggerConfigSchema),
});
export type DiscordHasRoleTriggerConfig = z.infer<typeof DiscordHasRoleTriggerConfigSchema>;

export const DiscordHasRoleVerificationAttemptDataSchema = z
  .object({
    userRoleIds: z.array(z.string()),
    expectedRoles: z.array(DiscordRoleTriggerConfigSchema),
  })
  .nullable();
export type DiscordHasRoleVerificationAttemptData = z.infer<
  typeof DiscordHasRoleVerificationAttemptDataSchema
>;

export type DiscordHasRoleTriggerStruct = TriggerIntegrationStruct<
  'discord.has-role',
  DiscordHasRoleTriggerConfig,
  DiscordHasRoleVerificationAttemptData
>;
export const DiscordHasRoleTriggerIntegrationSpec: TriggerIntegrationSpec<DiscordHasRoleTriggerStruct> =
  {
    id: 'discord.has-role' as const,
    configurableMaxAttempts: false,
    configSchema: DiscordHasRoleTriggerConfigSchema,
    verificationAttemptDataSchema: DiscordHasRoleVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
    deprecated: true,
  };
