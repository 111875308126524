import type {CohortFormMediaTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/media';
import type {TriggerIntegrationSocialLayerComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import Button from '@cohort/wallet/components/button/Button';
import {DrawerModal} from '@cohort/wallet/components/modals/Modal';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

const MIN_MEDIA_COUNT = 3;

const CohortFormMediaCommunitySubmissions: React.FC<
  TriggerIntegrationSocialLayerComponentProps<CohortFormMediaTriggerStruct>
> = ({socialLayerData}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.media.communitySubmissions',
  });
  const [selectedMedia, setSelectedMedia] = useState<string | null>(null);

  if (socialLayerData.medias.length < MIN_MEDIA_COUNT) {
    return null;
  }

  return (
    <div className="w-full">
      <h3 className="font-semibold">{t('title')}</h3>
      <div className="flex gap-4 overflow-x-auto py-4">
        {socialLayerData.medias.map((media, index) => (
          <div
            key={index}
            className="h-40 w-40 flex-none cursor-pointer overflow-hidden rounded-lg"
            onClick={() => setSelectedMedia(media)}
          >
            <img src={media} className="h-full w-full object-cover object-top" />
          </div>
        ))}
      </div>

      {selectedMedia && (
        <DrawerModal
          tracking={{
            namespace: 'modals.triggerIntegrations.cohortForm.media.communitySubmission',
            metadata: {},
          }}
          onClose={() => setSelectedMedia(null)}
        >
          <div className="flex flex-col items-center gap-4 px-2">
            <img src={selectedMedia} className="max-h-[80vh] w-auto rounded-lg object-contain" />
            <Button
              className="w-full"
              variant="secondary"
              onClick={() => setSelectedMedia(null)}
              tracking={{
                namespace: 'modals.triggerIntegrations.cohortForm.media.communitySubmission.close',
                metadata: undefined,
              }}
            >
              {t('close')}
            </Button>
          </div>
        </DrawerModal>
      )}
    </div>
  );
};

export default CohortFormMediaCommunitySubmissions;
