import InstagramCommentMediaTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/instagram/triggerIntegrations/commentMedia/InstructionsComponent';
import type {InstagramCommentMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import {InstagramCommentMediaTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import InstagramCommentMediaTriggerActionCtaComponent from '@cohort/wallet/apps/instagram/triggerIntegrations/commentMedia/ActionCtaComponent';
import InstagramCommentMediaTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/instagram/triggerIntegrations/commentMedia/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const InstagramCommentMediaTriggerIntegration: TriggerIntegration<InstagramCommentMediaTriggerStruct> =
  {
    type: 'action',
    spec: InstagramCommentMediaTriggerIntegrationSpec,
    detailsComponent: InstagramCommentMediaTriggerIntegrationDetailsComponent,
    actionCtaComponent: InstagramCommentMediaTriggerActionCtaComponent,
    instructionsComponent: InstagramCommentMediaTriggerIntegrationInstructionsComponent,
  };
