import EmbedError from '@cohort/components-xps/components/apps/EmbedError';
import EmbedSkeleton from '@cohort/components-xps/components/apps/EmbedSkeleton';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {loadScript} from '@cohort/shared-frontend/utils/loadScript';
import {useEffect, useState} from 'react';
import {flushSync} from 'react-dom';
import {getI18n, useTranslation} from 'react-i18next';

type EmbedPostProps = {
  type: 'post' | 'page';
  url: string;
  isMobile?: boolean;
  width?: string;
};

declare global {
  interface Window {
    fbAsyncInit?: () => void;
    FB?: {
      XFBML?: {
        parse: () => void;
      };
    };
  }
}

const FacebookEmbed: React.FC<EmbedPostProps> = ({type, url, isMobile = false, width}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'apps.facebook.facebookEmbedPost',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    loadScript('https://connect.facebook.net/en_US/sdk.js#xfbml=true&version=v3.2')
      .then(() => {
        if (window.FB) {
          flushSync(() => setIsLoading(false));
          window.FB.XFBML?.parse();
        } else if (!window.fbAsyncInit) {
          window.fbAsyncInit = () => {
            // we need to flush sync here to ensure the fb-post is rendered before executing the parse
            flushSync(() => setIsLoading(false));
            window.FB?.XFBML?.parse();
          };
        }
      })
      .catch(() => setIsError(true));
  }, []);

  if (isLoading) {
    return <EmbedSkeleton width="100%" height="400px" />;
  }

  if (isError) {
    return <EmbedError error={t('facebookEmbedError')} />;
  }

  return (
    <div
      className={cn('bg-white', type === 'post' ? 'fb-post' : 'fb-page')}
      data-href={url}
      {...(type === 'page' && {
        'data-tabs': 'timeline',
      })}
      {...((!isMobile || width !== undefined) && {
        'data-width': width ?? '400px',
      })}
    ></div>
  );
};

export default FacebookEmbed;
