import type {CohortFormScoreTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/score';
import {CohortFormScoreTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/score';
import TriggerFormWrapper from '@cohort/wallet/apps/cohort-form/triggerIntegrations/TriggerFormWrapper';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import ScoreInput from '@cohort/wallet/components/forms/ScoreInput';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import type {z} from 'zod';

type CohortFormScore = z.infer<
  (typeof CohortFormScoreTriggerIntegrationSpec)['verificationAttemptInputSchema']
>;

const CohortFormScoreUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFormScoreTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, stepId}) => {
  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm<CohortFormScore>({
    resolver: zodResolver(CohortFormScoreTriggerIntegrationSpec.verificationAttemptInputSchema),
  });
  const error = errors.answer?.message;

  return (
    <TriggerFormWrapper
      stepId={stepId}
      formId="score-form"
      error={error}
      isValid={isValid}
      onSubmit={handleSubmit(data => onTriggerIntegrationUsageSuccess(data))}
    >
      <ScoreInput name="answer" control={control} className="justify-center" />
    </TriggerFormWrapper>
  );
};

export default CohortFormScoreUsageComponent;
