import type {MediaSpec, MediaStruct} from '@cohort/shared/apps/media';
import type {ResourceSpec, ResourceStruct} from '@cohort/shared/apps/resource';
import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {PositiveIntegerSchema} from '@cohort/shared/schema/common';
import type {UserPropertyValue} from '@cohort/shared/schema/common/userProperty';
import {z} from 'zod';

export type ConnectorType = 'oauth1' | 'oauth2' | 'custom';

export type AppCategory = 'health-fitness' | 'social-media';

export type ProfilePropertiesType = Record<string, UserPropertyValue | null>;

export const BaseProfilePropertiesSchema = z.object({
  id: z.string(),
});
export type BaseProfileProperties = ProfilePropertiesType &
  z.infer<typeof BaseProfilePropertiesSchema>;

export type ConnectorStruct<
  Id extends string = string,
  AppCredentials = unknown,
  Credentials = unknown,
  ProfileProperties extends BaseProfileProperties = BaseProfileProperties,
  ConnectionConfig = unknown,
> = {
  Id: Id;
  AppCredentials: AppCredentials;
  Credentials: Credentials;
  ProfileProperties: ProfileProperties;
  ConnectionConfig: ConnectionConfig;
};

export type ExternalUserProfile<C extends ConnectorStruct = ConnectorStruct> = {
  id: string;
  displayName: string;
  properties: C['ProfileProperties'];
};

export type AppStruct<
  Id extends string = string,
  MerchantConnector extends ConnectorStruct | null = ConnectorStruct | null,
  UserConnector extends ConnectorStruct | null = ConnectorStruct | null,
  SyncIntegration extends SyncIntegrationStruct | null = SyncIntegrationStruct | null,
  ActionIds extends string = string,
  NotificationIntegrationIds extends string = string,
  PerkIntegrationIds extends string = string,
  TriggerIntegrationIds extends string = string,
  EventIds extends string = string,
  UserPropertyIds extends string = string,
  ResourceTypes extends string = string,
  MediaTypes extends string = string,
> = {
  Id: Id;
  MerchantConnector: MerchantConnector;
  UserConnector: UserConnector;
  SyncIntegration: SyncIntegration;
  ActionIds: ActionIds;
  NotificationIntegrationIds: NotificationIntegrationIds;
  PerkIntegrationIds: PerkIntegrationIds;
  TriggerIntegrationIds: TriggerIntegrationIds;
  EventIds: EventIds;
  UserPropertyIds: UserPropertyIds;
  ResourceTypes: ResourceTypes;
  MediaTypes: MediaTypes;
};

export type ActionStruct<Id extends string = string, Input = unknown, Output = unknown> = {
  Id: Id;
  Input: Input;
  Output: Output;
};

export const AsyncActionRetryOptionsSchema = z.object({
  maxAttempts: PositiveIntegerSchema,
  delayMs: PositiveIntegerSchema,
});
export type AsyncActionRetryOptions = z.infer<typeof AsyncActionRetryOptionsSchema>;

export type ActionSpec<T extends ActionStruct = ActionStruct> = {
  readonly id: T['Id'];
  readonly inputSchema: z.ZodSchema<T['Input']>;
  readonly outputSchema: z.ZodSchema<T['Output']>;
};

export type PerkIntegrationStruct<
  Id extends string = string,
  Config = unknown,
  State = unknown,
  AccessData = unknown,
  UsageInputData = unknown,
  InternalUsageData = unknown,
  ExposedUsageData = InternalUsageData,
  ExposedUsageDataForExport = unknown,
> = {
  Id: Id;
  Config: Config;
  State: State;
  AccessData: AccessData;
  UsageInputData: UsageInputData;
  InternalUsageData: InternalUsageData;
  ExposedUsageData: ExposedUsageData;
  ExposedUsageDataForExport: ExposedUsageDataForExport;
};

export type PerkIntegrationSpec<T extends PerkIntegrationStruct = PerkIntegrationStruct> = {
  id: T['Id'];
  configSchema: z.ZodSchema<T['Config']>;
  stateSchema: z.ZodSchema<T['State']>;
  accessDataSchema: z.ZodSchema<T['AccessData']>;
  usageInputDataSchema: z.ZodSchema<T['UsageInputData']>;
  internalUsageDataSchema: z.ZodSchema<T['InternalUsageData']>;
  exposedUsageDataSchema: z.ZodSchema<T['ExposedUsageData']>;
  exposedUsageDataForExportSchema: z.ZodSchema<T['ExposedUsageDataForExport']>;
  defaultMaxUsagesPerToken: number | null;
  requiresMerchantConnection: boolean;
};

export type ConnectorSpec<T extends ConnectorStruct = ConnectorStruct> = {
  id: T['Id']; // This can be different from the app type, for example instagram merchant connection uses Facebook
  type: ConnectorType;
  credentialsSchema: z.ZodSchema<T['Credentials']>;
  appCredentialsSchema: z.ZodSchema<T['AppCredentials']> | null;
  profilePropertiesSchema: z.ZodSchema<T['ProfileProperties']>;
  connectionConfigSchema: z.ZodSchema<T['ConnectionConfig']> | null;
};

export type MerchantConnectorSpec<T extends AppStruct> = T['MerchantConnector'] extends null
  ? null
  : ConnectorSpec<NonNullable<T['MerchantConnector']>>;

export type UserConnectorSpec<T extends AppStruct> = T['UserConnector'] extends null
  ? null
  : ConnectorSpec<NonNullable<T['UserConnector']>>;

export type AppSpec<T extends AppStruct = AppStruct> = {
  readonly id: T['Id'];
  readonly name: string;
  readonly category?: AppCategory;
  readonly documentationUrl?: string;
  readonly merchantConnector: MerchantConnectorSpec<T>;
  readonly userConnector: UserConnectorSpec<T>;
  readonly syncSpec: T['SyncIntegration'] extends null
    ? null
    : SyncIntegrationSpec<NonNullable<T['SyncIntegration']>>;
  readonly actionSpecs: ReadonlyArray<ActionSpec<ActionStruct<T['ActionIds']>>>;
  readonly notificationIntegrationSpecs: ReadonlyArray<
    NotificationIntegrationSpec<NotificationIntegrationStruct<T['NotificationIntegrationIds']>>
  >;
  readonly perkIntegrationSpecs: ReadonlyArray<
    PerkIntegrationSpec<PerkIntegrationStruct<T['PerkIntegrationIds']>>
  >;
  readonly triggerIntegrationSpecs: ReadonlyArray<
    TriggerIntegrationSpec<TriggerIntegrationStruct<T['TriggerIntegrationIds']>>
  >;
  readonly userEventSpecs: ReadonlyArray<UserEventSpec<UserEventStruct<T['EventIds']>>>;
  readonly userPropertySpecs: ReadonlyArray<
    UserPropertySpec<UserPropertyStruct<T['UserPropertyIds']>>
  >;
  readonly resourceSpecs: ReadonlyArray<ResourceSpec<ResourceStruct<T['ResourceTypes']>>>;
  readonly mediaSpecs: ReadonlyArray<MediaSpec<MediaStruct<T['MediaTypes']>>>;
  readonly deprecated?: boolean;
};

export type NotificationIntegrationStruct<
  Id extends string = string,
  Config = unknown,
  NotificationData = unknown,
  NotificationInput = unknown,
  EventIds extends string = string,
  NotificationExecutionData = unknown,
> = {
  Id: Id;
  Config: Config;
  NotificationData: NotificationData;
  NotificationInput: NotificationInput;
  EventIds: EventIds;
  NotificationExecutionData: NotificationExecutionData;
};

export type NotificationIntegrationSpec<
  T extends NotificationIntegrationStruct = NotificationIntegrationStruct,
> = {
  id: T['Id'];
  configSchema: z.ZodSchema<T['Config']>;
  notificationDataSchema: z.ZodSchema<T['NotificationData']>;
  notificationInputSchema: z.ZodSchema<T['NotificationInput']>;
  notificationExecutionDataSchema: z.ZodSchema<T['NotificationExecutionData']>;
};

export type AppStructWithUserConnector<T extends AppStruct = AppStruct> = T & {
  readonly UserConnector: NonNullable<T['UserConnector']>;
};

export type AppStructWithMerchantConnector<T extends AppStruct = AppStruct> = T & {
  readonly MerchantConnector: NonNullable<T['MerchantConnector']>;
};

export type AppSpecWithUserConnector<
  T extends AppStructWithUserConnector = AppStructWithUserConnector,
> = Omit<AppSpec<T>, 'userConnector'> & {
  readonly userConnector: NonNullable<UserConnectorSpec<T>>;
};

export type AppSpecWithMerchantConnector<
  T extends AppStructWithMerchantConnector = AppStructWithMerchantConnector,
> = Omit<AppSpec<T>, 'merchantConnector'> & {
  readonly merchantConnector: NonNullable<MerchantConnectorSpec<T>>;
};
