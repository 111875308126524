import {
  contentVisualPreviewEmptyImageElement,
  contentVisualPreviewImageElement,
} from '@cohort/components-xps/components/contents/apps/utils';
import type {MediaSize} from '@cohort/shared/utils/media';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {Video} from '@phosphor-icons/react';

type CohortImageVisualPreviewContentProps = {
  thumbnailFileKey: string | null;
  imageSize: MediaSize;
  title: string;
};

const CohortVideoVisualPreviewContent: React.FC<CohortImageVisualPreviewContentProps> = ({
  thumbnailFileKey,
  imageSize,
  title,
}) => {
  const thumbnailSrc = thumbnailFileKey
    ? getImageUrl(import.meta.env.COHORT_ENV, thumbnailFileKey, {
        h: Sizes[imageSize],
        w: Sizes[imageSize],
      })
    : null;

  return thumbnailSrc
    ? contentVisualPreviewImageElement(title, thumbnailSrc)
    : contentVisualPreviewEmptyImageElement(<Video />);
};

export default CohortVideoVisualPreviewContent;
