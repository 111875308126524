// i18nOwl-ignore [cohortFormControls.checkboxRequired, cohortFormControls.invalidEmail, cohortFormControls.required, cohortFormControls.uploadFailed]
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselProgress,
  useCarousel,
} from '@cohort/components-xps/components/Carousel';
import i18n from '@cohort/components-xps/lib/i18n';
import type {WalletAssetKind} from '@cohort/shared/schema/common/assets';
import type {
  CohortFormAnswer,
  CohortFormConfig,
  CohortFormPrompt,
  SelectCohortFormPrompt,
} from '@cohort/shared/schema/common/cohortForm';
import {
  CohortFormAnswerSchema,
  MediaCohortFormPromptSchema,
  SelectCohortFormPromptSchema,
} from '@cohort/shared/schema/common/cohortForm';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {formatI18nLanguage} from '@cohort/shared/utils/localization';
import {buildLocalizationConfig} from '@cohort/shared/utils/localization';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import CohortFormPromptInput from '@cohort/wallet/apps/cohort-form/modalStepper/CohortFormPromptInput';
import {validateCohortFormPrompt} from '@cohort/wallet/apps/cohort-form/utils';
import Button from '@cohort/wallet/components/button/Button';
import ProgressButton from '@cohort/wallet/components/button/ProgressButton';
import {ModalHeader} from '@cohort/wallet/components/modals/Modal';
import {Transition} from '@cohort/wallet/components/transitions/Transition';
import type {FeatureFlags} from '@cohort/wallet/FeatureFlags';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import useNotify from '@cohort/wallet/hooks/notify';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import {uploadAsset} from '@cohort/wallet/lib/Utils';
import type {UserAttributeWDto} from '@cohort/wallet-schemas/userAttributes';
import {ArrowCircleLeft} from '@phosphor-icons/react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import React, {useCallback, useState} from 'react';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type CohortFormValues = Record<string, CohortFormAnswer>;

type CohortFormControlsProps = {
  isLoading: boolean;
  onClose: () => void;
  children: React.ReactNode;
  prompts: Array<CohortFormPrompt>;
  onSubmitTracking: TrackingConfig;
  onSubmitCallback: (values: CohortFormValues) => void;
};

const CohortFormControls: React.FC<CohortFormControlsProps> = ({
  prompts,
  onClose,
  children,
  isLoading,
  onSubmitTracking,
  onSubmitCallback,
}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'modalStepper.cohortFormStepper',
  });
  const {featureVideoModeration} = useFlags<FeatureFlags>();
  const [formErrors, setFormErrors] = useState<Record<string, string> | undefined>(undefined);
  const {backgroundColor} = useThemeContext();
  const notify = useNotify();
  const {accentColor} = useThemeContext();
  const {selectedIndex, scrollNext, scrollPrev, canScrollPrev, canScrollNext} = useCarousel();
  const [uploadProgress, setUploadProgress] = useState(0);
  const currentPrompt = prompts[selectedIndex];

  const {getValues, handleSubmit, setValue} = useFormContext();

  const handleUploadProgress = (
    progress: number,
    fileIndex: number,
    totalFilesCount: number
  ): void => {
    const progressPerFile = 100 / totalFilesCount;
    const baseProgress = fileIndex * progressPerFile;
    const currentProgress = (progress * progressPerFile) / 100;
    setUploadProgress(baseProgress + currentProgress);
  };

  const onUploadError = (error: Error | unknown, promptId: string): void => {
    if (isCohortError(error, 'media.forbidden-content')) {
      setValue(promptId, null);
      notify('error', t('errorFileUploadForbiddenContent'));
      return;
    }
    notify('error', t('errorFileUploadFailed'));
  };

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({
      file,
      assetKind,
      fileIndex,
      totalFilesCount,
    }: {
      promptId: string;
      file: File;
      assetKind: WalletAssetKind;
      fileIndex: number;
      totalFilesCount: number;
    }) =>
      uploadAsset(file, assetKind, featureVideoModeration, progress => {
        handleUploadProgress(progress, fileIndex, totalFilesCount);
      }),
    onError: (error: Error, {promptId}) => onUploadError(error, promptId),
  });

  const validateForm = async (data: CohortFormValues): Promise<void> => {
    const values: CohortFormValues = {};
    const submitErrors: Record<string, string> = {};

    const mediaPrompts = prompts.filter(
      prompt => MediaCohortFormPromptSchema.safeParse(prompt).success
    );
    const totalFilesCount = mediaPrompts.length;

    for (const prompt of prompts) {
      const {value, error} = await validateCohortFormPrompt(
        prompt,
        data,
        async ({file, assetKind}) => {
          try {
            return await uploadFile({
              promptId: prompt.id,
              file,
              assetKind,
              fileIndex: mediaPrompts.indexOf(prompt),
              totalFilesCount,
            });
          } catch {
            return null;
          }
        }
      );
      values[prompt.id] = value;
      if (error) {
        submitErrors[prompt.id] = error;
      }
    }

    if (Object.keys(submitErrors).length > 0) {
      setFormErrors(
        Object.fromEntries(
          Object.entries(submitErrors).map(([promptId, error]) => [
            promptId,
            t(`cohortFormControls.${error}`),
          ])
        )
      );
      return;
    }

    onSubmitCallback(values);
  };

  if (!currentPrompt) {
    throw new Error(`Never happens: no prompt found at index ${selectedIndex}.`);
  }

  const handleNextClick = async (): Promise<void> => {
    const {error} = await validateCohortFormPrompt(currentPrompt, getValues());
    if (error) {
      setFormErrors({[currentPrompt.id]: t(`cohortFormControls.${error}`)});
      return;
    }
    scrollNext();
  };

  const onPromptChangeTracking = (action: 'prevStep' | 'nextStep'): TrackingConfig => ({
    namespace: onSubmitTracking.namespace,
    metadata: {
      integrationId: 'cohort-form.fill-form',
      action: `formControls.${action}`,
    },
  });

  const renderError = useCallback(
    (promptId: string): JSX.Element | undefined => {
      if (formErrors === undefined || formErrors[promptId] === undefined) {
        return;
      }

      return (
        <Transition appear enterFrom="bottom" show>
          <p className="mb-3 text-center text-sm text-red-500">{formErrors[promptId]}</p>
        </Transition>
      );
    },
    [formErrors]
  );

  const error = renderError(currentPrompt.id);

  return (
    <>
      <div className="sticky top-0 z-50 pb-2" style={{backgroundColor}}>
        <ModalHeader onClose={onClose}>
          <div className="grid h-8 grid-cols-5">
            <div className="col-span-1 h-8">
              {canScrollPrev && (
                <Button
                  variant="link"
                  className="!p-0"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault();
                    scrollPrev();
                  }}
                  tracking={canScrollNext ? onPromptChangeTracking('prevStep') : onSubmitTracking}
                >
                  <ArrowCircleLeft size={32} weight="fill" />
                </Button>
              )}
            </div>
            <div className="col-span-4">
              {prompts.length > 1 && (
                <CarouselProgress
                  disableClick
                  color={accentColor}
                  variant="progressBar"
                  label={t('cohortFormControls.progressBarLabel', {
                    currentIndex: selectedIndex + 1,
                    totalIndex: prompts.length,
                  })}
                />
              )}
            </div>
          </div>
        </ModalHeader>
      </div>

      <div className="flex flex-grow flex-col overflow-y-auto">{children}</div>

      <div className="sticky bottom-0 z-50 border-none py-6" style={{backgroundColor}}>
        {error && error}
        <ProgressButton
          variant="primary"
          size="default"
          className="mt-4 w-full"
          type={canScrollNext ? 'button' : 'submit'}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            if (canScrollNext) {
              handleNextClick();
            } else {
              handleSubmit(validateForm)();
            }
          }}
          loading={isLoading || isUploading}
          progress={uploadProgress}
          tracking={canScrollNext ? onPromptChangeTracking('nextStep') : onSubmitTracking}
        >
          {canScrollNext ? t('cohortFormControls.next') : t('cohortFormControls.submit')}
        </ProgressButton>
      </div>
    </>
  );
};

type CohortFormPromptInputListProps = {
  prompts: Array<CohortFormPrompt>;
};

const CohortFormPromptInputList: React.FC<CohortFormPromptInputListProps> = ({prompts}) => {
  const merchant = useMerchantContext();
  const {selectedIndex} = useCarousel();
  const currentPrompt = prompts[selectedIndex];

  if (!currentPrompt) {
    throw new Error(`Never happens: no prompt found at index ${selectedIndex}.`);
  }

  const localizationConfig = buildLocalizationConfig(
    formatI18nLanguage(i18n.language),
    merchant.supportedLanguages,
    merchant.defaultLanguage
  );

  return prompts.map(prompt => (
    <CarouselItem
      key={prompt.id}
      // h-1 so that all the elements in the flex carousel that are NOT visible have a small height, without this,
      // the UI can be broken: if the next element is huge, the current element height will be huge too.
      className={cn(currentPrompt.id !== prompt.id && 'h-1 overflow-hidden')}
    >
      <CohortFormPromptInput prompt={prompt} localizationConfig={localizationConfig} />
    </CarouselItem>
  ));
};

type CohortFormPromptStepperProps = {
  isLoading: boolean;
  onClose: () => void;
  config: CohortFormConfig;
  onSubmitTracking: TrackingConfig;
  userAttributes: Array<UserAttributeWDto>;
  onSubmitCallback: (values: CohortFormValues) => void;
};

const CohortFormStepper: React.FC<CohortFormPromptStepperProps> = ({
  config,
  onClose,
  isLoading,
  userAttributes,
  onSubmitTracking,
  onSubmitCallback,
}) => {
  const {prompts} = config;

  // include user attributes in default values
  const defaultValues: CohortFormValues = {};
  for (const prompt of prompts) {
    const userAttributeValue = userAttributes.find(
      attribute => attribute.userPropertyId === prompt.userPropertyId
    )?.value;

    const answerFromUserAttribute = userAttributeValue
      ? CohortFormAnswerSchema.parse(userAttributeValue)
      : null;
    defaultValues[prompt.id] = answerFromUserAttribute ?? '';

    if (prompt.type === 'checkbox') {
      defaultValues[prompt.id] = false;
    }

    if (
      SelectCohortFormPromptSchema.safeParse(prompt).success &&
      (prompt as SelectCohortFormPrompt).multipleChoice
    ) {
      defaultValues[prompt.id] = [];
    }
  }

  return (
    <div className="flex h-full w-full grow flex-col gap-8">
      <FormProvider
        {...useForm({
          defaultValues,
        })}
      >
        <form className="flex grow flex-col">
          <Carousel isDraggable={false} className="flex grow flex-col">
            <CohortFormControls
              prompts={prompts}
              onClose={onClose}
              onSubmitTracking={onSubmitTracking}
              onSubmitCallback={onSubmitCallback}
              isLoading={isLoading}
            >
              <CarouselContent className="grow items-center">
                <CohortFormPromptInputList prompts={prompts} />
              </CarouselContent>
            </CohortFormControls>
          </Carousel>
        </form>
      </FormProvider>
    </div>
  );
};

export default CohortFormStepper;
