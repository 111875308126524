import type {PictureChoiceCohortFormPrompt} from '@cohort/shared/schema/common/cohortForm';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {RadioCardOption} from '@cohort/shared-frontend/utils/form';
import FieldWrapper from '@cohort/wallet/components/forms/FieldWrapper';
import RadioCardsInput from '@cohort/wallet/components/forms/RadioCards';
import useGetLocalizedString from '@cohort/wallet/hooks/getLocalizedString';
import type {Control, FieldValues, Path, UseFormRegister} from 'react-hook-form';

type ScoreInputProps<T extends FieldValues> = {
  name: Path<T>;
  config: {
    pictureChoiceOptions: PictureChoiceCohortFormPrompt['pictureChoiceOptions'];
    showLabels: PictureChoiceCohortFormPrompt['showLabels'];
    multipleChoice: PictureChoiceCohortFormPrompt['multipleChoice'];
    maxMultipleChoices: PictureChoiceCohortFormPrompt['maxMultipleChoices'];
  };
  control: Control<T>;
  register: UseFormRegister<T>;
  label?: string;
  width?: string;
  error?: JSX.Element;
  className?: string;
};

export default function PictureChoiceInput<T extends FieldValues>({
  name,
  config,
  control,
  register,
  label,
  width,
  error,
  className,
}: ScoreInputProps<T>): JSX.Element {
  const getLocalizedString = useGetLocalizedString();

  const options: Array<RadioCardOption> =
    config.pictureChoiceOptions?.map(option => ({
      value: option.imageFileKey,
      label: getLocalizedString(option.label) ?? '',
      prefix: (
        <div>
          <img
            className={cn(
              'pointer-events-none mx-auto aspect-square rounded-[--xps-img-border-radius] object-cover'
            )}
            src={getImageUrl(import.meta.env.COHORT_ENV, option.imageFileKey, {
              h: Sizes.M,
              w: Sizes.M,
            })}
          />
        </div>
      ),
    })) ?? [];

  return (
    <FieldWrapper label={label} error={error} className={width}>
      <div className={cn('mx-auto max-w-[360px]', className)}>
        <RadioCardsInput
          name={name}
          options={options}
          control={control}
          register={register}
          hideLabels={!config.showLabels}
          trackingNamespace="cohortForm.pictureChoice"
          config={{
            multipleChoice: config.multipleChoice ?? false,
            maxMultipleChoices: config.maxMultipleChoices,
          }}
        />
      </div>
    </FieldWrapper>
  );
}
