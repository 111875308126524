import EmbedSkeleton from '@cohort/components-xps/components/apps/EmbedSkeleton';
import type {CohortFormDateTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/date';
import {CohortFormDateTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/date';
import TriggerFormWrapper, {
  useSyncUserAttributesToForm,
} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/TriggerFormWrapper';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {Input} from '@cohort/wallet/components/forms/Input';
import {zodResolver} from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import {useForm} from 'react-hook-form';
import {z} from 'zod';

type CohortFormDate = z.infer<
  (typeof CohortFormDateTriggerIntegrationSpec)['verificationAttemptInputSchema']
>;

const CohortFormDateUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFormDateTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, stepId, config}) => {
  const {
    register,
    handleSubmit,
    resetField,
    formState: {errors, isValid},
    watch,
  } = useForm<CohortFormDate>({
    defaultValues: {
      answer: new Date(),
    },
    resolver: zodResolver(CohortFormDateTriggerIntegrationSpec.verificationAttemptInputSchema),
  });
  const shouldDisplayLoader = useSyncUserAttributesToForm(config.userPropertyId, resetField);
  const answer = watch('answer');
  const error = errors.answer?.message;

  if (shouldDisplayLoader) {
    return <EmbedSkeleton width="100%" height={200} />;
  }

  return (
    <TriggerFormWrapper
      stepId={stepId}
      formId="date-form"
      error={error}
      isValid={isValid}
      onSubmit={handleSubmit(data => onTriggerIntegrationUsageSuccess(data))}
    >
      <Input
        type="date"
        register={register('answer', {
          setValueAs: (value: unknown) => {
            const parsed = z.coerce.date().safeParse(value);
            if (!parsed.success) {
              return null;
            }
            return parsed.data;
          },
        })}
        value={dayjs(answer).format('YYYY-MM-DD')}
      />
    </TriggerFormWrapper>
  );
};

export default CohortFormDateUsageComponent;
