import type {CohortFormLongTextTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/longText';
import {CohortFormLongTextTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/longText';
import TriggerFormWrapper from '@cohort/wallet/apps/cohort-form/triggerIntegrations/TriggerFormWrapper';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {TextArea} from '@cohort/wallet/components/forms/TextArea';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import type {z} from 'zod';

type CohortFormLongText = z.infer<
  (typeof CohortFormLongTextTriggerIntegrationSpec)['verificationAttemptInputSchema']
>;

const CohortFormLongTextUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFormLongTextTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, stepId}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.longText',
  });
  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm<CohortFormLongText>({
    resolver: zodResolver(CohortFormLongTextTriggerIntegrationSpec.verificationAttemptInputSchema),
  });
  const error = errors.answer?.message;

  return (
    <TriggerFormWrapper
      stepId={stepId}
      formId="long-text-form"
      error={error}
      isValid={isValid}
      onSubmit={handleSubmit(data => onTriggerIntegrationUsageSuccess(data))}
    >
      <TextArea register={register('answer')} placeholder={t('placeholderAnswerInput')} />
    </TriggerFormWrapper>
  );
};

export default CohortFormLongTextUsageComponent;
