import type {MediaSpec, MediaStruct} from '@cohort/shared/apps/media';
import {z} from 'zod';

const TwitterPostMediaConfigSchema = z.object({
  postId: z.string(),
  imageFileKey: z.string().nullable(),
});
export type TwitterPostMediaConfig = z.infer<typeof TwitterPostMediaConfigSchema>;

export type TwitterPostMediaStruct = MediaStruct<'twitter.post', TwitterPostMediaConfig>;
export const TwitterPostMediaSpec: MediaSpec<TwitterPostMediaStruct> = {
  kind: 'twitter.post',
  configSchema: TwitterPostMediaConfigSchema,
};
