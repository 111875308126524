import type {TikTokKeywordInDescriptionTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import {TikTokKeywordInDescriptionTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import TiktokKeywordInDescriptionTriggerActionCtaComponent from '@cohort/wallet/apps/tiktok/triggers/keywordInDescription/ActionCtaComponent';
import TikTokKeywordInDescriptionCommunitySubmissions from '@cohort/wallet/apps/tiktok/triggers/keywordInDescription/CommunitySubmissions';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const TikTokKeywordInDescriptionTriggerIntegration: TriggerIntegration<TikTokKeywordInDescriptionTriggerStruct> =
  {
    type: 'action',
    spec: TikTokKeywordInDescriptionTriggerSpec,
    communitySubmissionsComponent: TikTokKeywordInDescriptionCommunitySubmissions,
    actionCtaComponent: TiktokKeywordInDescriptionTriggerActionCtaComponent,
  };
