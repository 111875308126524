export function isFile(file: unknown): file is File {
  return file instanceof File;
}

export function isFileList(file: unknown): file is FileList {
  return file instanceof FileList;
}

export const isEmptyFileList = (file: unknown): file is FileList => {
  return isFileList(file) && file.length === 0;
};

export const isLandscapeVideo = async (fileOrVideoSrc: File | string): Promise<boolean> => {
  return new Promise(resolve => {
    const url =
      fileOrVideoSrc instanceof File ? URL.createObjectURL(fileOrVideoSrc) : fileOrVideoSrc;
    const video = document.createElement('video');

    video.onloadedmetadata = () => {
      if (fileOrVideoSrc instanceof File) {
        URL.revokeObjectURL(url);
      }
      const aspectRatio = video.videoWidth / video.videoHeight;
      resolve(aspectRatio > 1);
    };

    video.src = url;
    video.load();
  });
};
