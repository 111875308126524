import MediaContentSpotifyPlaylist from '@cohort/components-xps/components/contents/apps/spotify/playlist/MediaContentSpotifyPlaylist';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import type {SpotifyPlaylistMediaStruct} from '@cohort/shared/apps/spotify/medias/playlist';

const SpotifyPlaylistDisplayComponent: React.FC<MediaContentProps<SpotifyPlaylistMediaStruct>> = ({
  config,
  description,
  isMobile,
  title,
}) => {
  return (
    <MediaContentSpotifyPlaylist
      config={config}
      title={title}
      description={description}
      isMobile={isMobile}
    />
  );
};

export default SpotifyPlaylistDisplayComponent;
