import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {LocalizedStringSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CohortBeInCohortTriggerConfigSchema = z.object({
  cohortId: z.string().uuid(),
  title: LocalizedStringSchema,
});
export type CohortBeInCohortTriggerConfig = z.infer<typeof CohortBeInCohortTriggerConfigSchema>;

export const CohortBeInCohortVerificationAttemptDataSchema = z.object({
  cohortId: z.string().uuid(),
  cohortName: z.string(),
});
export type CohortBeInCohortVerificationAttemptData = z.infer<
  typeof CohortBeInCohortVerificationAttemptDataSchema
>;

export type CohortBeInCohortTriggerStruct = TriggerIntegrationStruct<
  'cohort.be-in-cohort',
  CohortBeInCohortTriggerConfig,
  CohortBeInCohortVerificationAttemptData
>;

export const CohortBeInCohortTriggerIntegrationSpec: TriggerIntegrationSpec<CohortBeInCohortTriggerStruct> =
  {
    id: 'cohort.be-in-cohort' as const,
    configurableMaxAttempts: false,
    configSchema: CohortBeInCohortTriggerConfigSchema,
    verificationAttemptDataSchema: CohortBeInCohortVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
    deprecated: true,
  };
