import TiktokEmbedPost from '@cohort/components-xps/components/apps/tiktok/TiktokEmbedPost';
import type {TikTokKeywordInDescriptionTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import type {TriggerIntegrationSocialLayerComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import Button from '@cohort/wallet/components/button/Button';
import {DrawerModal} from '@cohort/wallet/components/modals/Modal';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

const MIN_POST_COUNT = 3;

function extractTikTokVideoId(url: string): string | null {
  const regex = /\/video\/(\d+)(?=\?)/;
  const match = url.match(regex);
  return match ? (match[1] ?? null) : null;
}

const TikTokKeywordInDescriptionCommunitySubmissions: React.FC<
  TriggerIntegrationSocialLayerComponentProps<TikTokKeywordInDescriptionTriggerStruct>
> = ({socialLayerData}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.keyword-in-description.communitySubmissions',
  });
  const [selectedPost, setSelectedPost] = useState<string | null>(null);

  if (socialLayerData.posts.length < MIN_POST_COUNT) {
    return null;
  }

  return (
    <div className="w-full">
      <h3 className="font-semibold">{t('title')}</h3>
      <div className="flex gap-4 overflow-x-auto py-4">
        {socialLayerData.posts.map((post, index) => {
          const videoId = post.shareUrl ? extractTikTokVideoId(post.shareUrl) : null;

          return (
            <div key={index} className="relative w-40 flex-none overflow-hidden rounded-lg">
              {videoId && (
                <>
                  <TiktokEmbedPost videoId={videoId} width={160} muted isThumbnail />
                  <div
                    className="absolute inset-0 cursor-pointer bg-black/0 transition-colors"
                    onClick={() => setSelectedPost(videoId)}
                  />
                </>
              )}
            </div>
          );
        })}
      </div>

      {selectedPost && (
        <DrawerModal
          tracking={{
            namespace: 'modals.triggerIntegrations.tiktok.keywordInDescription.communitySubmission',
            metadata: {},
          }}
          onClose={() => setSelectedPost(null)}
        >
          <div className="flex w-full flex-col items-center gap-4">
            <TiktokEmbedPost videoId={selectedPost} width="100%" aspect="aspect-video" />
            <Button
              className="w-full"
              variant="secondary"
              onClick={() => setSelectedPost(null)}
              tracking={{
                namespace: 'modals.triggerIntegrations.cohortForm.media.communitySubmission.close',
                metadata: undefined,
              }}
            >
              {t('close')}
            </Button>
          </div>
        </DrawerModal>
      )}
    </div>
  );
};

export default TikTokKeywordInDescriptionCommunitySubmissions;
