import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const TikTokKeywordInBioTriggerConfigSchema = z.object({
  keyword: z.string().min(1, 'errorRequired'),
});
type TikTokKeywordInBioTriggerConfig = z.infer<typeof TikTokKeywordInBioTriggerConfigSchema>;

const TikTokKeywordInBioVerificationAttemptDataSchema = z.object({
  keyword: z.string(),
  username: z.string(),
  bioDescription: z.string().optional(),
});
export type TikTokKeywordInBioVerificationAttemptData = z.infer<
  typeof TikTokKeywordInBioVerificationAttemptDataSchema
>;

export type TikTokKeywordInBioTriggerStruct = TriggerIntegrationStruct<
  'tiktok.keyword-in-bio',
  TikTokKeywordInBioTriggerConfig,
  TikTokKeywordInBioVerificationAttemptData
>;
export const TikTokKeywordInBioTriggerSpec: TriggerIntegrationSpec<TikTokKeywordInBioTriggerStruct> =
  {
    id: 'tiktok.keyword-in-bio' as const,
    configurableMaxAttempts: false,
    configSchema: TikTokKeywordInBioTriggerConfigSchema,
    verificationAttemptDataSchema: TikTokKeywordInBioVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
    useCases: ['user-generated-content'],
  };
