import type {MediaSpec, MediaStruct} from '@cohort/shared/apps/media';
import {LocalizedRichTextSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

const CohortArticleMediaConfigSchema = z.object({
  content: LocalizedRichTextSchema,
  imageFileKey: z.string().nullable(),
});
export type CohortArticleMediaConfig = z.infer<typeof CohortArticleMediaConfigSchema>;

export type CohortArticleMediaStruct = MediaStruct<'cohort.article', CohortArticleMediaConfig>;

export const CohortArticleMediaSpec: MediaSpec<CohortArticleMediaStruct> = {
  kind: 'cohort.article' as const,
  configSchema: CohortArticleMediaConfigSchema,
};
