import EmbedSkeleton from '@cohort/components-xps/components/apps/EmbedSkeleton';
import Title from '@cohort/components-xps/components/Title';
import type {CohortFormCheckboxTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/checkbox';
import {CohortFormCheckboxTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/checkbox';
import TriggerFormWrapper, {
  useSyncUserAttributesToForm,
} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/TriggerFormWrapper';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {CheckboxInput} from '@cohort/wallet/components/forms/CheckboxInput';
import useGetLocalizedString from '@cohort/wallet/hooks/getLocalizedString';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import type {z} from 'zod';

type CohortFormCheckbox = z.infer<
  (typeof CohortFormCheckboxTriggerIntegrationSpec)['verificationAttemptInputSchema']
>;

const CohortFormCheckboxUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFormCheckboxTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config, stepId}) => {
  const {
    register,
    handleSubmit,
    resetField,
    formState: {errors, isValid},
  } = useForm<CohortFormCheckbox>({
    defaultValues: {
      answer: false,
    },
    resolver: zodResolver(CohortFormCheckboxTriggerIntegrationSpec.verificationAttemptInputSchema),
  });
  const shouldDisplayLoader = useSyncUserAttributesToForm(config.userPropertyId, resetField);
  const error = errors.answer?.message;
  const getLocalizedString = useGetLocalizedString();
  const checkboxLabel = getLocalizedString(config.checkboxText);

  if (shouldDisplayLoader) {
    return <EmbedSkeleton width="100%" height={200} />;
  }

  return (
    <TriggerFormWrapper
      stepId={stepId}
      formId="checkbox-form"
      error={error}
      isValid={isValid}
      onSubmit={handleSubmit(data => onTriggerIntegrationUsageSuccess(data))}
    >
      <div className="flex w-full justify-center gap-x-4">
        <CheckboxInput register={register('answer')} />
        <Title className="w-fit text-xl">{checkboxLabel}</Title>
      </div>
    </TriggerFormWrapper>
  );
};

export default CohortFormCheckboxUsageComponent;
