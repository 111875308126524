import {getActionCtaComponentFromTriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import type {TriggerCtaTrackingMetadata} from '@cohort/wallet/components/challenges/NextStepCta';
import {useVerifyStep} from '@cohort/wallet/hooks/useStepVerification';
import type {ChallengeStepTriggerWDto} from '@cohort/wallet-schemas/challengeSteps';

type ActionCtaComponentWrapperProps = {
  trigger: ChallengeStepTriggerWDto;
  challengeStepId: string;
  participationId: string;
  trackingMetadata: TriggerCtaTrackingMetadata;
};

const ActionCtaComponentWrapper: React.FC<ActionCtaComponentWrapperProps> = ({
  trigger,
  challengeStepId,
  participationId,
  trackingMetadata,
}) => {
  const userConnection = trigger.connection;

  const {processStepVerification} = useVerifyStep();

  const ActionCtaComponent = getActionCtaComponentFromTriggerIntegration(
    trigger.triggerIntegrationId
  );

  if (ActionCtaComponent === null) {
    return null;
  }

  return (
    <ActionCtaComponent
      config={trigger.triggerIntegrationConfig}
      processStepVerification={() =>
        processStepVerification({
          participationId,
          challengeStepId,
          challengeStepTriggerId: trigger.id,
          userConnectionId: userConnection?.id ?? null,
        })
      }
      trackingMetadata={trackingMetadata}
      stepId={challengeStepId}
    />
  );
};

export default ActionCtaComponentWrapper;
