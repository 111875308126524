import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import {GarminConnectBeActiveTriggerIntegrationSpec} from '@cohort/shared/apps/garmin-connect/triggers/beActive';
import {GarminConnectCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/garmin-connect/triggers/completeActivity';
import type {GarminConnectUserConnectorStruct} from '@cohort/shared/apps/garmin-connect/userConnector';
import {GarminConnectUserConnectorSpec} from '@cohort/shared/apps/garmin-connect/userConnector';
import {GarminConnectActivityCreatedEventSpec} from '@cohort/shared/apps/garmin-connect/userEvents/activityCreated';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {UserEventSpec} from '@cohort/shared/apps/userEvent';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {createBaseAppUserPropertySpec} from '@cohort/shared/apps/userProperty';

export const GARMIN_CONNECT_APP_ID = 'garmin-connect' as const;
export const GARMIN_CONNECT_APP_NAME = 'Garmin Connect' as const;

export const GarminConnectTriggerIntegrationSpecs = [
  GarminConnectBeActiveTriggerIntegrationSpec,
  GarminConnectCompleteActivityTriggerIntegrationSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;

type GarminConnectTriggerIntegrationIds =
  (typeof GarminConnectTriggerIntegrationSpecs)[number]['id'];

export const GarminConnectEventSpecs = [
  GarminConnectActivityCreatedEventSpec,
] as const satisfies ReadonlyArray<UserEventSpec>;
type GarminConnectEventIds = (typeof GarminConnectEventSpecs)[number]['id'];

export const GarminConnectUserPropertiesSpecs = [
  ...createBaseAppUserPropertySpec(GARMIN_CONNECT_APP_ID, GARMIN_CONNECT_APP_NAME),
] as const satisfies ReadonlyArray<UserPropertySpec<UserPropertyStruct>>;
type GarminConnectUserPropertyIds = (typeof GarminConnectUserPropertiesSpecs)[number]['id'];

export type GarminConnectAppStruct = AppStruct<
  'garmin-connect',
  null,
  GarminConnectUserConnectorStruct,
  null,
  never,
  never,
  never,
  GarminConnectTriggerIntegrationIds,
  GarminConnectEventIds,
  GarminConnectUserPropertyIds,
  never,
  never
>;

export const GarminConnectAppSpec: AppSpec<GarminConnectAppStruct> = {
  id: GARMIN_CONNECT_APP_ID,
  name: GARMIN_CONNECT_APP_NAME,
  category: 'health-fitness',
  merchantConnector: null,
  userConnector: GarminConnectUserConnectorSpec,
  syncSpec: null,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: GarminConnectTriggerIntegrationSpecs,
  userEventSpecs: GarminConnectEventSpecs,
  userPropertySpecs: GarminConnectUserPropertiesSpecs,
  resourceSpecs: [],
  mediaSpecs: [],
  deprecated: true,
};
