import {Card} from '@cohort/components-xps/components/cards/Card';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';

const ProgressCard: React.FC<{
  percentage: number;
  children: React.ReactNode;
  selected?: boolean;
}> = ({percentage, children, selected}) => {
  const {hasDarkBg} = useThemeContext();

  return (
    <Card
      className={cn(
        'relative flex items-center space-x-4 overflow-hidden !rounded-2xl px-4 py-3',
        hasDarkBg ? '!border-white/16 border' : 'border !border-black/8',
        selected && '!bg-[--xps-accent-color]/5 border-2 !border-[--xps-accent-color]'
      )}
    >
      <div
        className={cn(
          'absolute left-0 top-0 h-full w-full',
          !selected && (hasDarkBg ? 'bg-white/8' : 'bg-black/5'),
          selected && 'bg-[--xps-accent-color] opacity-8'
        )}
        style={{width: `${percentage}%`}}
      />
      <div className="relative !ml-0 flex-1">{children}</div>
    </Card>
  );
};

export default ProgressCard;
