import Button from '@cohort/wallet/components/button/Button';
import {EmbeddedTriggerCta} from '@cohort/wallet/components/challenges/NextStepCta';
import {useListUserAttributes} from '@cohort/wallet/hooks/api/UserAttributes';
import {useIsMutating} from '@tanstack/react-query';
import {motion} from 'motion/react';
import type {BaseSyntheticEvent} from 'react';
import {useEffect} from 'react';
import type {UseFormResetField} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

export function useSyncUserAttributesToForm(
  userPropertyId: string | null,
  resetField: UseFormResetField<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    answer: any;
  }>
): boolean {
  const {data: userAttributes, isFetched} = useListUserAttributes(
    userPropertyId ? [userPropertyId] : [],
    !!userPropertyId
  );
  const shouldDisplayLoader = !!userPropertyId && !isFetched;

  useEffect(() => {
    if (userAttributes) {
      resetField('answer', {defaultValue: userAttributes[0]?.value});
    }
  }, [userAttributes, resetField]);

  return shouldDisplayLoader;
}

const TriggerFormWrapper: React.FC<{
  children: React.ReactNode;
  formId: string;
  stepId: string;
  error: string | undefined;
  isValid: boolean;
  onSubmit: (e?: BaseSyntheticEvent<object> | undefined) => Promise<void>;
}> = ({children, formId, stepId, error, isValid, onSubmit}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.triggerFormWrapper',
  });
  const ongoingVerifications = useIsMutating({
    mutationKey: ['verifyStep', stepId],
  });
  const isVerifying = ongoingVerifications > 0;

  return (
    <form id={formId} className="md:min-w-[350px]" onSubmit={onSubmit}>
      {children}
      {error && (
        <motion.p className="mt-2 text-red-500" initial={{opacity: 0}} animate={{opacity: 1}}>
          {error}
        </motion.p>
      )}
      <EmbeddedTriggerCta>
        <Button
          form={formId}
          tracking={{
            namespace: `triggerIntegrations.${formId}.submit`,
          }}
          variant="primary"
          className="w-full"
          type="submit"
          loading={isVerifying}
          disabled={!isValid}
        >
          {t('submit')}
        </Button>
      </EmbeddedTriggerCta>
    </form>
  );
};

export default TriggerFormWrapper;
