import YoutubeLikeTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/youtube/triggers/like/InstructionsComponent';
import type {YoutubeLikeTriggerStruct} from '@cohort/shared/apps/youtube/triggers/like';
import {YoutubeLikeTriggerSpec} from '@cohort/shared/apps/youtube/triggers/like';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import YoutubeLikeTriggerActionCtaComponent from '@cohort/wallet/apps/youtube/triggers/like/ActionCtaComponent';
import YoutubeLikeTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/youtube/triggers/like/DetailsComponent';

export const YoutubeLikeTriggerIntegration: TriggerIntegration<YoutubeLikeTriggerStruct> = {
  type: 'action',
  spec: YoutubeLikeTriggerSpec,
  detailsComponent: YoutubeLikeTriggerIntegrationDetailsComponent,
  actionCtaComponent: YoutubeLikeTriggerActionCtaComponent,
  instructionsComponent: YoutubeLikeTriggerIntegrationInstructionsComponent,
};
