import {LocalizedStringSchema} from '@cohort/shared/schema/common';
import {match} from 'ts-pattern';
import {z} from 'zod';

export const CohortFormPromptTypeSchema = z.enum([
  'checkbox',
  'date',
  'email',
  'long-text',
  'media',
  'number',
  'picture-choice',
  'text',
  'select',
  'score',
]);
export type CohortFormPromptType = z.infer<typeof CohortFormPromptTypeSchema>;

/* -- Base Prompt -- */
const BaseCohortFormPromptSchema = z.object({
  id: z.string().uuid(),
  name: LocalizedStringSchema,
  referenceId: z.string().min(1).nullable(),
  imageFileKey: z.string().nullable(),
  mandatory: z.boolean(),
  userPropertyId: z.string().uuid().nullable(),
});

/* -- Media Prompt -- */
export const CohortFormMediaTypeSchema = z.enum(['image', 'video', 'imageOrVideo']);
export type CohortFormMediaType = z.infer<typeof CohortFormMediaTypeSchema>;

export const MediaCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('media'),
  mediaType: CohortFormMediaTypeSchema,
});
export type MediaCohortFormPrompt = z.infer<typeof MediaCohortFormPromptSchema>;

/* -- Generic Prompts -- */
export const CheckboxCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('checkbox'),
});

export const DateCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('date'),
});

export const EmailCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('email'),
});

export const LongTextCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('long-text'),
});

export const NumberCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('number'),
});

export const TextCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('text'),
});

export const ScoreCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('score'),
});

/* -- Select Prompts -- */
export const CohortFormOptionSchema = z.object({
  value: z.string().min(1),
  label: LocalizedStringSchema,
});
export type CohortFormOption = z.infer<typeof CohortFormOptionSchema>;

export const SelectCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('select'),
  multipleChoice: z.boolean().default(false).optional(),
  options: z.array(CohortFormOptionSchema).nullable(),
});
export type SelectCohortFormPrompt = z.infer<typeof SelectCohortFormPromptSchema>;

/* -- Picture Choice Prompts -- */
export const CohortFormPictureChoiceOptionSchema = z.object({
  imageFileKey: z.string(),
  label: LocalizedStringSchema.optional(),
});
export type CohortFormPictureChoiceOption = z.infer<typeof CohortFormPictureChoiceOptionSchema>;

export const PictureChoiceCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('picture-choice'),
  multipleChoice: z.boolean().default(false).optional(),
  maxMultipleChoices: z.number().min(1).nullable().optional(),
  showLabels: z.boolean().default(false).optional(),
  pictureChoiceOptions: z.array(CohortFormPictureChoiceOptionSchema).nullable(),
});
export type PictureChoiceCohortFormPrompt = z.infer<typeof PictureChoiceCohortFormPromptSchema>;

/* -- Refined Prompts Union -- */
export const CohortFormPromptSchema = z
  .discriminatedUnion('type', [
    MediaCohortFormPromptSchema,
    PictureChoiceCohortFormPromptSchema,
    CheckboxCohortFormPromptSchema,
    DateCohortFormPromptSchema,
    EmailCohortFormPromptSchema,
    LongTextCohortFormPromptSchema,
    NumberCohortFormPromptSchema,
    TextCohortFormPromptSchema,
    ScoreCohortFormPromptSchema,
    SelectCohortFormPromptSchema,
  ])
  .transform(data => {
    return match(data.type)
      .with('picture-choice', () => {
        const pictureChoiceData = data as PictureChoiceCohortFormPrompt;

        const {pictureChoiceOptions, showLabels} = pictureChoiceData;
        if (pictureChoiceOptions === null) {
          return pictureChoiceData;
        }
        // Remove empty options
        pictureChoiceData.pictureChoiceOptions = pictureChoiceOptions.filter(
          option => option.imageFileKey !== '' && option.imageFileKey
        );
        // Delete labels if not shown
        if (!showLabels) {
          pictureChoiceOptions.forEach(option => {
            delete option.label;
          });
        }

        return pictureChoiceData;
      })
      .with('select', () => {
        const selectData = data as SelectCohortFormPrompt;

        if (Array.isArray(selectData.options) && selectData.options.length === 0) {
          selectData.options = null;
          return selectData;
        }
        return selectData;
      })
      .otherwise(() => {
        return data;
      });
  });
export type CohortFormPrompt = z.infer<typeof CohortFormPromptSchema>;

// Schema for the config of the cohort form
export const CohortFormConfigSchema = z.object({
  ctaText: LocalizedStringSchema.optional(),
  prompts: z.array(CohortFormPromptSchema).min(1, {message: 'errorRequired'}),
});
export type CohortFormConfig = z.infer<typeof CohortFormConfigSchema>;

export const CohortFormAnswerSchema = z.union([
  z.boolean(),
  z.number(),
  z.date(),
  z.string(),
  z.null(),
  z.array(z.string()),
]);

export type CohortFormAnswer = z.infer<typeof CohortFormAnswerSchema>;

export const CohortFormAnswersSchema = z.record(z.string(), CohortFormAnswerSchema);
export type CohortFormAnswers = z.infer<typeof CohortFormAnswersSchema>;
