import FacebookLikePostTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/facebook/triggers/likePost/InstructionsComponent';
import type {FacebookLikePostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePost';
import {FacebookLikePostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePost';
import FacebookLikePostTriggerActionCtaComponent from '@cohort/wallet/apps/facebook/triggers/likePost/ActionCtaComponent';
import FacebookLikePostTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/facebook/triggers/likePost/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const FacebookLikePostTriggerIntegration: TriggerIntegration<FacebookLikePostTriggerStruct> =
  {
    type: 'action',
    spec: FacebookLikePostTriggerSpec,
    detailsComponent: FacebookLikePostTriggerIntegrationDetailsComponent,
    actionCtaComponent: FacebookLikePostTriggerActionCtaComponent,
    instructionsComponent: FacebookLikePostTriggerIntegrationInstructionsComponent,
  };
