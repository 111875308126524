import type {CohortVisitLinkTriggerStruct} from '@cohort/shared/apps/cohort/triggers/visitLink';
import {CohortVisitLinkTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/visitLink';
import CohortVisitLinkTriggerIntegrationActionCtaComponent from '@cohort/wallet/apps/cohort/triggerIntegrations/visitLink/ActionCtaComponent';
import CohortVisitLinkTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/cohort/triggerIntegrations/visitLink/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortVisitLinkTriggerIntegration: TriggerIntegration<CohortVisitLinkTriggerStruct> = {
  type: 'action',
  spec: CohortVisitLinkTriggerIntegrationSpec,
  actionCtaComponent: CohortVisitLinkTriggerIntegrationActionCtaComponent,
  detailsComponent: CohortVisitLinkTriggerIntegrationDetailsComponent,
  disableExponentialBackoff: true,
};
