import CohortArticleVisualPreviewContent from '@cohort/components-xps/components/contents/apps/cohort/article/VisualPreviewContent';
import type {CohortArticleMediaStruct} from '@cohort/shared/apps/cohort/medias/article';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';

const CohortArticleVisualPreview: React.FC<VisualPreviewProps<CohortArticleMediaStruct>> = ({
  config,
  title,
  imageSize = 'XL',
}) => {
  const {isMobile} = useScreenSize();
  const imageFileKey = config.imageFileKey;

  return (
    <CohortArticleVisualPreviewContent
      imageSize={imageSize}
      imageFileKey={imageFileKey}
      title={title}
      isMobile={isMobile}
    />
  );
};

export default CohortArticleVisualPreview;
