import YoutubeVideoVisualPreviewContent from '@cohort/components-xps/components/contents/apps/youtube/video/VisualPreviewContent';
import type {YoutubeVideoMediaStruct} from '@cohort/shared/apps/youtube/medias/video';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';

const YoutubeVideoVisualPreview: React.FC<VisualPreviewProps<YoutubeVideoMediaStruct>> = ({
  config,
  title,
}) => {
  return <YoutubeVideoVisualPreviewContent videoId={config.videoId} title={title} />;
};

export default YoutubeVideoVisualPreview;
