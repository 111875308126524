import type {CohortFormPictureChoiceTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/pictureChoice';
import {CohortFormPictureChoiceTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/pictureChoice';
import CohortFormPictureChoiceSocialLayerComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/pictureChoice/SocialLayerComponent';
import CohortFormPictureChoiceUsageComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/pictureChoice/UsageComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortFormPictureChoiceTriggerIntegration: TriggerIntegration<CohortFormPictureChoiceTriggerStruct> =
  {
    type: 'embedded',
    spec: CohortFormPictureChoiceTriggerIntegrationSpec,
    usageComponent: CohortFormPictureChoiceUsageComponent,
    socialLayerComponent: CohortFormPictureChoiceSocialLayerComponent,
    disableExponentialBackoff: true,
  };
