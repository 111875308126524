/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {getMerchantByHostname} from '@cohort/wallet/lib/Endpoints';

export const merchantsKeys = {
  merchants: ['merchants'] as const,
  getByHostName: (merchantId: string) => [...merchantsKeys.merchants, merchantId] as const,
};

export const useMerchant = () => {
  const hostname = window.location.hostname;
  return useCohortQuery({
    queryKey: merchantsKeys.getByHostName(hostname),
    queryFn: async () => getMerchantByHostname(hostname),
  });
};
