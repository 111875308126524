import type {
  AppSpec,
  AppStruct,
  BaseProfileProperties,
  ConnectorStruct,
} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import {GoogleFitBeActiveTriggerIntegrationSpec} from '@cohort/shared/apps/google-fit/triggers/beActive';
import {GoogleFitCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/google-fit/triggers/completeActivity';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {createBaseAppUserPropertySpec} from '@cohort/shared/apps/userProperty';

export const GOOGLE_FIT_APP_ID = 'google-fit' as const;
export const GOOGLE_FIT_APP_NAME = 'Google Fit' as const;

export const GoogleFitUserPropertiesSpecs = [
  ...createBaseAppUserPropertySpec(GOOGLE_FIT_APP_ID, GOOGLE_FIT_APP_NAME),
] as const satisfies ReadonlyArray<UserPropertySpec<UserPropertyStruct>>;
type GoogleFitUserPropertyIds = (typeof GoogleFitUserPropertiesSpecs)[number]['id'];

export const GoogleFitTriggerIntegrationSpecs = [
  GoogleFitBeActiveTriggerIntegrationSpec,
  GoogleFitCompleteActivityTriggerIntegrationSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;

type GoogleFitTriggerIntegrationIds = (typeof GoogleFitTriggerIntegrationSpecs)[number]['id'];

export type GoogleFitConnectorStruct = ConnectorStruct<
  'google-fit',
  OAuth2AppCredentials,
  OAuth2Credentials,
  BaseProfileProperties,
  null
>;

export type GoogleFitAppStruct = AppStruct<
  'google-fit',
  null,
  GoogleFitConnectorStruct,
  null,
  never,
  never,
  never,
  GoogleFitTriggerIntegrationIds,
  never,
  GoogleFitUserPropertyIds,
  never,
  never
>;

export const GoogleFitConnectorSpec = basicOauth2ConnectorSpec<GoogleFitConnectorStruct>(
  'google-fit',
  BaseProfilePropertiesSchema
);

export const GoogleFitAppSpec: AppSpec<GoogleFitAppStruct> = {
  id: GOOGLE_FIT_APP_ID,
  name: GOOGLE_FIT_APP_NAME,
  category: 'health-fitness',
  merchantConnector: null,
  userConnector: GoogleFitConnectorSpec,
  syncSpec: null,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: GoogleFitTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: GoogleFitUserPropertiesSpecs,
  resourceSpecs: [],
  mediaSpecs: [],
  deprecated: true,
};
