import type {CohortFormEmailTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/email';
import {CohortFormEmailTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/email';
import CohortFormEmailUsageComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/email/UsageComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortFormEmailTriggerIntegration: TriggerIntegration<CohortFormEmailTriggerStruct> = {
  type: 'embedded',
  spec: CohortFormEmailTriggerIntegrationSpec,
  usageComponent: CohortFormEmailUsageComponent,
  disableExponentialBackoff: true,
};
