import {Card, CardTitle} from '@cohort/components-xps/components/cards/Card';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {Language} from '@cohort/shared/schema/common';
import {getI18n, useTranslation} from 'react-i18next';

const InstructionsWrapper: React.FC<{selectedLanguage?: Language; children: React.ReactNode}> = ({
  selectedLanguage,
  children,
}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'apps.triggers.instructionsWrapper',
    lng: selectedLanguage ?? getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });

  return (
    <Card className="p-4 text-left">
      <CardTitle>{t('title')}</CardTitle>
      {children}
    </Card>
  );
};

export default InstructionsWrapper;
