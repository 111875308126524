import TiktokLikeTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/tiktok/triggers/likeTiktok/InstructionsComponent';
import type {TikTokLikeTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';
import {TikTokLikeTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';
import TikTokLikeTiktokTriggerActionCtaComponent from '@cohort/wallet/apps/tiktok/triggers/likeTiktok/ActionCtaComponent';
import TiktokLikeTiktokTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/tiktok/triggers/likeTiktok/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const TikTokLikeTiktokTriggerIntegration: TriggerIntegration<TikTokLikeTiktokTriggerStruct> =
  {
    type: 'action',
    spec: TikTokLikeTiktokTriggerSpec,
    detailsComponent: TiktokLikeTiktokTriggerIntegrationDetailsComponent,
    actionCtaComponent: TikTokLikeTiktokTriggerActionCtaComponent,
    instructionsComponent: TiktokLikeTriggerIntegrationInstructionsComponent,
  };
