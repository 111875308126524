import {isCohortError} from '@cohort/shared/schema/common/errors';
import {campaignsKeys} from '@cohort/wallet/hooks/api/Campaign';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import {spacesKeys} from '@cohort/wallet/hooks/api/Space';
import {userAttributesKeys} from '@cohort/wallet/hooks/api/UserAttributes';
import useNotify from '@cohort/wallet/hooks/notify';
import {verifyChallengeStep} from '@cohort/wallet/lib/Endpoints';
import type {VerifyChallengeStepWDto} from '@cohort/wallet-schemas/challengeParticipation';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback, useRef, useState} from 'react';
import {flushSync} from 'react-dom';
import {useTranslation} from 'react-i18next';

type VerifyStepPayload = VerifyChallengeStepWDto & {
  participationId: string;
};

type Output = {
  processStepVerification: (input: VerifyStepPayload, disableExponentialBackoff?: boolean) => void;
  processStepVerificationAsync: (
    input: VerifyStepPayload,
    disableExponentialBackoff?: boolean
  ) => Promise<boolean>;
  isVerificationLoading: boolean;
};

const MAX_ATTEMPTS = 3;

export const useVerifyStep = (): Output => {
  const {t} = useTranslation('hooks', {keyPrefix: 'useStepVerification'});
  const notify = useNotify();
  const queryClient = useQueryClient();
  const stepIdRef = useRef<string>('');
  const attemptCount = useRef(0);
  const [withRetry, setWithRetry] = useState(true);

  const getErrorMessage = (err: unknown): string => {
    if (!isCohortError(err)) {
      return t('verificationErrorNotification');
    }
    if (isCohortError(err) && err.code === 'challenge-step-completion.already-completed') {
      return t('verificationAlreadyCompletedNotification');
    }
    return (err.context.errorMessage as string | undefined) ?? t('verificationFailedNotification');
  };

  const getErrorDescription = (err: unknown): string | undefined => {
    if (!isCohortError(err)) {
      return undefined;
    }

    return err.context.retryable
      ? `${t('errorMessageOldData')}${t('errorMessageRetry')}`
      : undefined;
  };

  const {
    mutate,
    mutateAsync,
    isLoading: isVerificationLoading,
  } = useCohortMutation({
    mutationKey: ['verifyStep', stepIdRef.current],
    mutationFn: async (input: VerifyStepPayload) => {
      const {participationId, ...rest} = input;

      attemptCount.current += 1;
      return verifyChallengeStep(participationId, {
        ...rest,
        skipAttemptGeneration: withRetry ? attemptCount.current <= MAX_ATTEMPTS : false,
      });
    },
    onSettled: () => (attemptCount.current = 0),
    onSuccess: () => {
      queryClient.invalidateQueries(spacesKeys.spaces);
      queryClient.invalidateQueries(campaignsKeys.campaigns);
      // some trigger completion can update user attributes
      queryClient.invalidateQueries(userAttributesKeys.userAttributes);
    },
    onError: err => {
      const errMessage = getErrorMessage(err);
      const description = getErrorDescription(err);

      return notify('error', errMessage, {description});
    },
    retry: withRetry ? MAX_ATTEMPTS : false,
    retryDelay: withRetry ? (attemptIndex: number) => 3000 * 2 ** attemptIndex : undefined,
  });

  const processStepVerification = useCallback(
    (input: VerifyStepPayload, disableExponentialBackoff?: boolean) => {
      stepIdRef.current = input.challengeStepId;
      setWithRetry(!disableExponentialBackoff);
      mutate(input);
    },
    [mutate]
  );

  const processStepVerificationAsync = useCallback(
    async (input: VerifyStepPayload, disableExponentialBackoff?: boolean) => {
      stepIdRef.current = input.challengeStepId;
      // Need to flush to ensure the retry state is updated before the mutation is called
      flushSync(() => setWithRetry(!disableExponentialBackoff));
      const result = await Promise.allSettled([mutateAsync(input)]);

      if (result[0].status === 'fulfilled') {
        return result[0].value.completedChallengeStepIds.includes(input.challengeStepId);
      }
      return false;
    },
    [mutateAsync]
  );

  return {
    processStepVerification,
    processStepVerificationAsync,
    isVerificationLoading,
  };
};
