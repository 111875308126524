import type {CohortSwipeCardsTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {CohortSwipeCardsTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import CohortSwipeCardsSocialLayerComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/swipeCards/SocialLayerComponent';
import CohortSwipeCardsUsageComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/swipeCards/UsageComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortSwipeCardsTriggerIntegration: TriggerIntegration<CohortSwipeCardsTriggerStruct> =
  {
    type: 'embedded',
    spec: CohortSwipeCardsTriggerIntegrationSpec,
    usageComponent: CohortSwipeCardsUsageComponent,
    socialLayerComponent: CohortSwipeCardsSocialLayerComponent,
    disableExponentialBackoff: true,
  };
