import type {TriggerIntegrationSpec, UnverifiedTriggerStruct} from '@cohort/shared/apps/trigger';
import {UnverifiedTriggerBaseConfigSchema} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const InstagramFollowTriggerConfigSchema = UnverifiedTriggerBaseConfigSchema.extend({
  merchantUsername: z.string().min(1, 'errorRequired'),
});
type InstagramFollowTriggerConfig = z.infer<typeof InstagramFollowTriggerConfigSchema>;

const InstagramFollowVerificationAttemptDataSchema = z.object({
  username: z.string(),
  url: z.string().url(),
  verificationMessage: z.string().optional(),
});
export type InstagramFollowVerificationAttemptData = z.infer<
  typeof InstagramFollowVerificationAttemptDataSchema
>;

export type InstagramFollowTriggerStruct = UnverifiedTriggerStruct<
  'instagram.follow',
  InstagramFollowTriggerConfig,
  InstagramFollowVerificationAttemptData
>;
export const InstagramFollowTriggerIntegrationSpec: TriggerIntegrationSpec<InstagramFollowTriggerStruct> =
  {
    id: 'instagram.follow' as const,
    configurableMaxAttempts: false,
    configSchema: InstagramFollowTriggerConfigSchema,
    verificationAttemptDataSchema: InstagramFollowVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
    useCases: ['social-media'],
  };
