import {TriggerReferenceIdSchema} from '@cohort/shared/apps/cohort-form/triggers/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {DatetimeSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

const VerificationAttemptInputSchema = z.object({
  answer: DatetimeSchema,
});
type VerificationAttemptInput = z.infer<typeof VerificationAttemptInputSchema>;

const VerificationAttemptDataSchema = z.object({
  answer: DatetimeSchema,
});
type VerificationAttemptData = z.infer<typeof VerificationAttemptDataSchema>;

const ConfigSchema = z.object({
  referenceId: TriggerReferenceIdSchema,
  userPropertyId: z.string().uuid().nullable(),
});
type Config = z.infer<typeof ConfigSchema>;

export type CohortFormDateTriggerStruct = TriggerIntegrationStruct<
  'cohort-form.date',
  Config,
  VerificationAttemptData,
  VerificationAttemptInput
>;

export const CohortFormDateTriggerIntegrationSpec: TriggerIntegrationSpec<CohortFormDateTriggerStruct> =
  {
    id: 'cohort-form.date' as const,
    configurableMaxAttempts: false,
    configSchema: ConfigSchema,
    verificationAttemptDataSchema: VerificationAttemptDataSchema,
    verificationAttemptInputSchema: VerificationAttemptInputSchema,
    requiresMerchantConnection: false,
    useCases: ['customer-knowledge'],
  };
