import TwitterPostVisualPreviewContent from '@cohort/components-xps/components/contents/apps/twitter/post/VisualPreviewContent';
import type {TwitterPostMediaStruct} from '@cohort/shared/apps/twitter/medias/post';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';

const TwitterPostVisualPreview: React.FC<VisualPreviewProps<TwitterPostMediaStruct>> = ({
  config,
  title,
  imageSize = 'XL',
}) => {
  const {isMobile} = useScreenSize();
  const imageFileKey = config.imageFileKey;

  return (
    <TwitterPostVisualPreviewContent
      imageSize={imageSize}
      imageFileKey={imageFileKey}
      title={title}
      isMobile={isMobile}
    />
  );
};

export default TwitterPostVisualPreview;
