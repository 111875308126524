import {Card, CardTitleSecondary} from '@cohort/components-xps/components/cards/Card';
import {cn} from '@cohort/shared-frontend/utils/classNames';

type LinkPreviewCardProps = {
  title?: string;
  description?: string;
  image?: string;
};

const LinkPreviewCard: React.FC<LinkPreviewCardProps> = ({title, description, image}) => (
  <Card
    className={cn(
      'grid w-full grid-cols-[max-content_1fr] items-center gap-4 p-4',
      !image && 'grid-cols-[1fr]',
      image && 'grid-cols-[max-content_1fr]'
    )}
  >
    {image && (
      <img
        src={image}
        className="aspect-square size-[100px] !rounded-md object-cover md:size-[140px]"
      />
    )}
    <div className="flex flex-col gap-2 truncate">
      {title && <CardTitleSecondary className="truncate text-sm">{title}</CardTitleSecondary>}
      {description && <p className="truncate">{description}</p>}
    </div>
  </Card>
);

export default LinkPreviewCard;
