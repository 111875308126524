import EmbedError from '@cohort/components-xps/components/apps/EmbedError';
import EmbedSkeleton from '@cohort/components-xps/components/apps/EmbedSkeleton';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {SpotifyResourceType} from '@cohort/shared-frontend/common/apps/spotify';
import {loadScript} from '@cohort/shared-frontend/utils/loadScript';
import React, {useEffect, useRef, useState, useSyncExternalStore} from 'react';
import {useTranslation} from 'react-i18next';
import {getI18n} from 'react-i18next';

type EmbedController = {
  loadUri: (spotifyUri: string, preferVideo?: boolean, startAt?: number) => void;
  play: () => void;
  pause: () => void;
  resume: () => void;
  togglePlay: () => void;
  restart: () => void;
  seek: (seconds: number) => void;
  destroy: () => void;
  addListener: (
    event: 'ready' | 'playback_update',
    callback: (eventData: PlaybackUpdateEvent) => void
  ) => void;
};

type IFrameAPI = {
  createController: (
    element: HTMLElement,
    options: {uri: string; width: string; height: string},
    callback: (controller: EmbedController) => void
  ) => void;
};

type PlaybackUpdateEvent = {
  data: {
    isPaused: boolean;
    position: number;
    duration: number;
  };
};

declare global {
  interface Window {
    onSpotifyIframeApiReady: ((iframeApi: IFrameAPI) => void) | null;
    Spotify?: {
      Embed?: {
        API?: IFrameAPI;
      };
    };
  }
}

const SPOTIFY_IFRAME_HEIGHTS = {
  sm: '152',
  lg: '352',
};

type SpotifyPlayerProps = {
  onError?: (err: unknown) => void;
  resourceType: SpotifyResourceType;
  resourceId: string;
  size?: keyof typeof SPOTIFY_IFRAME_HEIGHTS;
};

// Shared state for API readiness
let isSpotifyApiReady = false;
const spotifyApiListeners = new Set<() => void>();

// Subscribe function for useSyncExternalStore
function subscribe(callback: () => void): () => void {
  spotifyApiListeners.add(callback);
  return () => {
    spotifyApiListeners.delete(callback);
  };
}

// Snapshot functions for useSyncExternalStore
function getSnapshot(): boolean {
  return isSpotifyApiReady;
}

function getServerSnapshot(): boolean {
  return false;
}

function useSpotifyApi(): {isApiReady: boolean; isApiError: boolean} {
  const isApiReady = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
  const [isApiError, setIsApiError] = useState(false);

  useEffect(() => {
    if (isSpotifyApiReady) {
      return;
    }

    loadScript('https://open.spotify.com/embed/iframe-api/v1')
      .then(() => {
        if (window.Spotify?.Embed?.API) {
          isSpotifyApiReady = true;
          spotifyApiListeners.forEach(listener => listener());
          spotifyApiListeners.clear();
        } else if (!window.onSpotifyIframeApiReady) {
          window.onSpotifyIframeApiReady = (iframeApi: IFrameAPI) => {
            window.Spotify = window.Spotify || {};
            window.Spotify.Embed = window.Spotify.Embed || {};
            window.Spotify.Embed.API = iframeApi;

            isSpotifyApiReady = true;
            spotifyApiListeners.forEach(listener => listener());
            spotifyApiListeners.clear();
          };
        }
      })
      .catch(() => setIsApiError(true));
  }, []);

  return {isApiReady, isApiError};
}

const SpotifyPlayer: React.FC<SpotifyPlayerProps> = ({
  resourceType,
  resourceId,
  onError,
  size = 'sm',
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const controllerRef = useRef<EmbedController | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<HTMLDivElement | null>(null);
  const {isApiReady, isApiError} = useSpotifyApi();
  const {t} = useTranslation('components', {
    keyPrefix: 'apps.spotify.spotifyPlayer',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });

  useEffect(() => {
    if (isApiReady && playerRef.current && !controllerRef.current) {
      const options = {
        width: `${wrapperRef.current?.clientWidth ?? 350}px`,
        height: SPOTIFY_IFRAME_HEIGHTS[size],
        uri: `spotify:${resourceType}:${resourceId}`,
      };

      try {
        window.Spotify?.Embed?.API?.createController(
          playerRef.current,
          options,
          (ctrl: EmbedController) => {
            controllerRef.current = ctrl;
            ctrl.addListener('ready', () => setIsLoading(false));
          }
        );
      } catch (err) {
        onError?.(err);
        setIsLoading(false);
        setIsError(true);
      }
    }
  }, [isApiReady, resourceType, resourceId, size, onError]);

  // Handle resource changes
  useEffect(() => {
    if (controllerRef.current) {
      controllerRef.current.loadUri(`spotify:${resourceType}:${resourceId}`);
    }
  }, [resourceType, resourceId]);

  if (isApiError || isError) {
    return <EmbedError error={t('spotifyEmbedError')} />;
  }

  return (
    <div ref={wrapperRef} className="grid w-full [&>*]:[grid-column:1] [&>*]:[grid-row:1]">
      {isLoading && (
        <EmbedSkeleton
          width="100%"
          height={parseInt(SPOTIFY_IFRAME_HEIGHTS[size])}
          className="rounded-[--xps-img-border-radius]"
        />
      )}
      <div ref={playerRef} />
    </div>
  );
};

export default SpotifyPlayer;
