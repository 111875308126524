import type {AssetKind, WalletAssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import type {CohortFormMediaType} from '@cohort/shared/schema/common/cohortForm';
import type {
  AssetMinDimension,
  AssetMinDimensions,
  SizedAssetKind,
} from '@cohort/shared/utils/fileUploads';
import {getMaxFileSize} from '@cohort/shared/utils/fileUploads';
import {isWalletImageFile} from '@cohort/shared/utils/mimeTypes';
import notify from '@cohort/wallet/components/toasts/Toast';
import type {TFunction} from 'i18next';
import type {Control, FieldValues, Path, UseFormRegister} from 'react-hook-form';
import {match} from 'ts-pattern';

export type FormField<T extends FieldValues> = {
  name: Path<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  rules?: Parameters<UseFormRegister<T>>[1];
};

export const isImageTooSmall = async (
  imageSrc: string,
  minDimensions: AssetMinDimensions[SizedAssetKind]
): Promise<boolean> => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = function () {
      resolve(img.width < minDimensions.width || img.height < minDimensions.height);
    };
    img.src = imageSrc;
  });
};

export const compressImage = async (
  file: File,
  options: {maxSize?: number; quality?: number} = {}
): Promise<File> => {
  const {maxSize, quality} = options;

  if (!isWalletImageFile(file.type)) {
    // If we can't optimize the image, return the original file
    return file;
  }

  const imageBitmap = await createImageBitmap(file);
  const canvas = document.createElement('canvas');

  let width = imageBitmap.width;
  let height = imageBitmap.height;

  // Resize if maxSize is defined and image is larger than maxSize
  if (maxSize && (width > maxSize || height > maxSize)) {
    if (width >= height) {
      height = Math.round((height * maxSize) / width);
      width = maxSize;
    } else {
      width = Math.round((width * maxSize) / height);
      height = maxSize;
    }
  }

  // Draw the image on a canvas in order to compress it
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Could not get canvas context');
  }

  ctx.drawImage(imageBitmap, 0, 0, width, height);

  // Convert to WebP and adjust quality to save space
  const blob = await new Promise<Blob | null>(resolve =>
    canvas.toBlob(resolve, 'image/webp', quality)
  );

  if (!blob) {
    return file;
  }

  const newFileName = file.name.replace(/\.[^.]+$/, '.webp');
  return new File([blob], newFileName, {type: 'image/webp'});
};

export const isValidFile = async (
  assetKind: AssetKind,
  file: File,
  minFileDimensions: AssetMinDimension | undefined,
  t: TFunction<'components', 'forms.fileInput'>,
  customAcceptedTypes?: string
): Promise<boolean> => {
  const acceptedTypes = customAcceptedTypes ?? AllowedAssetMimeTypes[assetKind].options.join(',');
  const fileUrl = URL.createObjectURL(file);

  // Can happen when drag & dropping a file of invalid type
  if (!acceptedTypes.includes(file.type)) {
    notify('error', t('errorInvalidFileType'));
    return false;
  }

  // Validate min size
  if (minFileDimensions && (await isImageTooSmall(fileUrl, minFileDimensions))) {
    notify(
      'error',
      t('errorImageTooSmall', {
        width: minFileDimensions.width,
        height: minFileDimensions.height,
      })
    );
    return false;
  }

  // Validate max size
  if (file.size > getMaxFileSize(assetKind, file.type).size) {
    notify('error', `${t(`errorFileTooLarge`)} ${getMaxFileSize(assetKind, file.type).label}`);
    return false;
  }

  return true;
};

export function getAcceptedAssetKindsForMediaInput(
  mediaType: CohortFormMediaType | undefined
): WalletAssetKind {
  if (mediaType === undefined) {
    throw new Error('Media type is required');
  }
  return match(mediaType)
    .with('image', () => 'cohortFormImage' as const)
    .with('video', () => 'cohortFormVideo' as const)
    .with('imageOrVideo', () => 'cohortFormImageOrVideo' as const)
    .exhaustive();
}
