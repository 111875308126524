import {LanguageSchema, RichTextSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CampaignStatusSchema = z.enum(['draft', 'published', 'ended', 'archived']);
export const PublishedCampaignStatusSchema = z.enum(['archived', 'published', 'ended']);

export const CampaignTypeSchema = z.enum(['store', 'challenge', 'airdrop']);

export const CampaignAudienceSchema = z.enum(['everyone', 'cohort', 'invitees']);

export type CampaignStatus = z.infer<typeof CampaignStatusSchema>;
export type PublishedCampaignStatus = z.infer<typeof PublishedCampaignStatusSchema>;
export type CampaignType = z.infer<typeof CampaignTypeSchema>;
export type CampaignAudience = z.infer<typeof CampaignAudienceSchema>;

export const FaqItemSchema = z.object({
  question: z.string().min(1),
  answer: RichTextSchema,
});
export type FaqItem = z.infer<typeof FaqItemSchema>;

export const LocalizedFaqsSchema = z.record(LanguageSchema, z.array(FaqItemSchema).or(z.null()));
export type LocalizedFaqs = z.infer<typeof LocalizedFaqsSchema>;

export const CampaignPricingSchema = z.enum(['free', 'paid']);
export type CampaignPricing = z.infer<typeof CampaignPricingSchema>;
