import YoutubeVideoPlayer from '@cohort/components-xps/components/apps/youtube/YoutubeVideoPlayer';
import type {YoutubeLikeTriggerStruct} from '@cohort/shared/apps/youtube/triggers/like';
import serializeDataAttributes from '@cohort/shared-frontend/utils/serializeDataAtttributes';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';

const YoutubeLikeTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<YoutubeLikeTriggerStruct>
> = ({config, stepId, triggerId}) => (
  <div
    className="w-full"
    {...serializeDataAttributes({
      'step-id': stepId,
      'trigger-id': triggerId,
    })}
  >
    <YoutubeVideoPlayer videoId={config.videoId} />
  </div>
);

export default YoutubeLikeTriggerIntegrationDetailsComponent;
