import type {CohortLinkPerkIntegrationStruct} from '@cohort/shared/apps/cohort/perks/link';
import {CohortLinkPerkIntegrationSpec} from '@cohort/shared/apps/cohort/perks/link';
import {CohortLinkPerkUsageComponent} from '@cohort/wallet/apps/cohort/perkIntegrations/link/PerkUsageComponent';
import type {PerkIntegration} from '@cohort/wallet/apps/PerkIntegration';

export const CohortLinkPerkIntegration: PerkIntegration<CohortLinkPerkIntegrationStruct> = {
  spec: CohortLinkPerkIntegrationSpec,
  usageComponent: CohortLinkPerkUsageComponent,
  usageType: 'self',
};
