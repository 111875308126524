import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {TypeformQuestionWithAnswerSchema} from '@cohort/shared/apps/typeform/common';
import {z} from 'zod';

export const TypeformFillFormTriggerConfigSchema = z.object({
  formId: z.string().min(1, {message: 'errorRequired'}),
});
export type TypeformFillFormTriggerConfig = z.infer<typeof TypeformFillFormTriggerConfigSchema>;

export const TypeformFillFormVerificationAttemptDataSchema = z.object({
  formId: z.string(),
  formTitle: z.string().optional(),
  responseId: z.string(),
  questionsWithAnswers: z.array(TypeformQuestionWithAnswerSchema),
});
export type TypeformFillFormVerificationAttemptData = z.infer<
  typeof TypeformFillFormVerificationAttemptDataSchema
>;

const TypeformFillFormVerificationAttemptInputSchema = z.object({
  responseId: z.string(),
});
export type TypeformFillFormVerificationAttemptInput = z.infer<
  typeof TypeformFillFormVerificationAttemptInputSchema
>;

export type TypeformFillFormTriggerStruct = TriggerIntegrationStruct<
  'typeform.fill-form',
  TypeformFillFormTriggerConfig,
  TypeformFillFormVerificationAttemptData,
  TypeformFillFormVerificationAttemptInput
>;

export const TypeformFillFormTriggerIntegrationSpec: TriggerIntegrationSpec<TypeformFillFormTriggerStruct> =
  {
    id: 'typeform.fill-form' as const,
    configurableMaxAttempts: false,
    configSchema: TypeformFillFormTriggerConfigSchema,
    verificationAttemptDataSchema: TypeformFillFormVerificationAttemptDataSchema,
    verificationAttemptInputSchema: TypeformFillFormVerificationAttemptInputSchema,
    requiresMerchantConnection: true,
    useCases: ['customer-knowledge'],
  };
