import {InstagramMediaSchema} from '@cohort/shared/apps/instagram/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const InstagramTagInPostTriggerConfigSchema = z.object({
  merchantUsername: z.string().min(1, 'errorRequired'),
});

export type InstagramTagInPostTriggerConfig = z.infer<typeof InstagramTagInPostTriggerConfigSchema>;

export const InstagramTagInPostVerificationAttemptDataSchema = z.object({
  media: InstagramMediaSchema.nullable(),
  username: z.string().nullable(),
  verificationMessage: z.string().optional(),
});
export type InstagramTagInPostVerificationAttemptData = z.infer<
  typeof InstagramTagInPostVerificationAttemptDataSchema
>;

const InstagramTagInPostSocialLayerDataSchema = z.object({
  posts: z.array(InstagramTagInPostVerificationAttemptDataSchema),
});
export type InstagramTagInPostSocialLayerData = z.infer<
  typeof InstagramTagInPostSocialLayerDataSchema
>;

export type InstagramTagInPostTriggerStruct = TriggerIntegrationStruct<
  'instagram.tag-in-post',
  InstagramTagInPostTriggerConfig,
  InstagramTagInPostVerificationAttemptData,
  unknown,
  InstagramTagInPostSocialLayerData
>;
export const InstagramTagInPostTriggerIntegrationSpec: TriggerIntegrationSpec<InstagramTagInPostTriggerStruct> =
  {
    id: 'instagram.tag-in-post' as const,
    configurableMaxAttempts: false,
    configSchema: InstagramTagInPostTriggerConfigSchema,
    verificationAttemptDataSchema: InstagramTagInPostVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    socialLayerDataSchema: InstagramTagInPostSocialLayerDataSchema,
    requiresMerchantConnection: true,
    useCases: ['user-generated-content'],
  };
