import TwitterFollowTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/twitter/triggerIntegrations/follow/InstructionsComponent';
import type {TwitterFollowTriggerStruct} from '@cohort/shared/apps/twitter/triggers/follow';
import {TwitterFollowTriggerIntegrationSpec} from '@cohort/shared/apps/twitter/triggers/follow';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import TwitterFollowTriggerActionCtaComponent from '@cohort/wallet/apps/twitter/triggerIntegrations/follow/ActionCtaComponent';
import TwitterFollowTriggerDetailsComponent from '@cohort/wallet/apps/twitter/triggerIntegrations/follow/DetailsComponent';

export const TwitterFollowTriggerIntegration: TriggerIntegration<TwitterFollowTriggerStruct> = {
  type: 'action',
  spec: TwitterFollowTriggerIntegrationSpec,
  actionCtaComponent: TwitterFollowTriggerActionCtaComponent,
  detailsComponent: TwitterFollowTriggerDetailsComponent,
  instructionsComponent: TwitterFollowTriggerIntegrationInstructionsComponent,
};
