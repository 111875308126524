import type {CohortSwipeCardsTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import TinderSwipe from '@cohort/wallet/apps/cohort-form/triggerIntegrations/swipeCards/TinderSwipe';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import useGetLocalizedString from '@cohort/wallet/hooks/getLocalizedString';
import {useIsMutating} from '@tanstack/react-query';

const CohortSwipeCardsUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortSwipeCardsTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config, stepId}) => {
  const getLocalizedString = useGetLocalizedString();
  const leftBtnLabel = getLocalizedString(config.leftBtnLabel) ?? '';
  const rightBtnLabel = getLocalizedString(config.rightBtnLabel) ?? '';
  const cards = config.cards;
  const ongoingVerifications = useIsMutating({
    mutationKey: ['verifyStep', stepId],
  });
  const isVerifying = ongoingVerifications > 0;

  return (
    <TinderSwipe
      leftBtnLabel={leftBtnLabel}
      rightBtnLabel={rightBtnLabel}
      cards={cards}
      onSuccess={answers => onTriggerIntegrationUsageSuccess({answers})}
      isVerifying={isVerifying}
    />
  );
};

export default CohortSwipeCardsUsageComponent;
