import {contentVisualPreviewImageElement} from '@cohort/components-xps/components/contents/apps/utils';
import type {MediaSize} from '@cohort/shared/utils/media';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';

type CohortImageVisualPreviewContentProps = {
  imageFileKey: string;
  title: string;
  imageSize: MediaSize;
};

const CohortImageVisualPreviewContent: React.FC<CohortImageVisualPreviewContentProps> = ({
  imageFileKey,
  title,
  imageSize,
}) => {
  const imageSrc = getImageUrl(import.meta.env.COHORT_ENV, imageFileKey, {
    h: Sizes[imageSize],
    w: Sizes[imageSize],
  });
  return contentVisualPreviewImageElement(title, imageSrc);
};

export default CohortImageVisualPreviewContent;
