import {TriggerReferenceIdSchema} from '@cohort/shared/apps/cohort-form/triggers/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {LocalizedStringSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

const VerificationAttemptInputSchema = z.object({
  answer: z.union([z.string(), z.array(z.string())]),
});
export type VerificationAttemptInput = z.infer<typeof VerificationAttemptInputSchema>;

const VerificationAttemptDataSchema = z.object({
  answer: z.union([z.string(), z.array(z.string())]),
});
export type VerificationAttemptData = z.infer<typeof VerificationAttemptDataSchema>;

const OptionSchema = z.object({
  value: z.string().min(1),
  label: LocalizedStringSchema,
});

const ConfigSchema = z.object({
  referenceId: TriggerReferenceIdSchema,
  userPropertyId: z.string().uuid().nullable(),
  multipleChoice: z.boolean(),
  options: z.array(OptionSchema).min(1, {message: 'errorNoOptions'}),
});
export type Config = z.infer<typeof ConfigSchema>;

export type CohortFormSelectTriggerStruct = TriggerIntegrationStruct<
  'cohort-form.select',
  Config,
  VerificationAttemptData,
  VerificationAttemptInput
>;

export const CohortFormSelectTriggerIntegrationSpec: TriggerIntegrationSpec<CohortFormSelectTriggerStruct> =
  {
    id: 'cohort-form.select' as const,
    configurableMaxAttempts: false,
    configSchema: ConfigSchema,
    verificationAttemptDataSchema: VerificationAttemptDataSchema,
    verificationAttemptInputSchema: VerificationAttemptInputSchema,
    requiresMerchantConnection: false,
  };
