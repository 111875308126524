import MediaContentTikTokPost from '@cohort/components-xps/components/contents/apps/tiktok/post/MediaContentTikTokPost';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import type {TikTokPostMediaStruct} from '@cohort/shared/apps/tiktok/medias/post';

const TikTokPostDisplayComponent: React.FC<MediaContentProps<TikTokPostMediaStruct>> = ({
  config,
  description,
  title,
}) => {
  return <MediaContentTikTokPost config={config} title={title} description={description} />;
};

export default TikTokPostDisplayComponent;
