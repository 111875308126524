import {TriggerReferenceIdSchema} from '@cohort/shared/apps/cohort-form/triggers/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const VerificationAttemptInputSchema = z.object({
  answer: z.string(),
});
type VerificationAttemptInput = z.infer<typeof VerificationAttemptInputSchema>;

const VerificationAttemptDataSchema = z.object({
  answer: z.string(),
  socialLayerConsent: z.boolean().optional(),
});
type VerificationAttemptData = z.infer<typeof VerificationAttemptDataSchema>;

const ConfigSchema = z.object({
  referenceId: TriggerReferenceIdSchema,
  mediaType: z.enum(['image', 'video', 'imageOrVideo']),
});
type Config = z.infer<typeof ConfigSchema>;

const SocialLayerDataSchema = z.object({
  medias: z.array(z.string()),
});
type SocialLayerData = z.infer<typeof SocialLayerDataSchema>;

export type CohortFormMediaTriggerStruct = TriggerIntegrationStruct<
  'cohort-form.media',
  Config,
  VerificationAttemptData,
  VerificationAttemptInput,
  SocialLayerData
>;

export const CohortFormMediaTriggerIntegrationSpec: TriggerIntegrationSpec<CohortFormMediaTriggerStruct> =
  {
    id: 'cohort-form.media' as const,
    configurableMaxAttempts: false,
    configSchema: ConfigSchema,
    verificationAttemptDataSchema: VerificationAttemptDataSchema,
    verificationAttemptInputSchema: VerificationAttemptInputSchema,
    socialLayerDataSchema: SocialLayerDataSchema,
    requiresMerchantConnection: false,
    useCases: ['user-generated-content'],
  };
