import {contentVisualPreviewImageElement} from '@cohort/components-xps/components/contents/apps/utils';
import type {MediaSize} from '@cohort/shared/utils/media';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {TiktokLogo} from '@phosphor-icons/react';

type TikTokPostVisualPreviewContentProps = {
  imageFileKey: string | null;
  title: string;
  isMobile: boolean;
  imageSize: MediaSize;
};

const TikTokPostVisualPreviewContent: React.FC<TikTokPostVisualPreviewContentProps> = ({
  imageFileKey,
  title,
  isMobile,
  imageSize,
}) => {
  if (!imageFileKey) {
    return (
      <div className={cn('flex h-full', isMobile ? 'justify-center' : 'items-center justify-end')}>
        <TiktokLogo
          size={isMobile ? 225 : 180}
          className={cn('text-inherit opacity-30')}
          viewBox={isMobile ? '0 0 256 230' : '40 15 120 220'}
        />
      </div>
    );
  }

  const imageSrc = getImageUrl(import.meta.env.COHORT_ENV, imageFileKey, {
    h: Sizes[imageSize],
    w: Sizes[imageSize],
  });

  return contentVisualPreviewImageElement(title, imageSrc);
};

export default TikTokPostVisualPreviewContent;
