import {contentVisualPreviewImageElement} from '@cohort/components-xps/components/contents/apps/utils';

type YoutubeVideoVisualPreviewContentProps = {
  videoId: string;
  title: string;
};

const YoutubeVideoVisualPreviewContent: React.FC<YoutubeVideoVisualPreviewContentProps> = ({
  videoId,
  title,
}) => {
  // Youtube standard thumbnail url based on the video ID (https://stackoverflow.com/a/2068371)
  const thumbnailSrc = `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;

  return contentVisualPreviewImageElement(title, thumbnailSrc);
};

export default YoutubeVideoVisualPreviewContent;
