import type {TypeformFillFormVerificationAttemptInput} from '@cohort/shared/apps/typeform/triggers/fillForm';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import {Widget} from '@typeform/embed-react';

type TypeformWidgetProps = {
  formId: string;
  onSubmit: (data: TypeformFillFormVerificationAttemptInput) => void;
};

const TypeformWidget: React.FC<TypeformWidgetProps> = ({formId, onSubmit}) => {
  const user = useUserStore(store => store.user);
  const {isMobile} = useScreenSize();

  if (!user) {
    return null;
  }

  return (
    <Widget
      id={formId}
      style={{width: '100%', height: isMobile ? '100%' : '800px'}}
      onSubmit={onSubmit}
      inlineOnMobile
      hidden={{
        cohort_user_id: user.id,
      }}
      iframeProps={{
        // We disable the iframe click verification hook to prevent blur
        // when the user clicks on the widget leading to weird behavior with the form inputs
        // @ts-expect-error - type issue with data-*
        'data-ignore-iframe-click-verification': 'true',
      }}
    />
  );
};

export default TypeformWidget;
