import type {CohortFormPictureChoiceTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/pictureChoice';
import {getImageUrl} from '@cohort/shared/utils/media';
import ProgressCard from '@cohort/wallet/apps/ProgressCard';
import type {TriggerIntegrationSocialLayerComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import React from 'react';

const CohortFormPictureChoiceSocialLayerComponent: React.FC<
  TriggerIntegrationSocialLayerComponentProps<CohortFormPictureChoiceTriggerStruct>
> = ({config, socialLayerData, verificationAttemptInput}) => {
  return (
    <div className="w-full space-y-2">
      {config.pictureChoiceOptions.map(picture => {
        const percentage = socialLayerData.pictures[picture.imageFileKey] ?? 0;
        const selected = Array.isArray(verificationAttemptInput?.answer)
          ? verificationAttemptInput.answer.includes(picture.imageFileKey)
          : picture.imageFileKey === verificationAttemptInput?.answer;

        return (
          <ProgressCard key={picture.imageFileKey} percentage={percentage} selected={selected}>
            <div className="flex w-full items-center justify-between">
              <img
                src={getImageUrl(import.meta.env.COHORT_ENV, picture.imageFileKey, {
                  h: 40,
                  w: 40,
                })}
                className="rounded"
              />
              <h3>{Math.round(percentage)}%</h3>
            </div>
          </ProgressCard>
        );
      })}
    </div>
  );
};

export default CohortFormPictureChoiceSocialLayerComponent;
