import MediaContentCohortArticle from '@cohort/components-xps/components/contents/apps/cohort/article/MediaContentCohortArticle';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import type {CohortArticleMediaStruct} from '@cohort/shared/apps/cohort/medias/article';

const CohortArticleDisplayComponent: React.FC<MediaContentProps<CohortArticleMediaStruct>> = ({
  config,
  title,
}) => {
  return <MediaContentCohortArticle config={config} title={title} />;
};

export default CohortArticleDisplayComponent;
