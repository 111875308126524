/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {
  getChallengeContent,
  getContent,
  getContentCategory,
  getContentsPreviewByCategories,
  getHighlightedContents,
  getPaginatedContents,
} from '@cohort/wallet/lib/Endpoints';
import {useInfiniteQuery} from '@tanstack/react-query';

export const contentsKeys = {
  contents: ['contents'] as const,
  getContent: (contentId: string) => [...contentsKeys.contents, contentId] as const,
  getChallengeContent: (contentId: string, campaignChallengeId: string) =>
    [...contentsKeys.contents, contentId, campaignChallengeId] as const,
  getContentsPreviewByCategories: () =>
    [...contentsKeys.contents, 'contentsPreviewByCategories'] as const,
  getHighlighted: () => [...contentsKeys.contents, 'highlighted'] as const,
  getContentCategory: (categorySlug: string) =>
    [...contentsKeys.contents, 'contentCategory', categorySlug] as const,
  listByCategory: (merchantId: string, categorySlug?: string) =>
    [...contentsKeys.contents, merchantId, categorySlug] as const,
  listInfiniteContents: (merchantId: string, categorySlug?: string) =>
    [...contentsKeys.listByCategory(merchantId, categorySlug), 'infinite'] as const,
};

export const useContentsPreviewByCategories = (enabled?: boolean) => {
  return useCohortQuery({
    queryKey: contentsKeys.getContentsPreviewByCategories(),
    queryFn: async () => getContentsPreviewByCategories(),
    enabled,
  });
};

export const useHighlightedContents = (enabled?: boolean) => {
  return useCohortQuery({
    queryKey: contentsKeys.getHighlighted(),
    queryFn: async () => getHighlightedContents(),
    enabled,
  });
};

export const useContentCategory = (categorySlug: string, enabled?: boolean) => {
  return useCohortQuery({
    queryKey: contentsKeys.getContentCategory(categorySlug),
    queryFn: async () => getContentCategory(categorySlug),
    enabled,
  });
};

export const useContent = (contentId: string, enabled?: boolean) => {
  return useCohortQuery({
    queryKey: contentsKeys.getContent(contentId),
    queryFn: async () => getContent(contentId),
    enabled,
  });
};

export const useChallengeContent = (
  contentId: string,
  campaignChallengeId: string,
  enabled?: boolean
) =>
  useCohortQuery({
    queryKey: contentsKeys.getChallengeContent(contentId, campaignChallengeId),
    queryFn: async () => getChallengeContent(contentId, campaignChallengeId),
    enabled,
  });

type UseContentsWithInfiniteQueryParams = {
  merchantId: string;
  categorySlug?: string;
  pageSize: number;
  enabled?: boolean;
};

export const useContentsWithInfiniteQuery = (params: UseContentsWithInfiniteQueryParams) => {
  const {merchantId, categorySlug, pageSize, enabled} = params;

  return useInfiniteQuery({
    queryKey: contentsKeys.listInfiniteContents(merchantId, categorySlug),
    queryFn: async ({pageParam = 1}) => {
      const [pagination, contents] = await getPaginatedContents({
        categorySlug,
        page: pageParam,
        pageSize,
      });

      return {data: contents, meta: pagination};
    },
    getNextPageParam: lastPage => {
      if (lastPage.meta.page === lastPage.meta.pages) {
        return undefined;
      }
      return lastPage.meta.page + 1;
    },
    enabled,
  });
};
