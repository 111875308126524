import EmbedSkeleton from '@cohort/components-xps/components/apps/EmbedSkeleton';
import type {CohortFormTextTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/text';
import {CohortFormTextTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/text';
import TriggerFormWrapper, {
  useSyncUserAttributesToForm,
} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/TriggerFormWrapper';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {Input} from '@cohort/wallet/components/forms/Input';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import type {z} from 'zod';

type CohortFormText = z.infer<
  (typeof CohortFormTextTriggerIntegrationSpec)['verificationAttemptInputSchema']
>;

const CohortFormTextUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFormTextTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, stepId, config}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.text',
  });
  const {
    register,
    handleSubmit,
    resetField,
    formState: {errors, isValid},
  } = useForm<CohortFormText>({
    resolver: zodResolver(CohortFormTextTriggerIntegrationSpec.verificationAttemptInputSchema),
  });
  const error = errors.answer?.message;
  const shouldDisplayLoader = useSyncUserAttributesToForm(config.userPropertyId, resetField);

  if (shouldDisplayLoader) {
    return <EmbedSkeleton width="100%" height={200} />;
  }

  return (
    <TriggerFormWrapper
      stepId={stepId}
      formId="text-form"
      error={error}
      isValid={isValid}
      onSubmit={handleSubmit(data => onTriggerIntegrationUsageSuccess(data))}
    >
      <Input type="text" register={register('answer')} placeholder={t('placeholderAnswerInput')} />
    </TriggerFormWrapper>
  );
};

export default CohortFormTextUsageComponent;
