import type {CohortFormCheckboxTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/checkbox';
import {CohortFormCheckboxTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/checkbox';
import CohortFormCheckboxUsageComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/checkbox/UsageComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortFormCheckboxTriggerIntegration: TriggerIntegration<CohortFormCheckboxTriggerStruct> =
  {
    type: 'embedded',
    spec: CohortFormCheckboxTriggerIntegrationSpec,
    usageComponent: CohortFormCheckboxUsageComponent,
    disableExponentialBackoff: true,
  };
