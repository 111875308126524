import {isOnSpace} from '@cohort/wallet/lib/Utils';

const spacePrefix = (): string => (isOnSpace() ? '/space' : '');

export const getHomeRoute = (challengeSlug?: string): string => {
  if (!isOnSpace() && !challengeSlug) {
    throw new Error('challengeSlug is required when not on space');
  }
  return isOnSpace() ? '/space' : `/challenges/${challengeSlug}`;
};

export const getBadgesListRoute = (): string => {
  return `/space/badges`;
};

export const getChallengesRoute = (campaignId: string): string => {
  return `/challenges/${campaignId}`;
};

export const getDefaultLoginRoute = (): string => {
  return '/login';
};

export const getMerchantSpaceLoginRoute = (): string => {
  return `/space/login`;
};

export const getMerchantSpaceRoute = (): string => {
  return `/space/home`;
};

export const getOwnershipDetailRoute = (ownershipId: string, challengeSlug?: string): string => {
  return `${getHomeRoute(challengeSlug)}/rewards/ownerships/${ownershipId}`;
};

export const getRewardBoxDetailRoute = (rewardBoxId: string, challengeSlug?: string): string => {
  return `${getHomeRoute(challengeSlug)}/rewards/reward-boxes/${rewardBoxId}`;
};

export const getImportNftRoute = (): string => {
  return `/space/rewards/ownerships/imports/new`;
};

export const getPerkDetailRoute = (perkId: string, challengeSlug?: string): string => {
  return `${getHomeRoute(challengeSlug)}/rewards/perks/${perkId}`;
};

export const getNftTransferInvitationLoginRoute = (nfTransferInvitationId: string): string => {
  return `/space/invitations/${nfTransferInvitationId}/login`;
};

export const getNftTransferInvitationRoute = (nfTransferInvitationId: string): string => {
  return `/space/invitations/${nfTransferInvitationId}`;
};

export const getNewNftTransferRoute = (ownershipId: string, challengeSlug?: string): string => {
  return `${getHomeRoute(challengeSlug)}/rewards/ownerships/${ownershipId}/transfer/new`;
};

export const getChallengesListRoute = (): string => {
  return `/space/challenges`;
};

export const getChallengePlayRoute = (challengeSlug: string): string => {
  return `${spacePrefix()}/challenges/${challengeSlug}/play`;
};

export const getChallengeDetailRoute = (challengeSlug: string): string => {
  return `${spacePrefix()}/challenges/${challengeSlug}`;
};

export const getChallengeStepVerifyRoute = (challengeSlug: string, stepId: string): string => {
  return `${spacePrefix()}/challenges/${challengeSlug}/steps/${stepId}/verify`;
};

export const getChallengeReferralRoute = (challengeSlug: string): string => {
  return `${spacePrefix()}/challenges/${challengeSlug}/referral`;
};

export const getChallengeTriggerIntegrationUsageRoute = (
  challengeSlug: string,
  stepId: string,
  triggerId: string
): string => {
  return `${spacePrefix()}/challenges/${challengeSlug}/steps/${stepId}/triggers/${triggerId}/use`;
};

export const getRewardsListRoute = (filter?: 'perks' | 'digital-assets'): string => {
  return `/space/rewards${filter ? `?filter=${filter}` : ''}`;
};

export const getContentsListRoute = (): string => {
  return `/space/contents`;
};

export const getContentCategoriesRoute = (): string => {
  return `/space/contents/categories`;
};

export const getContentCategoryRoute = (categorySlug: string): string => {
  return `/space/contents/categories/${categorySlug}`;
};

export const getSpaceSettingsRoute = (): string => {
  return `/space/settings`;
};

export const getRootPaths = (storeSlug?: string, challengeSlug?: string): string[] => {
  return [
    getMerchantSpaceRoute(),
    getChallengesListRoute(),
    getRewardsListRoute(),
    getSpaceSettingsRoute(),
    ...(challengeSlug ? [getChallengesRoute(challengeSlug)] : []),
    ...(storeSlug ? [getStoreCampaignRoute(storeSlug)] : []),
  ];
};

export const getStoreLoginRoute = (campaignSlug: string): string => {
  return `/store/${campaignSlug}/login`;
};

export const getStoreCampaignRoute = (campaignSlug: string): string => {
  return `/store/${campaignSlug}`;
};

export const getStorePaidCheckoutRoute = (campaignSlug: string): string => {
  return `/store/${campaignSlug}/checkout`;
};

export const getStoreOrderRoute = (campaignSlug: string, orderId: string): string => {
  return `/store/${campaignSlug}/orders/${orderId}`;
};
