import {
  contentVisualPreviewEmptyImageElement,
  contentVisualPreviewImageElement,
} from '@cohort/components-xps/components/contents/apps/utils';
import {SpotifyLogo} from '@phosphor-icons/react';

type SpotifyPlaylistVisualPreviewContentProps = {
  thumbnailUrl: string | null;
  title: string;
};

const SpotifyPlaylistVisualPreviewContent: React.FC<SpotifyPlaylistVisualPreviewContentProps> = ({
  thumbnailUrl,
  title,
}) => {
  return thumbnailUrl
    ? contentVisualPreviewImageElement(title, thumbnailUrl)
    : contentVisualPreviewEmptyImageElement(<SpotifyLogo />);
};

export default SpotifyPlaylistVisualPreviewContent;
