import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {LocalizedStringSchema} from '@cohort/shared/schema/common';
import type {CohortFormConfig} from '@cohort/shared/schema/common/cohortForm';
import {
  CohortFormAnswerSchema,
  CohortFormConfigSchema,
  CohortFormPromptTypeSchema,
} from '@cohort/shared/schema/common/cohortForm';
import {z} from 'zod';

export const CohortFillFormVerificationAttemptInputSchema = z.object({
  answers: z.record(z.string(), CohortFormAnswerSchema),
});
export type CohortFillFormVerificationAttemptInput = z.infer<
  typeof CohortFillFormVerificationAttemptInputSchema
>;

export const ExposedCohortFormTriggerAnswerDataSchema = z.object({
  promptId: z.string(),
  promptType: CohortFormPromptTypeSchema,
  referenceId: z.string().nullable(),
  answer: CohortFormAnswerSchema,
  promptName: LocalizedStringSchema,
});
export type ExposedCohortFormTriggerAnswerData = z.infer<
  typeof ExposedCohortFormTriggerAnswerDataSchema
>;

export const CohortFillFormVerificationAttemptDataSchema = z.object({
  answers: z.record(z.string(), ExposedCohortFormTriggerAnswerDataSchema),
});
export type CohortFillFormVerificationAttemptData = z.infer<
  typeof CohortFillFormVerificationAttemptDataSchema
>;

export type CohortFillFormTriggerStruct = TriggerIntegrationStruct<
  'cohort-form.fill-form',
  CohortFormConfig,
  CohortFillFormVerificationAttemptData
>;

export const CohortFillFormTriggerIntegrationSpec: TriggerIntegrationSpec<CohortFillFormTriggerStruct> =
  {
    id: 'cohort-form.fill-form' as const,
    configurableMaxAttempts: false,
    configSchema: CohortFormConfigSchema,
    verificationAttemptDataSchema: CohortFillFormVerificationAttemptDataSchema,
    verificationAttemptInputSchema: CohortFillFormVerificationAttemptInputSchema,
    requiresMerchantConnection: false,
    deprecated: true,
  };
