import EmbedError from '@cohort/components-xps/components/apps/EmbedError';
import EmbedSkeleton from '@cohort/components-xps/components/apps/EmbedSkeleton';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import {loadScript} from '@cohort/shared-frontend/utils/loadScript';
import {useEffect, useState} from 'react';
import {flushSync} from 'react-dom';
import {getI18n, useTranslation} from 'react-i18next';

type TwitterEmbedTimelineProps = {
  profileUrl: string;
  darkMode?: boolean;
};

const TwitterEmbedTimeline: React.FC<TwitterEmbedTimelineProps> = ({
  profileUrl,
  darkMode = false,
}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'apps.twitter.twitterEmbedTimeline',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    loadScript('https://platform.twitter.com/widgets.js')
      .then(() => {
        if (window.twttr) {
          flushSync(() => setIsLoading(false));
        }
      })
      .catch(() => setIsError(true));
  }, []);

  if (isLoading) {
    return <EmbedSkeleton width="100%" height="450px" />;
  }

  if (isError) {
    return <EmbedError error={t('twitterEmbedError')} />;
  }

  return (
    <div className="[&>*]:max-h-[450px] [&>*]:overflow-y-scroll">
      <a
        className="twitter-timeline"
        href={`${profileUrl.replace('https://x.com', 'https://twitter.com')}?ref_src=twsrc%5Etfw`}
        {...(darkMode ? {'data-theme': 'dark'} : {})}
      >
        <EmbedSkeleton width="100%" height="450px" />
      </a>
    </div>
  );
};

export default TwitterEmbedTimeline;
