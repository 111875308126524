import type {TypeformTakeQuizTriggerStruct} from '@cohort/shared/apps/typeform/triggers/takeQuiz';
import {TypeformTakeQuizTriggerIntegrationSpec} from '@cohort/shared/apps/typeform/triggers/takeQuiz';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import TypeformTakeQuizUsageComponent from '@cohort/wallet/apps/typeform/triggerIntegrations/takeQuiz/UsageComponent';

export const TypeformTakeQuizTriggerIntegration: TriggerIntegration<TypeformTakeQuizTriggerStruct> =
  {
    type: 'embedded',
    spec: TypeformTakeQuizTriggerIntegrationSpec,
    usageComponent: TypeformTakeQuizUsageComponent,
    hideCtaOnMobile: true,
    displayLoadingSkeleton: true,
  };
