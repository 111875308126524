import type {CohortFormTextTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/text';
import {CohortFormTextTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/text';
import CohortFormTextUsageComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/text/UsageComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortFormTextTriggerIntegration: TriggerIntegration<CohortFormTextTriggerStruct> = {
  type: 'embedded',
  spec: CohortFormTextTriggerIntegrationSpec,
  usageComponent: CohortFormTextUsageComponent,
  disableExponentialBackoff: true,
};
