import EmbedError from '@cohort/components-xps/components/apps/EmbedError';
import EmbedSkeleton from '@cohort/components-xps/components/apps/EmbedSkeleton';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {loadScript} from '@cohort/shared-frontend/utils/loadScript';
import React, {useEffect, useRef, useState, useSyncExternalStore} from 'react';
import {getI18n, useTranslation} from 'react-i18next';
import type {Options, YouTubePlayer} from 'youtube-player/dist/types';

declare global {
  interface Window {
    onYouTubeIframeAPIReady: (() => void) | null;
    YT?: {
      Player?: new (elementId: string | HTMLElement, options: Options) => YouTubePlayer;
    };
  }
}

type YoutubeVideoPlayerProps = {
  onError?: (err?: unknown) => void;
  videoId: string;
  borderRadius?: string;
};

// Shared state for API readiness
let isYouTubeApiReady = false;
const youTubeApiListeners = new Set<() => void>();

// Subscribe function for useSyncExternalStore
function subscribe(callback: () => void): () => void {
  youTubeApiListeners.add(callback);
  return () => {
    youTubeApiListeners.delete(callback);
  };
}

// Snapshot functions for useSyncExternalStore
function getSnapshot(): boolean {
  return isYouTubeApiReady;
}

function getServerSnapshot(): boolean {
  return false;
}

function useYouTubeApi(): {isApiReady: boolean; isApiError: boolean} {
  const isApiReady = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
  const [isApiError, setIsApiError] = useState(false);

  useEffect(() => {
    if (isYouTubeApiReady) {
      return;
    }

    loadScript('https://www.youtube.com/iframe_api')
      .then(() => {
        if (window.YT && window.YT.Player) {
          isYouTubeApiReady = true;
          youTubeApiListeners.forEach(listener => listener());
          youTubeApiListeners.clear();
        } else if (!window.onYouTubeIframeAPIReady) {
          window.onYouTubeIframeAPIReady = () => {
            isYouTubeApiReady = true;
            youTubeApiListeners.forEach(listener => listener());
            youTubeApiListeners.clear();
          };
        }
      })
      .catch(() => setIsApiError(true));
  }, []);

  return {isApiReady, isApiError};
}

const YoutubeVideoPlayer: React.FC<YoutubeVideoPlayerProps> = ({
  onError,
  videoId,
  borderRadius,
}) => {
  const {isApiReady, isApiError} = useYouTubeApi();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<YouTubePlayer | null>(null);
  const {t} = useTranslation('components', {
    keyPrefix: 'apps.youtube.youtubeVideoPlayer',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });

  // Initialize player when API is ready
  useEffect(() => {
    if (isApiReady && containerRef.current && !playerRef.current && window.YT && window.YT.Player) {
      try {
        setIsLoading(true);
        playerRef.current = new window.YT.Player(containerRef.current, {
          width: '100%',
          videoId,
          playerVars: {
            playsinline: 1,
            autoplay: 0,
          },
          events: {
            // @ts-expect-error - Youtube types are not up to date
            onReady: () => setIsLoading(false),
          },
        });
      } catch (err) {
        onError?.(err);
        setIsLoading(false);
        setIsError(true);
      }
    }

    return () => {
      playerRef.current?.destroy();
      playerRef.current = null;
    };
  }, [isApiReady, videoId, onError, containerRef]);

  if (isApiError || isError) {
    return <EmbedError error={t('youtubeEmbedError')} />;
  }

  return (
    <div
      className={cn(
        'relative grid aspect-video [&>*]:[grid-column:1] [&>*]:[grid-row:1]',
        borderRadius ?? 'rounded-[--xps-img-border-radius]'
      )}
    >
      <div
        ref={containerRef}
        className={cn('h-full', borderRadius ?? 'rounded-[--xps-img-border-radius]')}
      />
      {isLoading && <EmbedSkeleton width="100%" className="rounded-[--xps-img-border-radius]" />}
    </div>
  );
};

export default YoutubeVideoPlayer;
