import FacebookEmbed from '@cohort/components-xps/components/apps/facebook/FacebookEmbedPost';
import type {FacebookCommentPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/commentPost';
import serializeDataAttributes from '@cohort/shared-frontend/utils/serializeDataAtttributes';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';

const FacebookCommentPostTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<FacebookCommentPostTriggerStruct>
> = ({config, stepId, triggerId}) => {
  const {isMobile} = useScreenSize();

  return (
    <div
      className="flex w-full items-center justify-center"
      {...serializeDataAttributes({
        'step-id': stepId,
        'trigger-id': triggerId,
      })}
    >
      <FacebookEmbed type="post" url={config.postPermalink} isMobile={isMobile} />
    </div>
  );
};

export default FacebookCommentPostTriggerIntegrationDetailsComponent;
