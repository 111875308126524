import TikTokPostVisualPreviewContent from '@cohort/components-xps/components/contents/apps/tiktok/post/VisualPreviewContent';
import type {TikTokPostMediaStruct} from '@cohort/shared/apps/tiktok/medias/post';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';

const TikTokPostVisualPreview: React.FC<VisualPreviewProps<TikTokPostMediaStruct>> = ({
  config,
  title,
  imageSize = 'XL',
}) => {
  const {isMobile} = useScreenSize();
  const imageFileKey = config.imageFileKey;

  return (
    <TikTokPostVisualPreviewContent
      imageSize={imageSize}
      imageFileKey={imageFileKey}
      title={title}
      isMobile={isMobile}
    />
  );
};

export default TikTokPostVisualPreview;
