import type {CohortFormLongTextTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/longText';
import {CohortFormLongTextTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/longText';
import CohortFormLongTextUsageComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/longText/UsageComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortFormLongTextTriggerIntegration: TriggerIntegration<CohortFormLongTextTriggerStruct> =
  {
    type: 'embedded',
    spec: CohortFormLongTextTriggerIntegrationSpec,
    usageComponent: CohortFormLongTextUsageComponent,
    disableExponentialBackoff: true,
  };
