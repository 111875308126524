import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const YoutubeSubscribeTriggerConfigSchema = z.object({
  channelUrl: z.string().url(),
});
export type YoutubeSubscribeTriggerConfig = z.infer<typeof YoutubeSubscribeTriggerConfigSchema>;

export const YoutubeSubscribeVerificationAttemptDataSchema = z.object({
  channelId: z.string().nullable(),
  channelHandle: z.string().nullable(),
  channelTitle: z.string().nullable(),
  subscription: z
    .object({
      id: z.string(),
      createdAt: z.date(),
    })
    .nullable(),
});
export type YoutubeSubscribeVerificationAttemptData = z.infer<
  typeof YoutubeSubscribeVerificationAttemptDataSchema
>;

export type YoutubeSubscribeTriggerStruct = TriggerIntegrationStruct<
  'youtube.subscribe',
  YoutubeSubscribeTriggerConfig,
  YoutubeSubscribeVerificationAttemptData
>;
export const YoutubeSubscribeTriggerSpec: TriggerIntegrationSpec<YoutubeSubscribeTriggerStruct> = {
  id: 'youtube.subscribe' as const,
  configurableMaxAttempts: false,
  configSchema: YoutubeSubscribeTriggerConfigSchema,
  verificationAttemptDataSchema: YoutubeSubscribeVerificationAttemptDataSchema,
  verificationAttemptInputSchema: z.object({}),
  requiresMerchantConnection: true,
  useCases: ['social-media'],
};
