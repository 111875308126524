import type {ReactElement} from 'react';
import {cloneElement} from 'react';

export const contentVisualPreviewEmptyImageElement = (icon: ReactElement): JSX.Element => (
  <div className="flex aspect-video h-full w-full items-center justify-center rounded-[--xps-img-border-radius] bg-[--xps-card-background-color]">
    {cloneElement(icon, {size: 80, className: 'text-[--xps-border-color]'})}
  </div>
);

export const contentVisualPreviewImageElement = (title: string, imageSrc: string): JSX.Element => {
  return (
    <img
      title={title}
      className="aspect-video h-full w-full rounded-[--xps-img-border-radius] object-cover"
      draggable="false"
      src={imageSrc}
    />
  );
};
