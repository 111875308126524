import type {PerkIntegrationSpec, PerkIntegrationStruct} from '@cohort/shared/apps/app';
import {urlTransformer, urlValidator} from '@cohort/shared/utils/validators';
import {z} from 'zod';

export const CohortLinkConfigSchema = z.object({
  url: z
    .string()
    .refine(urlValidator, 'errorUrl')
    .transform(url => urlTransformer(url)),
});
export type CohortLinkConfig = z.infer<typeof CohortLinkConfigSchema>;

export const CohortLinkStateSchema = z.object({});
export type CohortLinkState = z.infer<typeof CohortLinkStateSchema>;

export const CohortLinkUsageDataSchema = z.object({});
export type CohortLinkUsageData = z.infer<typeof CohortLinkUsageDataSchema>;

export const ExposedCohortLinkUsageDataSchema = z.object({});
export type ExposedCohortLinkUsageData = z.infer<typeof ExposedCohortLinkUsageDataSchema>;

export type CohortLinkPerkIntegrationStruct = PerkIntegrationStruct<
  'cohort.link',
  CohortLinkConfig,
  CohortLinkState,
  null,
  null,
  CohortLinkUsageData,
  ExposedCohortLinkUsageData,
  ExposedCohortLinkUsageDataForExport
>;

const ExposedCohortLinkUsageDataForExportSchema = ExposedCohortLinkUsageDataSchema;
export type ExposedCohortLinkUsageDataForExport = z.infer<
  typeof ExposedCohortLinkUsageDataForExportSchema
>;

export const CohortLinkPerkIntegrationSpec: PerkIntegrationSpec<CohortLinkPerkIntegrationStruct> = {
  id: 'cohort.link' as const,
  configSchema: CohortLinkConfigSchema,
  stateSchema: CohortLinkStateSchema,
  accessDataSchema: z.null(),
  usageInputDataSchema: z.null(),
  internalUsageDataSchema: z.object({}),
  exposedUsageDataSchema: z.object({}),
  exposedUsageDataForExportSchema: z.object({}),
  defaultMaxUsagesPerToken: null,
  requiresMerchantConnection: false,
};
