import Spinner from '@cohort/shared-frontend/components/Spinner';
import {cn} from '@cohort/shared-frontend/utils/classNames';

const EmbedSkeleton: React.FC<{
  width: string | number;
  height?: string | number;
  children?: React.ReactNode;
  className?: string;
}> = ({width, className, height = width, children}) => (
  <div
    className={cn(
      'flex animate-pulse items-center justify-center gap-2 border bg-[--xps-card-background-color]',
      className
    )}
    style={{width, height}}
  >
    <Spinner size={24} />
    {children}
  </div>
);

export default EmbedSkeleton;
