import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import {Fragment} from 'react';
import {getI18n, useTranslation} from 'react-i18next';

const DefaultInstructions: React.FC = () => {
  const {t} = useTranslation('components', {
    keyPrefix: 'apps.facebook.triggers.defaultInstructions',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });

  return (
    <Fragment>
      <li>
        <p>{t('instructionsStep1')}</p>
      </li>
      <li>
        <p>{t('instructionsStep2')}</p>
      </li>
      <li>
        <p>{t('instructionsStep3')}</p>
      </li>
    </Fragment>
  );
};

export default DefaultInstructions;
