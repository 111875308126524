import CohortLinkVisualPreviewContent from '@cohort/components-xps/components/contents/apps/cohort/link/VisualPreviewContent';
import type {CohortLinkMediaStruct} from '@cohort/shared/apps/cohort/medias/link';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';

const CohortLinkVisualPreview: React.FC<VisualPreviewProps<CohortLinkMediaStruct>> = ({
  config,
  title,
}) => <CohortLinkVisualPreviewContent imageSrc={config.imageSrc} title={title} />;

export default CohortLinkVisualPreview;
