import {HexColorSchema, SlugSchema} from '@cohort/shared/schema/common';
import {
  CampaignAudienceSchema,
  CampaignPricingSchema,
  CampaignTypeSchema,
  PublishedCampaignStatusSchema,
} from '@cohort/shared/schema/common/campaign';
import {CurrencySchema, PricesSchema} from '@cohort/shared/schema/common/currency';
import {DataModeSchema} from '@cohort/shared/schema/common/dataMode';
import {ChallengeParticipationWSchema} from '@cohort/wallet-schemas/challengeParticipation';
import {ChallengeStepWSchema} from '@cohort/wallet-schemas/challengeSteps';
import {ContentWSchema} from '@cohort/wallet-schemas/content';
import {
  ReferralConfigurationWSchema,
  RewardBoxWSchema,
  RewardConfigurationWSchema,
} from '@cohort/wallet-schemas/reward';
import {z} from 'zod';

export const FaqItemWSchema = z.object({
  question: z.string().min(1),
  answer: z.string().min(1),
});
export type FaqItemWDto = z.infer<typeof FaqItemWSchema>;

export const StoreWSchema = z.object({
  title: z.string(),
  imageFileKey: z.string().nullable(),
  animationFileKey: z.string().nullable(),
  accentColorCode: HexColorSchema.nullable(),
  backgroundColorCode: HexColorSchema.nullable(),
  description: z.string().min(1).nullable(),
  defaultCurrency: CurrencySchema.nullable(),
  prices: PricesSchema,
  pricing: CampaignPricingSchema,
  shopUrl: z.string().url(),
  slug: SlugSchema,
});
export type StoreWDto = z.infer<typeof StoreWSchema>;

export const ChallengeWSchema = z.object({
  imageFileKey: z.string().nullable(),
  animationFileKey: z.string().nullable(),
  description: z.string(),
  title: z.string(),
  optInCta: z.string(),
  steps: z.array(ChallengeStepWSchema),
  participation: ChallengeParticipationWSchema.nullable(),
  slug: SlugSchema,
  standaloneUrl: z.string(),
});
export type ChallengeWDto = z.infer<typeof ChallengeWSchema>;

export const BaseCampaignWSchema = z.object({
  id: z.string().uuid(),
  audience: CampaignAudienceSchema,
  status: PublishedCampaignStatusSchema,
  faqs: z.array(FaqItemWSchema),
  tosFileKey: z.string().nullable(),
  tosUrl: z.string().url().nullable(),
  mode: DataModeSchema,
  type: CampaignTypeSchema,
  rewardConfiguration: RewardConfigurationWSchema,
  referralConfiguration: ReferralConfigurationWSchema.nullable(),
  showMaxSupply: z.boolean(),
  contents: z.array(ContentWSchema),
  isOtpEnabled: z.boolean(),
  socialLayerEnabled: z.boolean(),
});
export type BaseCampaignWDto = z.infer<typeof BaseCampaignWSchema>;

export const CampaignStoreWSchema = BaseCampaignWSchema.merge(
  z.object({type: z.literal('store'), store: StoreWSchema})
);
export type CampaignStoreWDto = z.infer<typeof CampaignStoreWSchema>;

export const CampaignChallengeWSchema = BaseCampaignWSchema.merge(
  z.object({type: z.literal('challenge'), challenge: ChallengeWSchema})
);
export type CampaignChallengeWDto = z.infer<typeof CampaignChallengeWSchema>;

export const CampaignAirdropWSchema = BaseCampaignWSchema.merge(
  z.object({type: z.literal('airdrop')})
);
export type CampaignAirdropWDto = z.infer<typeof CampaignAirdropWSchema>;

export const StandaloneChallengeWSchema = z.object({
  campaign: CampaignChallengeWSchema,
  rewardBox: RewardBoxWSchema.nullable(),
});
export type StandaloneChallengeWDto = z.infer<typeof StandaloneChallengeWSchema>;
