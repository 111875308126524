import type {TriggerIntegrationId} from '@cohort/shared/apps';
import type {
  InstructionsComponentProps,
  TriggerIntegrationSpec,
  TriggerIntegrationStruct,
} from '@cohort/shared/apps/trigger';
import type {App} from '@cohort/wallet/apps';
import {Apps} from '@cohort/wallet/apps';
import type {TriggerCtaTrackingMetadata} from '@cohort/wallet/components/challenges/NextStepCta';
import type {ChallengeStepTriggerWDto} from '@cohort/wallet-schemas/challengeSteps';
import type {VerificationAttemptInput} from '@cohort/wallet-schemas/challengeStepTriggers';

export type DetailsComponentProps<T extends TriggerIntegrationStruct = TriggerIntegrationStruct> = {
  config: T['Config'];
  triggerId: string;
  stepId: string;
};

export type ActionCtaComponentProps<T extends TriggerIntegrationStruct = TriggerIntegrationStruct> =
  {
    config: T['Config'];
    processStepVerification: () => void;
    trackingMetadata: TriggerCtaTrackingMetadata;
    stepId: string;
  };

export type TriggerIntegrationType = 'action' | 'verification' | 'embedded';
export type TriggerIntegrationUsageComponentProps<
  T extends TriggerIntegrationStruct = TriggerIntegrationStruct,
> = {
  onTriggerIntegrationUsageSuccess: (data: VerificationAttemptInput) => Promise<boolean>;
  stepId: string;
  hasMedia: boolean;
  handleAnswerChange: (answer: VerificationAttemptInput) => void;
  config: T['Config'];
};

export type TriggerIntegrationSocialLayerComponentProps<
  T extends TriggerIntegrationStruct = TriggerIntegrationStruct,
> = {
  config: T['Config'];
  socialLayerData: T['SocialLayerData'];
  verificationAttemptInput?: T['VerificationAttemptInput'];
};

type BaseTriggerIntegration<T extends TriggerIntegrationStruct> = {
  type: TriggerIntegrationType;
  detailsComponent?: React.FC<DetailsComponentProps<T>>;
  spec: TriggerIntegrationSpec<T>;
  disableExponentialBackoff?: boolean;
  instructionsComponent?: React.FC<InstructionsComponentProps<T>>;
};

type ActionTriggerIntegration<T extends TriggerIntegrationStruct> = BaseTriggerIntegration<T> & {
  type: 'action';
  actionCtaComponent: React.FC<ActionCtaComponentProps<T>>;
  usageComponent?: never;
  socialLayerComponent?: never;
  communitySubmissionsComponent?: React.FC<TriggerIntegrationSocialLayerComponentProps<T>>;
  hideCtaOnMobile?: never;
  displayLoadingSkeleton?: boolean;
};

type EmbeddedTriggerIntegration<T extends TriggerIntegrationStruct> = BaseTriggerIntegration<T> & {
  type: 'embedded';
  actionCtaComponent?: never;
  usageComponent: React.FC<TriggerIntegrationUsageComponentProps<T>>;
  socialLayerComponent?: React.FC<TriggerIntegrationSocialLayerComponentProps<T>>;
  communitySubmissionsComponent?: React.FC<TriggerIntegrationSocialLayerComponentProps<T>>;
  hideCtaOnMobile?: boolean;
  displayLoadingSkeleton?: boolean;
};

type VerificationTriggerIntegration<T extends TriggerIntegrationStruct> =
  BaseTriggerIntegration<T> & {
    type: 'verification';
    actionCtaComponent?: never;
    usageComponent?: never;
    socialLayerComponent?: never;
    communitySubmissionsComponent?: React.FC<TriggerIntegrationSocialLayerComponentProps<T>>;
    hideCtaOnMobile?: never;
    displayLoadingSkeleton?: boolean;
  };

export type TriggerIntegration<T extends TriggerIntegrationStruct = TriggerIntegrationStruct> =
  | ActionTriggerIntegration<T>
  | EmbeddedTriggerIntegration<T>
  | VerificationTriggerIntegration<T>;

export const getAppsWithTriggerIntegrations = (): App[] => {
  return Apps.filter(app => app.spec.triggerIntegrationSpecs.length > 0);
};

export const getAppFromTriggerIntegration = (triggerIntegrationId: TriggerIntegrationId): App => {
  const appsListWithTriggers = getAppsWithTriggerIntegrations();
  const app = appsListWithTriggers.find(app =>
    app.triggerIntegrations.find(integration => integration.spec.id === triggerIntegrationId)
  );

  if (!app) {
    throw new Error(`No app found for trigger ${triggerIntegrationId}`);
  }
  return app;
};

export const getDetailsComponentFromTriggerIntegration = (
  trigger: ChallengeStepTriggerWDto
): React.FC<DetailsComponentProps> | null => {
  const app = getAppFromTriggerIntegration(trigger.triggerIntegrationId);
  const DetailsComponent = app.triggerIntegrations.find(
    integration => integration.spec.id === trigger.triggerIntegrationId
  )?.detailsComponent;

  if (!DetailsComponent) {
    return null;
  }

  return DetailsComponent;
};

export const getActionCtaComponentFromTriggerIntegration = (
  triggerIntegrationId: TriggerIntegrationId
): React.FC<ActionCtaComponentProps> | null => {
  const app = getAppFromTriggerIntegration(triggerIntegrationId);
  const ActionCtaComponent = app.triggerIntegrations.find(
    integration => integration.spec.id === triggerIntegrationId
  )?.actionCtaComponent;

  if (!ActionCtaComponent) {
    return null;
  }

  return ActionCtaComponent;
};
