import SpotifyFollowUserTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/spotify/triggers/followUser/InstructionsComponent';
import type {SpotifyFollowUserTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followUser';
import {SpotifyFollowUserTriggerSpec} from '@cohort/shared/apps/spotify/triggers/followUser';
import SpotifyFollowUserTriggerActionCtaComponent from '@cohort/wallet/apps/spotify/triggers/followUser/ActionCtaComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const SpotifyFollowUserTriggerIntegration: TriggerIntegration<SpotifyFollowUserTriggerStruct> =
  {
    type: 'action',
    spec: SpotifyFollowUserTriggerSpec,
    actionCtaComponent: SpotifyFollowUserTriggerActionCtaComponent,
    instructionsComponent: SpotifyFollowUserTriggerIntegrationInstructionsComponent,
  };
