import type {CohortFormDateTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/date';
import {CohortFormDateTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/date';
import CohortFormDateUsageComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/date/UsageComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortFormDateTriggerIntegration: TriggerIntegration<CohortFormDateTriggerStruct> = {
  type: 'embedded',
  spec: CohortFormDateTriggerIntegrationSpec,
  usageComponent: CohortFormDateUsageComponent,
  disableExponentialBackoff: true,
};
