import {GarminConnectTriggerIntegrationAvailableActivityGroupsSchema} from '@cohort/shared/apps/garmin-connect/activity';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {startDateBeforeEndDateRefinement} from '@cohort/shared/schema/common/dates';
import {z} from 'zod';

export const GarminConnectBeActiveTriggerConfigMetricSchema = z.enum([
  'distance',
  'duration',
  'elevation',
]);

export const GarminConnectBeActiveTriggerConfigSchema = z
  .object({
    activityGroups: z
      .array(GarminConnectTriggerIntegrationAvailableActivityGroupsSchema)
      .nonempty()
      .nullable(),
    endDateTimestamp: z.number(),
    metric: GarminConnectBeActiveTriggerConfigMetricSchema,
    startDateTimestamp: z.number(),
    value: z.number().min(0),
  })
  .superRefine(
    startDateBeforeEndDateRefinement({
      startDatePropertyName: 'startDateTimestamp',
      endDatePropertyName: 'endDateTimestamp',
    })
  );

export type GarminConnectBeActiveTriggerConfig = z.infer<
  typeof GarminConnectBeActiveTriggerConfigSchema
>;

export const GarminConnectBeActiveVerificationAttemptDataSchema = z
  .object({
    activityIds: z.array(z.number()).nullable(),
    cumulativeValue: z.number().min(0).optional(),
    metric: GarminConnectBeActiveTriggerConfigMetricSchema,
    thresholdValue: z.number().min(0),
    garminUserId: z.string().nullable(),
    garminUsername: z.string().nullable(),
  })
  .nullable();

export type GarminConnectBeActiveVerificationAttemptData = z.infer<
  typeof GarminConnectBeActiveVerificationAttemptDataSchema
>;

export type GarminConnectBeActiveTriggerStruct = TriggerIntegrationStruct<
  'garmin-connect.be-active',
  GarminConnectBeActiveTriggerConfig,
  GarminConnectBeActiveVerificationAttemptData
>;

export const GarminConnectBeActiveTriggerIntegrationSpec: TriggerIntegrationSpec<GarminConnectBeActiveTriggerStruct> =
  {
    id: 'garmin-connect.be-active' as const,
    configurableMaxAttempts: false,
    configSchema: GarminConnectBeActiveTriggerConfigSchema,
    verificationAttemptDataSchema: GarminConnectBeActiveVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
    deprecated: true,
  };
