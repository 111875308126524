import FacebookTagInPostTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/facebook/triggers/tagInPost/InstructionsComponent';
import type {FacebookTagInPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/tagInPost';
import {FacebookTagInPostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/tagInPost';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const FacebookTagInPostTriggerIntegration: TriggerIntegration<FacebookTagInPostTriggerStruct> =
  {
    type: 'verification',
    spec: FacebookTagInPostTriggerSpec,
    instructionsComponent: FacebookTagInPostTriggerIntegrationInstructionsComponent,
  };
