import {TriggerReferenceIdSchema} from '@cohort/shared/apps/cohort-form/triggers/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const VerificationAttemptInputSchema = z.object({
  answer: z.string().min(1),
});
type VerificationAttemptInput = z.infer<typeof VerificationAttemptInputSchema>;

const VerificationAttemptDataSchema = z.object({
  answer: z.string(),
});
type VerificationAttemptData = z.infer<typeof VerificationAttemptDataSchema>;

const ConfigSchema = z.object({
  referenceId: TriggerReferenceIdSchema,
});
type Config = z.infer<typeof ConfigSchema>;

export type CohortFormLongTextTriggerStruct = TriggerIntegrationStruct<
  'cohort-form.long-text',
  Config,
  VerificationAttemptData,
  VerificationAttemptInput
>;

export const CohortFormLongTextTriggerIntegrationSpec: TriggerIntegrationSpec<CohortFormLongTextTriggerStruct> =
  {
    id: 'cohort-form.long-text' as const,
    configurableMaxAttempts: false,
    configSchema: ConfigSchema,
    verificationAttemptDataSchema: VerificationAttemptDataSchema,
    verificationAttemptInputSchema: VerificationAttemptInputSchema,
    requiresMerchantConnection: false,
    useCases: ['user-generated-content'],
  };
