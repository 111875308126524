import {
  buildTikTokEmbedThumbnailUrl,
  buildTikTokEmbedUrl,
} from '@cohort/shared-frontend/common/apps/tiktok';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isString} from 'remeda';

type EmbedPostProps = {
  videoId: string;
  width?: number | string;
  aspect?: string;
  title?: string;
  muted?: boolean;
  isThumbnail?: boolean;
};

const TiktokEmbedPost: React.FC<EmbedPostProps> = ({
  videoId,
  width = 400,
  aspect,
  title,
  muted,
  isThumbnail = false,
}) => {
  const height = !isString(width) ? width * 1.33 : null;

  return (
    <iframe
      title={title ?? 'TikTok Embed Post'}
      src={`${isThumbnail ? buildTikTokEmbedThumbnailUrl(videoId) : buildTikTokEmbedUrl(videoId)}`}
      className={cn(aspect, 'rounded-[--xps-img-border-radius]')}
      style={{width: width, ...(height ? {height} : {}), ...(muted ? {pointerEvents: 'none'} : {})}}
    />
  );
};

export default TiktokEmbedPost;
