import SpotifyPlayer from '@cohort/components-xps/components/apps/spotify/SpotifyPlayer';
import type {SpotifyFollowPlaylistTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followPlaylist';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';

const SpotifyFollowPlaylistTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<SpotifyFollowPlaylistTriggerStruct>
> = ({config}) => (
  <SpotifyPlayer resourceId={config.playlistId} resourceType="playlist" size="lg" />
);

export default SpotifyFollowPlaylistTriggerIntegrationDetailsComponent;
