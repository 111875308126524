import type {Language} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const TriggerIntegrationUseCaseSchema = z.enum([
  'user-generated-content',
  'customer-knowledge',
  'social-media',
]);
export type TriggerIntegrationUseCase = z.infer<typeof TriggerIntegrationUseCaseSchema>;

export type TriggerIntegrationStruct<
  Id extends string = string,
  Config = unknown,
  VerificationAttemptData = unknown,
  VerificationAttemptInput = unknown,
  SocialLayerData = unknown,
> = {
  Id: Id;
  Config: Config;
  VerificationAttemptData: VerificationAttemptData;
  VerificationAttemptInput: VerificationAttemptInput;
  SocialLayerData: SocialLayerData;
};

export type InstructionsComponentProps<
  T extends TriggerIntegrationStruct = TriggerIntegrationStruct,
> = {
  config: T['Config'];
  stepId: string;
  connected: boolean;
  selectedLanguage?: Language;
};

export const UnverifiedTriggerBaseConfigSchema = z.object({
  url: z.string().url(),
});

const UnverifiedTriggerConfigSchema = UnverifiedTriggerBaseConfigSchema.passthrough();
type UnverifiedTriggerConfig = z.infer<typeof UnverifiedTriggerConfigSchema>;

export type UnverifiedTriggerStruct<
  Id extends string = string,
  Config extends UnverifiedTriggerConfig = UnverifiedTriggerConfig,
  VerificationAttemptData = unknown,
  VerificationAttemptInput = unknown,
  SocialLayerData = unknown,
> = {
  Id: Id;
  Config: Config;
  VerificationAttemptData: VerificationAttemptData;
  VerificationAttemptInput: VerificationAttemptInput;
  SocialLayerData: SocialLayerData;
};

export type TriggerIntegrationSpec<T extends TriggerIntegrationStruct = TriggerIntegrationStruct> =
  {
    id: T['Id'];
    configurableMaxAttempts: boolean;
    configSchema: z.ZodSchema<T['Config']>;
    verificationAttemptDataSchema: z.ZodSchema<T['VerificationAttemptData']>;
    verificationAttemptInputSchema: z.ZodSchema<T['VerificationAttemptInput']>;
    socialLayerDataSchema?: z.ZodSchema<T['SocialLayerData']>;
    requiresMerchantConnection: boolean;
    deprecated?: boolean;
    useCases?: TriggerIntegrationUseCase[];
  };
