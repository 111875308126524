import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const SpotifyFollowPlaylistTriggerConfigSchema = z.object({
  playlistId: z.string().min(1, 'errorRequired'),
  playlistName: z.string().min(1, 'errorRequired'),
  playlistThumbnailUrl: z.string().url(),
  playlistUrl: z.string().url(),
  playlistTracksCount: z.number(),
});
export type SpotifyFollowPlaylistTriggerConfig = z.infer<
  typeof SpotifyFollowPlaylistTriggerConfigSchema
>;

export const SpotifyFollowPlaylistVerificationAttemptDataSchema = z.object({
  userId: z.string(),
  userName: z.string(),
  userUrl: z.string().url(),
  follows: z.boolean(),
  playlistName: z.string(),
  playlistThumbnailUrl: z.string().url(),
  playlistTracksCount: z.number(),
});
export type SpotifyFollowPlaylistVerificationAttemptData = z.infer<
  typeof SpotifyFollowPlaylistVerificationAttemptDataSchema
>;

export type SpotifyFollowPlaylistTriggerStruct = TriggerIntegrationStruct<
  'spotify.follow-playlist',
  SpotifyFollowPlaylistTriggerConfig,
  SpotifyFollowPlaylistVerificationAttemptData
>;
export const SpotifyFollowPlaylistTriggerSpec: TriggerIntegrationSpec<SpotifyFollowPlaylistTriggerStruct> =
  {
    id: 'spotify.follow-playlist' as const,
    configurableMaxAttempts: false,
    configSchema: SpotifyFollowPlaylistTriggerConfigSchema,
    verificationAttemptDataSchema: SpotifyFollowPlaylistVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
