import type {CohortFormNumberTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/number';
import {CohortFormNumberTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/number';
import CohortFormNumberUsageComponent from '@cohort/wallet/apps/cohort-form/triggerIntegrations/number/UsageComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortFormNumberTriggerIntegration: TriggerIntegration<CohortFormNumberTriggerStruct> =
  {
    type: 'embedded',
    spec: CohortFormNumberTriggerIntegrationSpec,
    usageComponent: CohortFormNumberUsageComponent,
    disableExponentialBackoff: true,
  };
