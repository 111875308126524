import {EmailSchema, HexColorSchema, LanguageSchema} from '@cohort/shared/schema/common';
import {CustomComponentTypeSchema} from '@cohort/shared/schema/common/customComponents';
import {DataModeSchema} from '@cohort/shared/schema/common/dataMode';
import {CustomComponentWSchema} from '@cohort/wallet-schemas/customComponent';
import {z} from 'zod';

const MerchantFontWSchema = z.object({
  id: z.string().uuid(),
  src: z.string().url(),
  family: z.string(),
});
export type MerchantFontWDto = z.infer<typeof MerchantFontWSchema>;

const MerchantComponentsWSchema = z.object({
  id: z.string().uuid(),
  type: CustomComponentTypeSchema,
  template: z.string(),
});
export type MerchantComponentsWDto = z.infer<typeof MerchantComponentsWSchema>;

export const MerchantWSchema = z.object({
  id: z.string().uuid(),
  authTenantId: z.string(),
  name: z.string().min(1),
  slug: z.string().min(1),
  xpsEnabled: z.boolean(),
  logoFileKey: z.string().nullable(),
  description: z.string().min(1).nullable(),
  accentColorCode: HexColorSchema.nullable(),
  backgroundColorCode: HexColorSchema.nullable(),
  supportEmail: EmailSchema.nullable(),
  mode: DataModeSchema,
  googleSigninEnabled: z.boolean(),
  membershipPassConfiguration: z.object({
    title: z.string().min(1),
    bannerFileKey: z.string().nullable(),
    backgroundColorCode: HexColorSchema,
  }),
  defaultLanguage: LanguageSchema,
  supportedLanguages: z.array(LanguageSchema),
  emailSigninEnabled: z.boolean(),
  spaceEmbedUrl: z.string().url().nullable(),
  campaignsEmbedUrl: z.string().url().nullable(),
  rgpdTermsUrl: z.string().url().nullable(),
  fonts: z.array(MerchantFontWSchema),
});
export type MerchantWDto = z.infer<typeof MerchantWSchema>;

export const PreviewMerchantWSchema = MerchantWSchema.merge(
  z.object({
    logoFileKey: MerchantWSchema.shape.logoFileKey.nullable(),
    description: MerchantWSchema.shape.description.nullable(),
  })
);
export type PreviewMerchantWDto = z.infer<typeof PreviewMerchantWSchema>;

export const MerchantInitWSchema = MerchantWSchema.extend({
  customComponents: z.array(CustomComponentWSchema),
  organization: z.object({
    id: z.string().uuid(),
    name: z.string().min(1),
    slug: z.string(),
  }),
});

export type MerchantInitWDto = z.infer<typeof MerchantInitWSchema>;
