import {Card} from '@cohort/components-xps/components/cards/Card';
import type {CohortSwipeCardsTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {getImageUrl} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {TriggerIntegrationSocialLayerComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import useGetLocalizedString from '@cohort/wallet/hooks/getLocalizedString';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import React from 'react';

const CohortSwipeCardsSocialLayerComponent: React.FC<
  TriggerIntegrationSocialLayerComponentProps<CohortSwipeCardsTriggerStruct>
> = ({config, socialLayerData, verificationAttemptInput}) => {
  const {hasDarkBg} = useThemeContext();
  const getLocalizedString = useGetLocalizedString();
  const leftBtnLabel = getLocalizedString(config.leftBtnLabel) ?? '';
  const rightBtnLabel = getLocalizedString(config.rightBtnLabel) ?? '';
  const cards = config.cards;

  const textColor = hasDarkBg ? 'text-white/60' : 'text-black/60';
  const percentageColor = hasDarkBg ? 'text-white' : 'text-black/90';

  return (
    <div className="w-full space-y-4">
      {cards.map(card => {
        const {id, imageFileKey} = card;
        const leftPercentage = socialLayerData.cards[id]?.left || 0;
        const rightPercentage = socialLayerData.cards[id]?.right || 0;
        const leftSelected = Object.entries(verificationAttemptInput?.answers ?? {}).some(
          ([cardId, answer]) => cardId === id && answer.answer === leftBtnLabel
        );
        const rightSelected = Object.entries(verificationAttemptInput?.answers ?? {}).some(
          ([cardId, answer]) => cardId === id && answer.answer === rightBtnLabel
        );

        return (
          <Card
            className={cn(
              'relative flex items-center space-x-4 overflow-hidden !rounded-2xl px-4 py-3',
              hasDarkBg ? '!border-white/16 border' : 'border !border-black/8'
            )}
          >
            <img
              src={getImageUrl(import.meta.env.COHORT_ENV, imageFileKey, {
                h: 40,
                w: 40,
              })}
              className="rounded"
            />
            <div className="flex-1">
              <div className="relative mb-2 h-5 w-full overflow-hidden">
                <div className="absolute inset-0 flex items-center justify-between px-2 text-xs font-medium">
                  <span className={cn(textColor)}>{leftBtnLabel}</span>
                  <span className={cn(percentageColor)}>{Math.round(leftPercentage)}%</span>
                </div>
                <div
                  className={cn(
                    'h-full rounded-lg transition-all',
                    !leftSelected && (hasDarkBg ? 'bg-white/8' : '!bg-black/4'),
                    leftSelected && 'bg-[--xps-accent-color] opacity-10'
                  )}
                  style={{width: `${leftPercentage}%`}}
                />
              </div>
              <div className="relative h-5 w-full overflow-hidden">
                <div className="absolute inset-0 flex items-center justify-between px-2 text-xs font-medium">
                  <span className={cn(textColor)}>{rightBtnLabel}</span>
                  <span className={cn(percentageColor)}>{Math.round(rightPercentage)}%</span>
                </div>
                <div
                  className={cn(
                    'h-full rounded-lg transition-all',
                    !rightSelected && (hasDarkBg ? 'bg-white/8' : '!bg-black/4'),
                    rightSelected && 'bg-[--xps-accent-color] opacity-10'
                  )}
                  style={{width: `${rightPercentage}%`}}
                />
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default CohortSwipeCardsSocialLayerComponent;
