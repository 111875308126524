import {TIKTOK_URL} from '@cohort/shared/apps/tiktok/common';
import type {TikTokKeywordInBioTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import OpenCtaWithRefresh from '@cohort/wallet/components/challenges/OpenCtaWithRefresh';
import {useTranslation} from 'react-i18next';

const TiktokKeywordInBioTriggerActionCtaComponent: React.FC<
  ActionCtaComponentProps<TikTokKeywordInBioTriggerStruct>
> = ({trackingMetadata, stepId, processStepVerification}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.keyword-in-bio',
  });

  return (
    <OpenCtaWithRefresh
      url={TIKTOK_URL}
      stepId={stepId}
      processStepVerification={processStepVerification}
      trackingMetadata={trackingMetadata}
      ctaTitle={t('ctaTitle')}
      refreshCtaTitle={t('refreshTitle')}
    />
  );
};

export default TiktokKeywordInBioTriggerActionCtaComponent;
